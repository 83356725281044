import React, { Component } from 'react';
import ImageUploader from './ImageUploader';
import Input from './Input'
import { connect } from 'react-redux';
import { changeStateValue, resetStateValue } from '../redux/MainReducer';
import FileInput from './FileInput';
import TextEditor from './TextEditor';
import { insertNewData, insertNewDataGO } from '../actions/MainAction';
import { mixinAlert } from '../actions/SweetAlerts';
import DropDown from './DropDown';
import Videoinp from './Videoinp';
import TextArea from './TextArea';
class Popup extends Component {
  closePopup=()=>{
    this.props.resetStateValue({name:"modalData"})
  }
  saveBtn=(data)=>{
    let permission = true;
    for(const x of data.requireds){
      if(data[x]=="" || !data[x]){
        permission = false;
      }
    }
    for(const x of data.requiredsLang){
      if(data?.languages[x]=="" || !data?.languages[x]){
        permission = false;
      }
    }
    if(permission){
      this.setState({btnDisable:true})
      let formData = new FormData();
      for(const property in data){
        if(data[property] && property !=="languages"){
          formData.append(property, data[property]);
        }
      }
      if(!data?.video){
        formData.append("video", null)
      }
      if(!data?.image){
        formData.append("image", null)
      }
      if(data?.languages){
        formData.append("translates", JSON.stringify([data?.languages]))
      }
      let x = 0;
      if(data?.images){
        for(let i = 0; i < data?.images.filter(x=>x.id=='').length; i++){
          formData.append(`image${i}`, data?.images.filter(x=>x.id=='')[i]?.image);
          x++;
        }
      }
      // console.log(data?.deleted_images)
      if(data?.deleted_images){
        formData.append("deleted_images", JSON.stringify(data?.deleted_images))
      }
      formData.append("image_count", x);
      if(data?.pathTitle){
        formData.append("path", data?.pathTitle)
      }
      let url = data?.url;
      if(data?.id && data?.id !=""){
        url = `${data?.url}/${data?.id}`;
        formData.append("_method", "put")
      }

      if(data?.go_api){
        this.props.insertNewDataGO(url, formData)
        .then(resp=>{
          this.setState({btnDisable:false})
          if(resp==="success"){
            this.closePopup();
            data?.afterCallFunction(...data?.afterParams)
            mixinAlert("success", "Data uğurla əlavə olundu")
          }else{
            mixinAlert("error", "Xəta baş verdi")
          }
        })
      }else{
        this.props.insertNewData(url, formData)
        .then(resp=>{
          this.setState({btnDisable:false})
          if(resp==="success"){
            this.closePopup();
            data?.afterCallFunction(...data?.afterParams)
            mixinAlert("success", "Data uğurla əlavə olundu")
          }else{
            mixinAlert("error", "Xəta baş verdi")
          }
        })
      }

     
    }else{
      mixinAlert("warning", "Zəhmət olmasa bütün xanaları doldurun");
    }
  }
  closeBackground(e){
    // console.log(e.target.className)
    // if(typeof e.target.className == 'string' && e.target.className.includes("background_shadow")){
    //   this.closePopup();
    // }
  }
  render() {
    const {modalData} = this.props;
    return (
   
          <div onClick={this.closeBackground.bind(this)} className="fixed background_shadow inset-0 flex items-center justify-center z-10">
            <div onClick={this.closeBackground.bind(this)} className="background_shadow bg-black bg-opacity-50 absolute inset-0" ></div>
            <div className="bg-white p-6 rounded-lg shadow-lg z-10 w-[700px] max-h-[70vh] overflow-auto">
              <span className="absolute top-0 right-0 p-4" >&times;</span>
              <h3 className='font-[700] text-[24px] text-[var(--blue)] mb-[16px]'>Add a parent</h3>
              {/* <div className='flex justify-center'><ImageUploader /></div> */}
              <div className='grid grid-cols-2 gap-[16px] mt-[32px]'>
                {modalData?.image || modalData?.image =="" ? <FileInput type="image" name="modalData.image" value={modalData?.image_full_url} title="Upload image" valueName="modalData.image_full_url" />:null}
                {modalData?.video || modalData?.video =="" ? <Videoinp type="video" name="modalData.video" value={modalData?.video} title="Upload video" valueName="modalData.video_full_url" />:null}


                {modalData?.sectionsOption? <DropDown title="Select a section" options={modalData?.sectionsOption} name="modalData.section_id" value={modalData?.section_id}  />:null}
                {modalData?.page_type_option? <DropDown title="Select a page" options={modalData?.page_type_option} name="modalData.page_type" value={modalData?.page_type}  />:null}
                {modalData?.schoolTypeOption? <DropDown title="Select a page" options={modalData?.schoolTypeOption} name="modalData.school_type" value={modalData?.school_type}  />:null}

                {modalData?.languages?.title ||modalData?.languages?.title==""? <Input title="Title" type="text" name="modalData.languages.title" value={modalData?.languages?.title}  />:null}
                {modalData?.languages?.type ||modalData?.languages?.type==""? <Input title="Type" type="text" name="modalData.languages.type" value={modalData?.languages?.type}  />:null}
                {modalData?.name ||modalData?.name==""? <Input title="Name" type="text" name="modalData.name" value={modalData?.name}  />:null}
                {modalData?.title ||modalData?.title==""? <Input title="Title" type="text" name="modalData.title" value={modalData?.title}  />:null}
                {modalData?.date ||modalData?.date==""? <Input title="Date" type="date" name="modalData.date" value={modalData?.date}  />:null}
                {modalData?.path ||modalData?.path==""? <Input title="Path" type="text" name="modalData.path" value={modalData?.path}  />:null}
                {modalData?.percentage ||modalData?.percentage==""? <Input title="Percentage" type="number" name="modalData.percentage" value={modalData?.percentage}  />:null}
                {/* {modalData?.languages?.text ||modalData?.languages?.text==""? <Input title="Sub title" type="text" name="modalData.languages.text" value={modalData?.languages?.text}  />:null} */}
                {modalData?.price ||modalData?.price ==""? <Input title="Price" type="number" name="modalData.price" value={modalData?.price}  />:null}
                {modalData?.duration ||modalData?.duration ==""? <Input title="Duration" type="number" name="modalData.duration" value={modalData?.duration}  />:null}
                {modalData?.phone_number ||modalData?.phone_number ==""? <Input title="Phone number" type="text" name="modalData.phone_number" value={modalData?.phone_number}  />:null}
                {modalData?.office_number ||modalData?.office_number ==""? <Input title="Office number" type="text" name="modalData.office_number" value={modalData?.office_number}  />:null}
                {modalData?.location ||modalData?.location ==""? <Input title="Location" type="text" name="modalData.location" value={modalData?.location}  />:null}
                {modalData?.map ||modalData?.map ==""? <Input title="Map" type="text" name="modalData.map" value={modalData?.map}  />:null}
                {modalData?.facebook ||modalData?.facebook ==""? <Input title="Facebook" type="text" name="modalData.facebook" value={modalData?.facebook}  />:null}
                {modalData?.instagram ||modalData?.instagram ==""? <Input title="Instagram" type="text" name="modalData.instagram" value={modalData?.instagram}  />:null}
                {modalData?.raiting ||modalData?.raiting ==""? <Input title="Raiting" type="number" name="modalData.raiting" value={modalData?.raiting}  />:null}
                {modalData?.lessons ||modalData?.lessons ==""? <Input title="Lessons" type="number" name="modalData.lessons" value={modalData?.lessons}  />:null}
                {modalData?.student_count ||modalData?.student_count ==""? <Input title="Student count" type="number" name="modalData.student_count" value={modalData?.student_count}  />:null}
                {modalData?.property ||modalData?.property ==""? <Input title="Property" type="text" name="modalData.property" value={modalData?.property}  />:null}
                {modalData?.languages?.value ||modalData?.languages?.value==""? <Input title="Value" type="text" name="modalData.languages.value" value={modalData?.languages?.value}  />:null}
                {modalData?.languages?.btn ||modalData?.languages?.btn==""? <Input title="Btn Text" type="text" name="modalData.languages.btn" value={modalData?.languages?.btn}  />:null}
                {modalData?.languages?.content ||modalData?.languages?.content==""? <Input title="content Text" type="text" name="modalData.languages.content" value={modalData?.languages?.content}  />:null}
              </div>
              <div className='mt-[20px]'>
             
              {
                modalData?.languages?.text ||modalData?.languages?.text==""?
                <TextEditor title='Description' value={modalData?.languages?.text} name="modalData.languages.text" />:null
              }
              {
                modalData?.text ||modalData?.text==""?
                <TextEditor title='Description' value={modalData?.text} name="modalData.text" />:null
              }
              </div>
              <div className='mt-[20px]'>
              {
                modalData?.languages?.description ||modalData?.languages?.description==""?
                <TextEditor title='Overview' value={modalData?.languages?.description} name="modalData.languages.description" />:null
              }
              {
                modalData?.description ||modalData?.description==""?
                <TextEditor title='Description' value={modalData?.description} name="modalData.description" />:null
              }

             <div className='mt-[20px]'>
             {
                modalData?.languages?.seo_description ||modalData?.languages?.seo_description==""?
                <TextArea title='SEO Description' value={modalData?.languages?.seo_description} name="modalData.languages.seo_description" />:null
              }
              {
                modalData?.languages?.seo_keywords ||modalData?.languages?.seo_keywords==""?
                <TextArea title='SEO Keywords' value={modalData?.languages?.seo_keywords} name="modalData.languages.seo_keywords" />:null
              }
             </div>
             <div className='mt-[20px]'>
             {
                modalData?.seo_description ||modalData?.seo_description==""?
                <TextArea title='SEO Description' value={modalData?.seo_description} name="modalData.seo_description" />:null
              }
              {
                modalData?.seo_keywords ||modalData?.seo_keywords==""?
                <TextArea title='SEO Keywords' value={modalData?.seo_keywords} name="modalData.seo_keywords" />:null
              }
             </div>
              </div>
              <div className='flex justify-between mt-[16px]'>
                <button onClick={this.closePopup.bind(this)} className='p-[3px_10px]  bg-[var(--red)] text-[var(--white)] font-[700] text-[14px] rounded-[8px]'>Close</button>
                <button onClick={this.saveBtn.bind(this, modalData)} className=' p-[3px_10px]  bg-[var(--blue)] text-[var(--white)] font-[700] text-[14px] rounded-[8px]'>Add</button>
              </div>

            </div>
          </div>

    );
  }
}
const mapStateToProps = (state) =>({
  modalData: state.Data.modalData
});
const mapDispatchToProps = {changeStateValue, resetStateValue, insertNewData, insertNewDataGO}
export default connect(mapStateToProps, mapDispatchToProps)(Popup);
