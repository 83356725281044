export const img_data = [
    {
        "path": "s%c9%99n-h%c9%99l%c9%99-ingilis-dilini-bilmirs%c9%99n",
        "img": "https://celt.az/wp-content/uploads/2021/06/Resim1-1.png"
    },
    {
        "path": "professional-ingilis-dili-mu%c9%99llimi-olmaq-ist%c9%99yirs%c9%99n"
    },
    {
        "path": "is-yerind%c9%99n-ayrilmadan-xaricd%c9%99-t%c9%99hsil-al",
        "img": "https://celt.az/wp-content/uploads/2013/03/embassy_summer_schools_london_me_studyroom.jpg"
    },
    {
        "path": "m%c9%99kt%c9%99blil%c9%99r-kampaniyasi",
        "img": "https://celt.az/wp-content/uploads/2021/06/Resim1-1.png"
    },
    {
        "path": "tefltesol-proqrami-davam-edir"
    },
    {
        "path": "niy%c9%99-toefl",
        "img": "https://celt.az/wp-content/uploads/2021/03/toefl.png"
    },
    {
        "path": "ielts-ben%c9%99lxalq-ingilis-dili-imtahani",
        "img": "https://celt.az/wp-content/uploads/2016/05/IELTS-hazırlığı-bakı-1024x804.jpg"
    },
    {
        "path": "tefl-tesol-iii"
    },
    {
        "path": "h%c9%99m-istirah%c9%99t-et-h%c9%99m-d%c9%99-ingilis-dili-oyr%c9%99n",
        "img": "https://celt.az/wp-content/uploads/2010/11/Relax-and-study-The-winning-mix.jpg"
    },
    {
        "path": "xaricd%c9%99-t%c9%99hsil-almaq-ist%c9%99y%c9%99nl%c9%99r-ucun",
        "img": "https://celt.az/wp-content/uploads/2017/11/Waitlist-News-feature.jpg"
    },
    {
        "path": "s%c9%99n-d%c9%99-t%c9%99l%c9%99b%c9%99-ol",
        "img": "https://celt.az/wp-content/uploads/2017/11/new-jersey-universitet.jpg"
    },
    {
        "path": "celt-memar-%c9%99c%c9%99mi",
        "img": "https://celt.az/wp-content/uploads/2017/11/new-jersey-universitet.jpg"
    },
    {
        "path": "ingilis-dilinin-t%c9%99drisi-v%c9%99-secim",
        "img": "https://celt.az/wp-content/uploads/2021/06/Resim1-1.png"
    },
    {
        "path": "usaqlari-m%c9%99kt%c9%99b%c9%99-nec%c9%99-hazirlamali",
        "img": "https://celt.az/wp-content/uploads/2017/11/new-jersey-universitet.jpg"
    },
    {
        "path": "az%c9%99rbaycanda-yoxsa-xaricd%c9%99-t%c9%99hsil",
        "img": "https://celt.az/wp-content/uploads/2017/11/Waitlist-News-feature.jpg"
    },
    {
        "path": "gre-gmat-yoxsa-sat-imtahani",
        "img": "https://celt.az/wp-content/uploads/2017/06/Coping_with_stress_at_university.jpg"
    },
    {
        "path": "ingilis-dilini-nec%c9%99-oyr%c9%99nm%c9%99li",
        "img": "https://celt.az/wp-content/uploads/2021/06/Resim1-1.png"
    },
    {
        "path": "work-and-travel-il%c9%99-yay-t%c9%99tilini-s%c9%99m%c9%99r%c9%99li-kecir",
        "img": "https://celt.az/wp-content/uploads/2017/07/Work_and_Travel-1024x275.jpg"
    },
    {
        "path": "adobe-photoshopcorel-drawautocad-proqramlari",
        "img": "https://celt.az/wp-content/uploads/2014/05/information_technology-1024x768.jpg"
    },
    {
        "path": "yos-yoxsa-sat-turkiy%c9%99y%c9%99-q%c9%99bul",
        "img": "https://celt.az/wp-content/uploads/2011/03/SAT-imtahani-ilə-təhsil.jpg"
    },
    {
        "path": "ingilis-dili-yeni-metodika-il%c9%99",
        "img": "https://celt.az/wp-content/uploads/2021/06/Resim1-1.png"
    },
    {
        "path": "h%c9%99r-k%c9%99sin-bilm%c9%99li-oldugu-komputer-proqrami",
        "img": "https://celt.az/wp-content/uploads/2014/05/information_technology-1024x768.jpg"
    },
    {
        "path": "almaniyada-pulsuz-t%c9%99hsil-al",
        "img": "https://celt.az/wp-content/uploads/2011/10/Germany.jpg"
    },
    {
        "path": "conversation-clubyerli-yoxsa-xarici",
        "img": "https://celt.az/wp-content/uploads/2021/06/Resim1-1.png"
    },
    {
        "path": "informasiya-texnologiyalarinin-t%c9%99hsild%c9%99-rolu",
        "img": "https://celt.az/wp-content/uploads/2017/11/Waitlist-News-feature.jpg"
    },
    {
        "path": "imtahan-onc%c9%99si-sinaqlar",
        "img": "https://celt.az/wp-content/uploads/2016/05/IELTS-hazırlığı-bakı-1024x804.jpg"
    },
    {
        "path": "xarici-yoxsa-yerli-mu%c9%99llim",
        "img": "https://celt.az/wp-content/uploads/2021/06/Resim1-1.png"
    },
    {
        "path": "t%c9%99l%c9%99b%c9%99nin-bilik-v%c9%99-bacariqlarinin-qiym%c9%99tl%c9%99ndirilm%c9%99si",
        "img": "https://celt.az/wp-content/uploads/2021/06/Resim1-1.png"
    },
    {
        "path": "ingilis-dilind%c9%99-nec%c9%99-danisim",
        "img": "https://celt.az/wp-content/uploads/2021/06/Resim1-1.png"
    },
    {
        "path": "nabranda-yoxsa-turkiy%c9%99d%c9%99-istirah%c9%99t",
        "img": "https://celt.az/wp-content/uploads/2010/11/Relax-and-study-The-winning-mix.jpg"
    },
    {
        "path": "work-and-travel-ugura-aparan-yol",
        "img": "https://celt.az/wp-content/uploads/2017/07/Work_and_Travel-1024x275.jpg"
    },
    {
        "path": "xaricd%c9%99-t%c9%99hsil-foundation-proqrami",
        "img": "https://celt.az/wp-content/uploads/2017/11/Waitlist-News-feature.jpg"
    },
    {
        "path": "distant",
        "img": "https://celt.az/wp-content/uploads/2013/03/embassy_summer_schools_london_me_studyroom.jpg"
    },
    {
        "path": "ingilis-dili-d%c9%99rsl%c9%99rind%c9%99-psixoloji-durumlar",
        "img": "https://celt.az/wp-content/uploads/2021/06/Resim1-1.png"
    },
    {
        "path": "danisiq-yoxsa-qrammatika",
        "img": "https://celt.az/wp-content/uploads/2011/07/aid98614-v4-728px-Write-an-English-Essay-Step-1-Version-2.jpg"
    },
    {
        "path": "az-yaslilara-ingilis-dilinin-oyr%c9%99dilm%c9%99si",
        "img": "https://celt.az/wp-content/uploads/2018/11/psh67.png"
    },
    {
        "path": "bir-az-da-t%c9%99nqid"
    },
    {
        "path": "az%c9%99rbaycan-t%c9%99hsili-internet-m%c9%99kaninda"
    },
    {
        "path": "internetd%c9%99n-duzgun-istifad%c9%99-edirikmi"
    },
    {
        "path": "t%c9%99drisd%c9%99-yas-dovrl%c9%99ri",
        "img": "https://celt.az/wp-content/uploads/2017/11/new-jersey-universitet.jpg"
    },
    {
        "path": "ingilis-dilin-d%c9%99rsl%c9%99rind%c9%99-interaktiv-metod",
        "img": "https://celt.az/wp-content/uploads/2021/06/Resim1-1.png"
    },
    {
        "path": "g%c9%99ncliyimiz-v%c9%99-t%c9%99hsilimiz"
    },
    {
        "path": "xaricd%c9%99-t%c9%99hsil-kanadaya-q%c9%99bul",
        "img": "https://celt.az/wp-content/uploads/2013/10/canada1.jpg"
    },
    {
        "path": "summer-school-in-nabran",
        "img": "https://celt.az/wp-content/uploads/2010/11/Relax-and-study-The-winning-mix.jpg"
    },
    {
        "path": "ingilis-dili-d%c9%99rsl%c9%99rind%c9%99-insa-yazilari",
        "img": "https://celt.az/wp-content/uploads/2011/07/aid98614-v4-728px-Write-an-English-Essay-Step-1-Version-2.jpg"
    },
    {
        "path": "t%c9%99hsil-nazirliyi-hansi-ixtisaslari-maliyy%c9%99l%c9%99sdirir",
        "img": "https://celt.az/wp-content/uploads/2017/11/Waitlist-News-feature.jpg"
    },
    {
        "path": "sagirdl%c9%99rin-biliyinin-qiym%c9%99tl%c9%99ndirilm%c9%99si",
        "img": "https://celt.az/wp-content/uploads/2021/06/Resim1-1.png"
    },
    {
        "path": "xaricd%c9%99-t%c9%99hsilnorvec%c9%99-q%c9%99bul",
        "img": "https://celt.az/wp-content/uploads/2017/11/Waitlist-News-feature.jpg"
    },
    {
        "path": "bolonya-sistemi-v%c9%99-az%c9%99rbaycan-t%c9%99hsili"
    },
    {
        "path": "dunya-t%c9%99hsilin%c9%99-s%c9%99yah%c9%99t"
    },
    {
        "path": "t%c9%99hsild%c9%99-yeni-metodologiyalar-v%c9%99-t%c9%99hsil-sisteml%c9%99ri"
    },
    {
        "path": "65-yasli-mu%c9%99lliml%c9%99r-t%c9%99qaud%c9%99-gond%c9%99ril%c9%99c%c9%99kmi"
    },
    {
        "path": "ingilis-dilin-oyr%c9%99ndunyaya-f%c9%99rqli-bax",
        "img": "https://celt.az/wp-content/uploads/2021/06/Resim1-1.png"
    },
    {
        "path": "valideynl%c9%99r-diqq%c9%99tli-olmalidirlar",
        "img": "https://celt.az/wp-content/uploads/2017/11/Waitlist-News-feature.jpg"
    },
    {
        "path": "xaricd%c9%99-nec%c9%99-t%c9%99hsil-ala-bil%c9%99r%c9%99m",
        "img": "https://celt.az/wp-content/uploads/2016/11/set-1024x768.jpg"
    },
    {
        "path": "kicik-yaslilar-ingilis-dilini-nec%c9%99-oyr%c9%99nm%c9%99li",
        "img": "https://celt.az/wp-content/uploads/2018/11/14090487-2-pic905-905x505-53012.jpg"
    },
    {
        "path": "ingilis-dili"
    },
    {
        "path": "umumi-ingilis-dili-kurslari",
        "img": "https://celt.az/wp-content/uploads/2012/12/english_20language_20company-2.jpg"
    },
    {
        "path": "intensiv-ingilis-dili-kurslari",
        "img": "https://celt.az/wp-content/uploads/2021/06/Resim1-1.png"
    },
    {
        "path": "dil-v%c9%99rdisl%c9%99rin%c9%99-praktik-yiy%c9%99l%c9%99nm%c9%99-m%c9%99s%c9%99l%c9%99l%c9%99ri",
        "img": "https://celt.az/wp-content/uploads/2021/06/Resim1-1.png"
    },
    {
        "path": "fasil%c9%99siz-ingilis-dili-kurslari",
        "img": "https://celt.az/wp-content/uploads/2014/03/IMG_0241.jpg"
    },
    {
        "path": "ingilis-dili-beginner-s%c9%99viyy%c9%99si",
        "img": "https://celt.az/wp-content/uploads/2014/04/teaching-english-as-a-foreign-language.jpg"
    },
    {
        "path": "ingilis-dili-elementary-s%c9%99viyy%c9%99si",
        "img": "https://celt.az/wp-content/uploads/2021/06/Resim1-1.png"
    },
    {
        "path": "celt-elml%c9%99r"
    },
    {
        "path": "ingilis-dili-pre-intermedate-s%c9%99viyy%c9%99si",
        "img": "https://celt.az/wp-content/uploads/2010/07/celt-english.jpg"
    },
    {
        "path": "celt-28-may"
    },
    {
        "path": "celt-g%c9%99nclik",
        "img": "https://celt.az/wp-content/uploads/2015/10/celt-collega-1024x755.png"
    },
    {
        "path": "celt-ic%c9%99ri-s%c9%99h%c9%99r-i"
    },
    {
        "path": "toefl-v%c9%99-ielts-imtahanlarina-qeydiyyat",
        "img": "https://celt.az/wp-content/uploads/2021/06/Resim1-1.png"
    },
    {
        "path": "ingilis-dilini-biziml%c9%99-oyr%c9%99n",
        "img": "https://celt.az/wp-content/uploads/2021/06/Resim1-1.png"
    },
    {
        "path": "ingilisdilli-yay-dus%c9%99rg%c9%99si",
        "img": "https://celt.az/wp-content/uploads/2010/11/Relax-and-study-The-winning-mix.jpg"
    },
    {
        "path": "foundation-kecm%c9%99d%c9%99n-xaricd%c9%99-t%c9%99hsil-al",
        "img": "https://celt.az/wp-content/uploads/2017/11/Waitlist-News-feature.jpg"
    },
    {
        "path": "fontys-universiteti",
        "img": "https://celt.az/wp-content/uploads/2015/12/2-1024x341.jpg"
    },
    {
        "path": "ingilis-dili-d%c9%99rsl%c9%99rind%c9%99-psixologiyanin-%c9%99h%c9%99miyy%c9%99ti",
        "img": "https://celt.az/wp-content/uploads/2012/12/shutterstock_7526713.jpg"
    },
    {
        "path": "dovl%c9%99t-hesabina-xaricd%c9%99-t%c9%99hsil-al",
        "img": "https://celt.az/wp-content/uploads/2018/06/uk-baki-image.jpg"
    },
    {
        "path": "xaricd%c9%99-t%c9%99hsil-almaq-imkanlari",
        "img": "https://celt.az/wp-content/uploads/2013/03/embassy_summer_schools_london_ucl_residence_garden.jpg"
    },
    {
        "path": "almaniyada-t%c9%99hsil",
        "img": "https://celt.az/wp-content/uploads/2013/03/embassy-summer-los-angeles-northridge-shopping.jpg"
    },
    {
        "path": "distant-t%c9%99hsil-uzr%c9%99-d%c9%99r%c9%99c%c9%99l%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2013/03/embassy_summer_schools_london_me_studyroom.jpg"
    },
    {
        "path": "umumi-ingilis-dili-2",
        "img": "https://celt.az/wp-content/uploads/2013/03/embassy_summer_schools_santa_clara_campus.jpg"
    },
    {
        "path": "toefl-imtahanina-nec%c9%99-hazirlasa-bil%c9%99r%c9%99m",
        "img": "https://celt.az/wp-content/uploads/2013/03/embassy_summer_schools_ucl_trips_british_museum.jpg"
    },
    {
        "path": "litvada-tehsil",
        "img": "https://celt.az/wp-content/uploads/2019/05/23-may.jpg"
    },
    {
        "path": "xaricd%c9%99-t%c9%99hsil-al-innovativ-dusun",
        "img": "https://celt.az/wp-content/uploads/2013/03/embassy_summer_schools_london_me_trip_buckingham_palace.jpg"
    },
    {
        "path": "qaranliqdan-baxmat%c9%99hsil-il%c9%99-g%c9%99l%c9%99c%c9%99yini-isiqlandir",
        "img": "https://celt.az/wp-content/uploads/2013/07/light-1024x729.jpg"
    },
    {
        "path": "s%c9%99lis-insan",
        "img": "https://celt.az/wp-content/uploads/2013/07/Lutfi-Zade.jpg"
    },
    {
        "path": "qirmizi-numun%c9%99-elgun-k%c9%99rimov-cunki-az%c9%99rbaycana-bu-lazimdir",
        "img": "https://celt.az/wp-content/uploads/2013/07/William_Paterson_University_of_New_Jersey_1.jpg"
    },
    {
        "path": "xaricd%c9%99-t%c9%99hsil-ucun-5-s%c9%99b%c9%99b"
    },
    {
        "path": "niy%c9%99-amerika-i-ci-hiss%c9%99",
        "img": "https://celt.az/wp-content/uploads/2013/08/usa.jpg"
    },
    {
        "path": "amerika-g%c9%99ncl%c9%99rinin-h%c9%99yati-life-of-american-youth",
        "img": "https://celt.az/wp-content/uploads/2013/08/life-of.jpg"
    },
    {
        "path": "secim",
        "img": "https://celt.az/wp-content/uploads/2013/08/secim.jpg"
    },
    {
        "path": "m%c9%99n-kim%c9%99m",
        "img": "https://celt.az/wp-content/uploads/2013/08/men-kimem.jpg"
    },
    {
        "path": "ielts-vs-toefl-hansi-daha-yaxsidir",
        "img": "https://celt.az/wp-content/uploads/2013/08/İELTS-VS-TOEFL.jpg"
    },
    {
        "path": "yeni-d%c9%99rs-ilind%c9%99-xidm%c9%99tinizd%c9%99yik",
        "img": "https://celt.az/wp-content/uploads/2013/09/yeni.jpg"
    },
    {
        "path": "ugurlu-t%c9%99rcum%c9%99ciniz-talking-translatordictionary",
        "img": "https://celt.az/wp-content/uploads/2013/09/mobiapp.jpg"
    },
    {
        "path": "student-life-t%c9%99l%c9%99b%c9%99-h%c9%99yati",
        "img": "https://celt.az/wp-content/uploads/2013/09/studentlife.jpg"
    },
    {
        "path": "pes%c9%99kar-mu%c9%99llim",
        "img": "https://celt.az/wp-content/uploads/2013/09/professionla-teacher.jpg"
    },
    {
        "path": "xarici-mu%c9%99lliml%c9%99rin-t%c9%99qdimatinda-ingilis-dili-kurslari-celt-colleges",
        "img": "https://celt.az/wp-content/uploads/2013/09/teachers.jpg"
    },
    {
        "path": "mobil-telefonundan-oyr%c9%99n-english-grammar-book",
        "img": "https://celt.az/wp-content/uploads/2013/09/MOBİAPP2.jpg"
    },
    {
        "path": "ingilis-payiz",
        "img": "https://celt.az/wp-content/uploads/2013/10/ingilispayiz.jpg"
    },
    {
        "path": "harry-potter-az%c9%99rbaycanda-olsa",
        "img": "https://celt.az/wp-content/uploads/2013/10/harry-1024x577.jpg"
    },
    {
        "path": "s%c9%99nin-elektron-poct-h%c9%99yatin-windows-live-mail",
        "img": "https://celt.az/wp-content/uploads/2013/10/Windows-Live-help.png"
    },
    {
        "path": "oxu-kitap-kitab",
        "img": "https://celt.az/wp-content/uploads/2013/10/OXUKI-TAP.jpg"
    },
    {
        "path": "s%c9%99nin-musiqili-mu%c9%99llimin",
        "img": "https://celt.az/wp-content/uploads/2013/10/senin-musiqili-muellimin.jpg"
    },
    {
        "path": "sizin-h%c9%99r-yerd%c9%99-etibarli-lug%c9%99tiniz-abbyy-lingvo",
        "img": "https://celt.az/wp-content/uploads/2013/10/ABBYY-Lingvo-3.jpg"
    },
    {
        "path": "4luk-boyuk-britaniya",
        "img": "https://celt.az/wp-content/uploads/2013/10/UK.jpg"
    },
    {
        "path": "d%c9%99qiq-t%c9%99hsil-almaniya",
        "img": "https://celt.az/wp-content/uploads/2013/10/germany.jpg"
    },
    {
        "path": "dunyanin-insan-kapitali-paytaxti-kanada",
        "img": "https://celt.az/wp-content/uploads/2013/10/go-to-canada-1024x965.jpg"
    },
    {
        "path": "do-you-speak-english",
        "img": "https://celt.az/wp-content/uploads/2013/11/376958548_640.jpg"
    },
    {
        "path": "celt-28-may-da-1-gun-real-bloq",
        "img": "https://celt.az/wp-content/uploads/2013/11/Celt-28Mayda-1-Gun-1024x533.png"
    },
    {
        "path": "almaniyanin-1%e2%84%96-li-universiteti",
        "img": "https://celt.az/wp-content/uploads/2013/11/ALMANIYA-1-CI-UNIVERSITET.jpg"
    },
    {
        "path": "mancester-universiteti"
    },
    {
        "path": "avstriyanin-t%c9%99hsil-incisi-vyana-universiteti",
        "img": "https://celt.az/wp-content/uploads/2013/11/Vyana-University-1024x418.jpg"
    },
    {
        "path": "do-you-understand-yalniz-bu-sozu-cox-esid%c9%99nl%c9%99r-ucun",
        "img": "https://celt.az/wp-content/uploads/2013/11/DO-1024x483.jpg"
    },
    {
        "path": "h%c9%99r-gun-yeni-99-soz-oyr%c9%99n-yeni-soz-kosmik-g%c9%99misi",
        "img": "https://celt.az/wp-content/uploads/2013/11/englihs-bloq3.png"
    },
    {
        "path": "islam-elmin-t%c9%99hsilin-besiyi-dunyanin-%c9%99n-q%c9%99dim-v%c9%99-ilk-universiteti",
        "img": "https://celt.az/wp-content/uploads/2013/11/ISLAM3.jpg"
    },
    {
        "path": "uz-yoxsa-kitab-facebook",
        "img": "https://celt.az/wp-content/uploads/2013/12/facebook-uz-yoxsa-kitab-1024x597.jpg"
    },
    {
        "path": "konulluluk-%c9%99m%c9%99ksev%c9%99rliyin-insansev%c9%99rliyin-d%c9%99y%c9%99ridir",
        "img": "https://celt.az/wp-content/uploads/2013/12/1381813548.png"
    },
    {
        "path": "my-english-level-c2-and-you-cefr-levels",
        "img": "https://celt.az/wp-content/uploads/2013/12/HMMM.jpg"
    },
    {
        "path": "turkiy%c9%99nin-tarixin-dasiyicisi-istanbul-universiteti",
        "img": "https://celt.az/wp-content/uploads/2013/12/IstanbulUni-Logo.jpg"
    },
    {
        "path": "ingilt%c9%99r%c9%99nin-t%c9%99hsil-incisi-oksford-universiteti",
        "img": "https://celt.az/wp-content/uploads/2013/12/OKSFORD1.jpg"
    },
    {
        "path": "vaxt-itkisi-pul-itkisind%c9%99n-d%c9%99-acidir",
        "img": "https://celt.az/wp-content/uploads/2013/12/vaxt.jpg"
    },
    {
        "path": "iki-ev-yoxsa-kiyev",
        "img": "https://celt.az/wp-content/uploads/2013/12/kiyev7.jpg"
    },
    {
        "path": "insanlari-birl%c9%99sdir%c9%99n-yeni-il-agaci",
        "img": "https://celt.az/wp-content/uploads/2013/12/Artificial-Christmas-Tree.jpeg"
    },
    {
        "path": "m%c9%99n-harada-oxumaliyam-i-ci-hiss%c9%99-top-universitetl%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2013/12/MEN-HARDA-1024x594.jpg"
    },
    {
        "path": "m%c9%99n-harada-oxumaliyam-i-ci-hiss%c9%99-ii-bolum-top-universitetl%c9%99r-top-400-top-500",
        "img": "https://celt.az/wp-content/uploads/2013/12/to400.jpg"
    },
    {
        "path": "5-lik-almaniya-universitetl%c9%99ri",
        "img": "https://celt.az/wp-content/uploads/2014/01/5lik-1024x815.jpg"
    },
    {
        "path": "arzular-onu-b%c9%99rk-b%c9%99rk-tut-ki-s%c9%99nin-olsun",
        "img": "https://celt.az/wp-content/uploads/2014/01/arzular.jpg"
    },
    {
        "path": "etibarli-dost",
        "img": "https://celt.az/wp-content/uploads/2014/01/etibarli-dost.jpg"
    },
    {
        "path": "italyan-roma",
        "img": "https://celt.az/wp-content/uploads/2014/01/italyan.jpg"
    },
    {
        "path": "m%c9%99n-harada-oxumaliyam-italiya",
        "img": "https://celt.az/wp-content/uploads/2014/01/italy.jpg"
    },
    {
        "path": "ielts-mobil-telefonunuzda-android-v%c9%99-ios-%c9%99s-telefonlar-ucun",
        "img": "https://celt.az/wp-content/uploads/2014/01/ielts-esas.png"
    },
    {
        "path": "hec-vaxt-yatmayan-s%c9%99h%c9%99rl%c9%99r-new-york",
        "img": "https://celt.az/wp-content/uploads/2014/01/HECVAXT.jpg"
    },
    {
        "path": "qizildan-qiym%c9%99tli-vaxt-i-ci-hiss%c9%99",
        "img": "https://celt.az/wp-content/uploads/2014/02/VAXT.jpg"
    },
    {
        "path": "g%c9%99lin-tanis-olaq-xocali-22-yas-azen",
        "img": "https://celt.az/wp-content/uploads/2014/02/meneedalet-1024x681.jpg"
    },
    {
        "path": "ingilis-dili-lug%c9%99ti-basic-english-android-%c9%99s-telefonlar-ucun",
        "img": "https://celt.az/wp-content/uploads/2014/03/BLOQ1.jpg"
    },
    {
        "path": "essex-universiteti"
    },
    {
        "path": "poznan-universiteti"
    },
    {
        "path": "polsada-universitetl%c9%99r-yaranmasi-varsava-universiteti"
    },
    {
        "path": "harvard-universiteti",
        "img": "https://celt.az/wp-content/uploads/2021/05/Harvard-Universitesi.jpg"
    },
    {
        "path": "amerikada-t%c9%99hsil-sistemi",
        "img": "https://celt.az/wp-content/uploads/2018/03/masters-degree-usa.jpg"
    },
    {
        "path": "kaliforniya-texnologiya-universiteti",
        "img": "https://celt.az/wp-content/uploads/2021/05/Harvard-Universitesi.jpg"
    },
    {
        "path": "poznan-iqtisadiyyat-universiteti"
    },
    {
        "path": "wroclaw-iqtisadiyyat-universiteti"
    },
    {
        "path": "lodz-texnologiya-universiteti",
        "img": "https://celt.az/wp-content/uploads/2018/05/IMG_0555.jpeg"
    },
    {
        "path": "lazarski-universiteti"
    },
    {
        "path": "london-iqtisadiyyat-v%c9%99-siyasi-elml%c9%99r-m%c9%99kt%c9%99bi"
    },
    {
        "path": "soas-london-universiteti"
    },
    {
        "path": "birkbeck-universiteti"
    },
    {
        "path": "southampton-universiteti"
    },
    {
        "path": "almaniyada-t%c9%99qaudlu-t%c9%99hsil-az%c9%99rbaycanli-t%c9%99l%c9%99b%c9%99l%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2017/12/pppp.jpg"
    },
    {
        "path": "munchen-texniki-universiteti",
        "img": "https://celt.az/wp-content/uploads/2018/03/malmo-c.jpg"
    },
    {
        "path": "almaniyada-ali-t%c9%99hsil",
        "img": "https://celt.az/wp-content/uploads/2017/11/Waitlist-News-feature.jpg"
    },
    {
        "path": "distant-t%c9%99hsil-n%c9%99dir-az%c9%99rbaycanda-distant-t%c9%99hsil-sistemi"
    },
    {
        "path": "almaniyada-magistratura",
        "img": "https://celt.az/wp-content/uploads/2011/10/study-in-germany-1024x827.png"
    },
    {
        "path": "almaniya-universitetl%c9%99ri"
    },
    {
        "path": "ruprext-karl-heydelberq-universiteti",
        "img": "https://celt.az/wp-content/uploads/2018/03/Trinity-College-Library-Dublin.jpg"
    },
    {
        "path": "lider-t%c9%99l%c9%99b%c9%99l%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2018/03/masters-degree-usa.jpg"
    },
    {
        "path": "xaricd%c9%99-t%c9%99hsil-v%c9%99-almaniya"
    },
    {
        "path": "ingilt%c9%99r%c9%99d%c9%99-t%c9%99hsil",
        "img": "https://celt.az/wp-content/uploads/2014/04/Preston_Business_Centre_University_of_Central_Lancs_-_geograph.org_.uk_-_28324.jpg"
    },
    {
        "path": "niy%c9%99-ingilt%c9%99r%c9%99",
        "img": "https://celt.az/wp-content/uploads/2018/01/London-Graduation-Shoot-Westminster-1.jpg"
    },
    {
        "path": "sunderland-universiteti-haqqinda",
        "img": "https://celt.az/wp-content/uploads/2018/05/IMG_0546.jpeg"
    },
    {
        "path": "dunyaya-liderlik-ed%c9%99n-ingilt%c9%99r%c9%99-t%c9%99hsili-sistemi"
    },
    {
        "path": "celt-il%c9%99-ingilt%c9%99r%c9%99nin-h%c9%99r-yerind%c9%99-t%c9%99hsil-ala-bil%c9%99rsiniz"
    },
    {
        "path": "dil-kurslari-il%c9%99-bagli-%c9%99n-cox-veril%c9%99n-suallar"
    },
    {
        "path": "dunya-standartlarina-uygun-t%c9%99hsil-alin"
    },
    {
        "path": "xaricd%c9%99-t%c9%99hsil-unudulmayacaq-mac%c9%99ranin-baslangici"
    },
    {
        "path": "macaristanda-magistr-t%c9%99hsili-almagin-%c9%99n-asan-yolu",
        "img": "https://celt.az/wp-content/uploads/2014/12/Flag_of_Hungary_1867-1918.svg_.png"
    },
    {
        "path": "debrecen-s%c9%99h%c9%99ri-v%c9%99-universiteti"
    },
    {
        "path": "niy%c9%99-macaristan"
    },
    {
        "path": "norvecd%c9%99-pulsuz-t%c9%99hsil",
        "img": "https://celt.az/wp-content/uploads/2013/09/norveçdə-təhsil.jpg"
    },
    {
        "path": "norvecd%c9%99-universitet-v%c9%99-kollecl%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2013/09/norveçdə-təhsil.jpg"
    },
    {
        "path": "oslo-universiteti",
        "img": "https://celt.az/wp-content/uploads/2013/09/norveçdə-təhsil.jpg"
    },
    {
        "path": "niderlan-v%c9%99-ya-hollandiyada-ali-t%c9%99hsil",
        "img": "https://celt.az/wp-content/uploads/2015/12/2-1024x341.jpg"
    },
    {
        "path": "t%c9%99hsil-haqqindan-azad-olan-olk%c9%99l%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2012/10/MTAyNHg3Njg11663521_11613245.jpg"
    },
    {
        "path": "niy%c9%99-niderland",
        "img": "https://celt.az/wp-content/uploads/2015/12/2-1024x341.jpg"
    },
    {
        "path": "turkiy%c9%99d%c9%99-t%c9%99hsil-almagin-ustunlukl%c9%99ri-v%c9%99-top-10-universitet",
        "img": "https://celt.az/wp-content/uploads/2017/11/turkiyede-tehsil.jpg"
    },
    {
        "path": "amerikada-10-000-liq-t%c9%99qaud-proqrammi",
        "img": "https://celt.az/wp-content/uploads/2021/05/Harvard-Universitesi.jpg"
    },
    {
        "path": "royal-road-university-d%c9%99-t%c9%99qaud-proqrammi",
        "img": "https://celt.az/wp-content/uploads/2021/05/Harvard-Universitesi.jpg"
    },
    {
        "path": "new-york-inistitundan-az%c9%99rbaycanli-10-t%c9%99l%c9%99b%c9%99-ucun-full-schoolarship",
        "img": "https://celt.az/wp-content/uploads/2014/11/new_york_1-1024x680.jpg"
    },
    {
        "path": "ingilt%c9%99r%c9%99-v%c9%99-kanadada-t%c9%99qaud-proqrami"
    },
    {
        "path": "isvecr%c9%99d%c9%99-ayliq-1300-avro-t%c9%99qaud-almaq-imkani",
        "img": "https://celt.az/wp-content/uploads/2017/12/İsveçrədə-Təhsil-ilə-bağlı-Sual-və-Cavablar.jpg"
    },
    {
        "path": "hollandiyada-pulsuz-t%c9%99hsil",
        "img": "https://celt.az/wp-content/uploads/2015/12/2-1024x341.jpg"
    },
    {
        "path": "ingilt%c9%99r%c9%99-%c9%99n-yaxsi-universitet-hansidir"
    },
    {
        "path": "isvecr%c9%99d%c9%99-magistr-t%c9%99hsili-ucun-full-scholarship",
        "img": "https://celt.az/wp-content/uploads/2017/12/İsveçrədə-Təhsil-ilə-bağlı-Sual-və-Cavablar.jpg"
    },
    {
        "path": "siz%c9%99-n%c9%99-lazimdir"
    },
    {
        "path": "londonda-oxumagin-sebebleri"
    },
    {
        "path": "londonda-tehsil",
        "img": "https://celt.az/wp-content/uploads/2014/11/london-eye_1428544c.jpg"
    },
    {
        "path": "manchester-boyuk-britaniyanin-real-m%c9%99d%c9%99niyy%c9%99t-paytaxti-kimi"
    },
    {
        "path": "birminghamda-h%c9%99yat"
    },
    {
        "path": "glasgow-sotlandiyanin-%c9%99n-maraqli-s%c9%99h%c9%99ri-kimi"
    },
    {
        "path": "amerikada-bakalvr-t%c9%99hsili-dusun%c9%99n-h%c9%99r-k%c9%99s-bunlari-bilm%c9%99lidir",
        "img": "https://celt.az/wp-content/uploads/2021/05/Harvard-Universitesi.jpg"
    },
    {
        "path": "xaricd%c9%99-oxumaq-ucun-10-qizil-s%c9%99b%c9%99b"
    },
    {
        "path": "distant-t%c9%99hsild%c9%99-yaxsini-pisd%c9%99n-nec%c9%99-ayird-ed%c9%99-bil%c9%99rik"
    },
    {
        "path": "t%c9%99hsil-haqqisi-%c9%99n-ucuz-olan-oz%c9%99l-universitetl%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2021/05/Harvard-Universitesi.jpg"
    },
    {
        "path": "dizayn-v%c9%99-yaradiciliq-paytaxti-milan"
    },
    {
        "path": "g%c9%99l%c9%99c%c9%99k-ucun-%c9%99n-perspektivli-9-ixtisas",
        "img": "https://celt.az/wp-content/uploads/2018/11/razgovorn.jpg"
    },
    {
        "path": "boyuk-britaniyada-magistr-t%c9%99hsili"
    },
    {
        "path": "ingilt%c9%99r%c9%99d%c9%99-universitet-%c9%99vv%c9%99li-hazirliq-programlari-foundation"
    },
    {
        "path": "parisd%c9%99-moda-dizayn-t%c9%99hsili"
    },
    {
        "path": "ingilt%c9%99r%c9%99d%c9%99-t%c9%99hsill%c9%99-bagli-%c9%99n-cox-sorusulan-suallar"
    },
    {
        "path": "moda-t%c9%99hsili-haqqinda-h%c9%99r-sey",
        "img": "https://celt.az/wp-content/uploads/2013/12/modadizayn-1024x576.jpg"
    },
    {
        "path": "nec%c9%99-harda-n%c9%99-vaxt-n%c9%99-q%c9%99d%c9%99r"
    },
    {
        "path": "%c9%99n-yaxsi-universitet-ixtisaslari",
        "img": "https://celt.az/wp-content/uploads/2016/06/ən-çox-qazandıran-ixtisaslar.jpg"
    },
    {
        "path": "foundation-t%c9%99hsilini-s%c9%99nd%c9%99-t%c9%99qaudl%c9%99-al"
    },
    {
        "path": "westminster-universitetind%c9%99-t%c9%99qaudl%c9%99-t%c9%99hsil"
    },
    {
        "path": "xaricd%c9%99-kiml%c9%99r-oxuya-bil%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2014/03/IMG_0241.jpg"
    },
    {
        "path": "almaniyada-dogru-universitet-secimi"
    },
    {
        "path": "polsada-t%c9%99hsil",
        "img": "https://celt.az/wp-content/uploads/2014/12/poland-fairs.jpg"
    },
    {
        "path": "almaniyada-t%c9%99hsil-2-2"
    },
    {
        "path": "niy%c9%99-mba-bu-t%c9%99hsil-n%c9%99-q%c9%99d%c9%99r-lazimlidir",
        "img": "https://celt.az/wp-content/uploads/2013/08/20130807_100316-1024x768.jpg"
    },
    {
        "path": "mba-m%c9%99zunlari-n%c9%99-q%c9%99d%c9%99r-maas-alir",
        "img": "https://celt.az/wp-content/uploads/2013/08/20130807_101245-1024x768.jpg"
    },
    {
        "path": "%c9%99n-yaxsi-universitetl%c9%99rd%c9%99-online-t%c9%99hsil"
    },
    {
        "path": "amerika-yoxsa-ingilt%c9%99r%c9%99"
    },
    {
        "path": "city-university-london-haqqinda-butun-m%c9%99lumatlar"
    },
    {
        "path": "bu-5-s%c9%99hvi-t%c9%99krarlayan-xaricd%c9%99-oxumagi-unutsun"
    },
    {
        "path": "isvecd%c9%99-magistr-t%c9%99qaud-proqrami"
    },
    {
        "path": "finlandiyada-h%c9%99m-magistr-t%c9%99hsili-al-h%c9%99md%c9%99-t%c9%99hsil-haqqindan-azad-ol"
    },
    {
        "path": "amerikada-t%c9%99qaud"
    },
    {
        "path": "macaristanda-t%c9%99hsil-x%c9%99rcl%c9%99ri"
    },
    {
        "path": "macaristanda-t%c9%99hsil-almagin-ustunlukl%c9%99ri"
    },
    {
        "path": "ikinci-dil-oyr%c9%99nm%c9%99yin-ustunlukl%c9%99ri"
    },
    {
        "path": "8-addimla-xaricd%c9%99-t%c9%99hsil-alin",
        "img": "https://celt.az/wp-content/uploads/2018/01/London-Graduation-Shoot-Westminster-1.jpg"
    },
    {
        "path": "lodz-universiteti",
        "img": "https://celt.az/wp-content/uploads/2018/02/myo-01.jpg"
    },
    {
        "path": "harvard-universiteti-il%c9%99-bagli-veril%c9%99n-suallar"
    },
    {
        "path": "nabran-yay-m%c9%99kt%c9%99bi"
    },
    {
        "path": "muasir-mu%c9%99llim-nec%c9%99-olmalidir"
    },
    {
        "path": "on-the-internet-yoxsa-in-the-internet",
        "img": "https://celt.az/wp-content/uploads/2014/11/Internet_of_Things1.jpg"
    },
    {
        "path": "dill%c9%99r-sizin-dusunc%c9%99-t%c9%99rzini-d%c9%99yism%c9%99y%c9%99-qadirdirmi",
        "img": "https://celt.az/wp-content/uploads/2014/12/featured_new_polyglot.jpg"
    },
    {
        "path": 19993,
        "img": "https://celt.az/wp-content/uploads/2014/12/maxresdefault.jpg"
    },
    {
        "path": "macaristanin-gorm%c9%99li-16-goz%c9%99l-yeri",
        "img": "https://celt.az/wp-content/uploads/2014/12/buda1.jpg"
    },
    {
        "path": "in-the-picture-yoxsa-on-the-picture-in-a-photo-yoxsa-on-a-photo",
        "img": "https://celt.az/wp-content/uploads/2014/12/IMG_1126.jpg"
    },
    {
        "path": "ingilis-dili-c%c9%99tin-dildirmi",
        "img": "https://celt.az/wp-content/uploads/2014/12/small1.jpg"
    },
    {
        "path": "sat-imtahani-n%c9%99dir",
        "img": "https://celt.az/wp-content/uploads/2015/10/SAT.jpg"
    },
    {
        "path": "celt-conversation-club",
        "img": "https://celt.az/wp-content/uploads/2021/06/Resim1-1.png"
    },
    {
        "path": "celt-colleges-pulsuz-d%c9%99rsl%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2015/11/Free-English-Trial-lesson-in-London--1024x1024.png"
    },
    {
        "path": "beyn%c9%99lxalq-%c9%99laq%c9%99l%c9%99r-yoxsa-beyn%c9%99lxalq-%c9%99laq%c9%99l%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2016/05/summer4-1024x768.gif"
    },
    {
        "path": "xaricd%c9%99-oxumaq-ucun-kiml%c9%99r-t%c9%99qaud-ala-bil%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2016/05/Scholarships-Are-Incredibly-Competitive.jpg"
    },
    {
        "path": "ielts-imtahani-ucun-%c9%99n-yaxsi-kitablar-profesional-b%c9%99l%c9%99dci",
        "img": "https://celt.az/wp-content/uploads/2016/05/IELTS-hazırlığı-bakı-1024x804.jpg"
    },
    {
        "path": "otto-von-bismarck",
        "img": "https://celt.az/wp-content/uploads/2016/05/Otto_von_Bismarck-748x1024.jpg"
    },
    {
        "path": "en-cox-qazanci-olan-ixtisaslar",
        "img": "https://celt.az/wp-content/uploads/2014/11/marketing-job-growth.jpg"
    },
    {
        "path": "bangor-university"
    },
    {
        "path": "vakansiya"
    },
    {
        "path": "hair-is-ya-hair-are-yoxsa-hairs-are",
        "img": "https://celt.az/wp-content/uploads/2021/06/Resim1-1.png"
    },
    {
        "path": "consumer-yoxsa-customer",
        "img": "https://celt.az/wp-content/uploads/2021/06/Resim1-1.png"
    },
    {
        "path": "2-5-saat-celtd%c9%99-diliniz%c9%99-ayirdiginiz-vaxt"
    },
    {
        "path": "m%c9%99n-harada-oxumaliyam-ispaniya"
    },
    {
        "path": "qirmizi-numun%c9%99-motivasiya-layih%c9%99si-rovs%c9%99n-qasimov-k%c9%99sfl%c9%99ri-izl%c9%99m%c9%99yin-k%c9%99sf-edin"
    },
    {
        "path": "qizil-bil%c9%99rzik"
    },
    {
        "path": "celth%c9%99yat%c9%99rzi-istedadli-g%c9%99ncliyin-t%c9%99rzi"
    },
    {
        "path": "bu-h%c9%99yatda-1-d%c9%99f%c9%99-yasayiriq-ona-gor%c9%99d%c9%99-risk-etm%c9%99k-lazimdir-mubariz%c9%99-aparmaq-lazimdir"
    },
    {
        "path": "qirmizi-numun%c9%99-motivasiya-layih%c9%99si-n%c9%99rmin-%c9%99liyeva-agil-d%c9%99ryasi"
    },
    {
        "path": "qirmizi-numun%c9%99-motivasiya-layih%c9%99si-elcin-haciyev"
    },
    {
        "path": "anam-m%c9%99n%c9%99-bizim-yem%c9%99kl%c9%99ri-bisirm%c9%99yi-skype-vasit%c9%99si-il%c9%99-oyr%c9%99dir"
    },
    {
        "path": "h%c9%99r-bir-xanim-oz-g%c9%99l%c9%99c%c9%99yini-sigortalamalidir"
    },
    {
        "path": "1835-avroyaa-hollandiyada-magistr-t%c9%99hsili"
    },
    {
        "path": "amerikada-olmaq-yenid%c9%99n-dogulmaq-kimidir"
    },
    {
        "path": "%c9%99g%c9%99r-afrika-da-ingilt%c9%99r%c9%99-kimi-keyfiyy%c9%99tli-t%c9%99hsil-t%c9%99klif-ets%c9%99ydi-afrikani-sec%c9%99rdim"
    },
    {
        "path": "qiz-oldugum-ucun-xaricd%c9%99-oxumagima-q%c9%99rib%c9%99-baxirdilar"
    },
    {
        "path": "yeni-bloq-yayimi"
    },
    {
        "path": "ilk-addim-celt-kids-usaginizin-saglam-addimi"
    },
    {
        "path": "xaricd%c9%99-oxumagin-avantajlari"
    },
    {
        "path": "qirmizi-numun%c9%99m%c9%99tin"
    },
    {
        "path": "ucuz-%c9%99tin-sorbasi-olmaz-ucuz-kurslara-aldanmayin"
    },
    {
        "path": "0-dan-basla"
    },
    {
        "path": "xaricd%c9%99-yay-dus%c9%99rg%c9%99l%c9%99ri"
    },
    {
        "path": "aberystwyth-university",
        "img": "https://celt.az/wp-content/uploads/2021/06/Resim1-1.png"
    },
    {
        "path": "bellerbys-college"
    },
    {
        "path": "cats-college"
    },
    {
        "path": "celt-ic%c9%99ri-s%c9%99h%c9%99r-filialinin-ugurlari",
        "img": "https://celt.az/wp-content/uploads/2016/11/set-1024x768.jpg"
    },
    {
        "path": "almaniya-t%c9%99hsil-iubh-business-school"
    },
    {
        "path": "dunyani-celt-ile-keshf-et",
        "img": "https://celt.az/wp-content/uploads/2017/04/dünyanı-celt-ilə-kəşf-edin.png"
    },
    {
        "path": "dil-oyrenmek-isteyi-motivasiya",
        "img": "https://celt.az/wp-content/uploads/2017/05/DIL-ÖYRƏNMƏK-İSTƏYİ-VƏ-MOTİVASİYA.png"
    },
    {
        "path": "macaristanda-t%c9%99hsil-szeged-universiteti",
        "img": "https://celt.az/wp-content/uploads/2017/05/IMG_1987-1024x683.jpg"
    },
    {
        "path": "ofisiantin-ugur-hekay%c9%99si",
        "img": "https://celt.az/wp-content/uploads/2017/05/CELTAZ.png"
    },
    {
        "path": "individual-ingilis-dili-kurslari",
        "img": "https://celt.az/wp-content/uploads/2017/05/İNDİVİDUAL-İNGİLİS-DİLİ-KURSU.png"
    },
    {
        "path": "vaxt-v%c9%99-ingilis-dili",
        "img": "https://celt.az/wp-content/uploads/2017/05/VAXTLIVƏ-İNGİLİS-DİLİ.png"
    },
    {
        "path": "bogazici-universitetind%c9%99-nec%c9%99-t%c9%99hsil-ala-bil%c9%99r%c9%99m",
        "img": "https://celt.az/wp-content/uploads/2017/06/bogazici-universitetinde-tehsil.png"
    },
    {
        "path": "sat-yoxsa-yos-imtahani-il%c9%99-turkiy%c9%99nin-guclu-universitetl%c9%99rind%c9%99-t%c9%99hsil-almaq-olar",
        "img": "https://celt.az/wp-content/uploads/2017/06/yos-imtahani-yoxsa-sat-imtahani.jpg"
    },
    {
        "path": "turkiy%c9%99d%c9%99-turk-dilind%c9%99-t%c9%99hsil-yaxsidir-yoxsa-ingilis-dilind%c9%99",
        "img": "https://celt.az/wp-content/uploads/2017/06/turkiyede-turk-dilinde-tehsil-yoxsa-ingilis-dilinde.png"
    },
    {
        "path": "duzgunmu-yoxsa-yalnish-avropada-tehsil",
        "img": "https://celt.az/wp-content/uploads/2017/06/duzgunmu-yoxsa-yalnis-avropada-tehsil.png"
    },
    {
        "path": "readingd%c9%99-mu%c9%99llim-n%c9%99-edir-ki-onsuz-da-ozum-oxuyub-cavab-verm%c9%99liy%c9%99m",
        "img": "https://celt.az/wp-content/uploads/2017/06/project-management-interview-questions.jpg"
    },
    {
        "path": "t%c9%99qaudlu-t%c9%99hsil-imkanlari",
        "img": "https://celt.az/wp-content/uploads/2020/03/Scholarship-Opportunities-01-768x403-1.jpg"
    },
    {
        "path": "odtu-orta-dogu-texnik-universitetind%c9%99-nec%c9%99-t%c9%99hsil-almaq-olar",
        "img": "https://celt.az/wp-content/uploads/2017/06/Orta-Dogu-Texnik-Universitetinde-tehsil.jpg"
    },
    {
        "path": "%d0%be%d0%b1%d1%83%d1%87%d0%b5%d0%bd%d0%b8%d0%b5-%d0%b2-%d0%b2%d0%b5%d0%bd%d0%b3%d1%80%d0%b8%d0%b8-%d1%83%d0%bd%d0%b8%d0%b2%d0%b5%d1%80%d1%81%d0%b8%d1%82%d0%b5%d1%82-%d1%81%d0%b5%d0%b3%d0%b5%d0%b4",
        "img": "https://celt.az/wp-content/uploads/2017/07/IMG_1987-1024x683.jpg"
    },
    {
        "path": "salam-qonsu",
        "img": "https://celt.az/wp-content/uploads/2017/07/celt-salam-qonsu.png"
    },
    {
        "path": "m%c9%99qs%c9%99din-n%c9%99dir-t%c9%99hsil-almaq-yoxsa",
        "img": "https://celt.az/wp-content/uploads/2017/07/İNDİVİDUAL-İNGİLİS-DİLİ-KURSU.png"
    },
    {
        "path": "work-and-travel-probleml%c9%99ri",
        "img": "https://celt.az/wp-content/uploads/2017/07/Work_and_Travel-1024x275.jpg"
    },
    {
        "path": "amerikada-orta-m%c9%99kt%c9%99b",
        "img": "https://celt.az/wp-content/uploads/2017/09/Amerikada-orta-məktəb.jpg"
    },
    {
        "path": "macaristanda-tibb-t%c9%99hsili-debrecen-universiteti",
        "img": "https://celt.az/wp-content/uploads/2017/11/macaristanda-tehsil.jpg"
    },
    {
        "path": "italiyada-muhendislik",
        "img": "https://celt.az/wp-content/uploads/2017/11/Politecnico_di_Milano_Bovisa_6.jpg"
    },
    {
        "path": "mannheim-universitetind%c9%99-t%c9%99hsil",
        "img": "https://celt.az/wp-content/uploads/2017/11/20171125_161605.jpg"
    },
    {
        "path": "abs-da-tibb-t%c9%99hsili",
        "img": "https://celt.az/wp-content/uploads/2017/11/gawande-mcallen-texas-update.jpg"
    },
    {
        "path": "amerikada-22-proqrami-n%c9%99dir",
        "img": "https://celt.az/wp-content/uploads/2017/12/americanhonors-photo.png"
    },
    {
        "path": "almaniyada-magistr-t%c9%99hsili-iqtisadiyyat-maliyy%c9%99-menecment-mba",
        "img": "https://celt.az/wp-content/uploads/2017/12/15815712043_429778e7f0_bnn.jpg"
    },
    {
        "path": "s%c9%99rqi-avropada-t%c9%99hsil-almaq",
        "img": "https://celt.az/wp-content/uploads/2017/12/pppp.jpg"
    },
    {
        "path": "almaniyada-magistr-t%c9%99hsili-muh%c9%99ndislik",
        "img": "https://celt.az/wp-content/uploads/2017/12/RE-ENGINEER_YOUR_FUTURE_IN_GERMANY.jpg"
    },
    {
        "path": "almaniyada-tehsil-sual-ve-cavablar",
        "img": "https://celt.az/wp-content/uploads/2011/10/Germany.jpg"
    },
    {
        "path": "avstriyada-tehsil-sual-cavablar",
        "img": "https://celt.az/wp-content/uploads/2017/12/avstriyada-tehsil.jpg"
    },
    {
        "path": "serqi-avropa-tehsil-sual-cavab",
        "img": "https://celt.az/wp-content/uploads/2017/12/Şərqi-Avropada-Təhsil-ilə-bağlı-sual-və-cavablar.jpg"
    },
    {
        "path": "ingiltere-tehsil-baklavr-magistr",
        "img": "https://celt.az/wp-content/uploads/2014/12/study-in-uk.png"
    },
    {
        "path": "almaniyada-magistr-tehsili-huquq",
        "img": "https://celt.az/wp-content/uploads/2017/12/Law-Feature-Image-1024x536.png"
    },
    {
        "path": "distant-t%c9%99hsil-il%c9%99-bagli-sual-v%c9%99-cavablar",
        "img": "https://celt.az/wp-content/uploads/2017/06/1030-School-four-high-school-students-laying-on-the-floor-studying-2500x1720-1024x705.jpg"
    },
    {
        "path": "turkiyede-tehsil-sual-ve-cavablar",
        "img": "https://celt.az/wp-content/uploads/2017/01/turkiyede-tehsil-1024x680.jpg"
    },
    {
        "path": "polsada-tehsil-sual-ve-cavab",
        "img": "https://celt.az/wp-content/uploads/2012/10/MTAyNHg3Njg11663521_11613245.jpg"
    },
    {
        "path": "macaristan-tehsil",
        "img": "https://celt.az/wp-content/uploads/2014/12/1024px-Parliament_Building_Budapest_Hungary.jpg"
    },
    {
        "path": "xaride-tehsil-sual-ve-cavab",
        "img": "https://celt.az/wp-content/uploads/2010/08/xaricde-magistr-tehsili.jpg"
    },
    {
        "path": "hollandiyada-tehsil",
        "img": "https://celt.az/wp-content/uploads/2015/10/sfeerimpressie-Hogeschool-Inholland-Campusplein_tcm9-164361-1024x576.jpg"
    },
    {
        "path": "italiyada-t%c9%99hsil-il%c9%99-bagli-sual-v%c9%99-cavablar",
        "img": "https://celt.az/wp-content/uploads/2017/06/duzgunmu-yoxsa-yalnis-avropada-tehsil.png"
    },
    {
        "path": "xaricd%c9%99-t%c9%99hsil-alark%c9%99n-isl%c9%99m%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2017/12/wallpaper.jpg"
    },
    {
        "path": "ispaniyada-t%c9%99hsil-il%c9%99-bagli-sual-v%c9%99-cavablar",
        "img": "https://celt.az/wp-content/uploads/2014/01/ispaniya1-1024x703.jpg"
    },
    {
        "path": "isvecd%c9%99-t%c9%99hsil-il%c9%99-bagli-sual-v%c9%99-cavablar",
        "img": "https://celt.az/wp-content/uploads/2017/12/İsveçdə-Təhsil-ilə-bağlı-Sual-və-Cavablar.jpg"
    },
    {
        "path": "isvecrede-tehsil",
        "img": "https://celt.az/wp-content/uploads/2017/12/İsveçrədə-Təhsil-ilə-bağlı-Sual-və-Cavablar.jpg"
    },
    {
        "path": "kanadada-tehsil",
        "img": "https://celt.az/wp-content/uploads/2013/10/canada1.jpg"
    },
    {
        "path": "xaricde-dil-kurslari-yay-mektebleri",
        "img": "https://celt.az/wp-content/uploads/2017/12/BA01F095-A2E3-456E-857D-9E81596E7622.jpeg"
    },
    {
        "path": "amerikada-t%c9%99hsil-university-south-florida",
        "img": "https://celt.az/wp-content/uploads/2017/12/INTO-USF-intro-USF-entrance-sign--1024x683.jpg"
    },
    {
        "path": "xaricde-ixtisasartirma-kurslari",
        "img": "https://celt.az/wp-content/uploads/2017/12/entrepreneurs_2-598x331.jpg"
    },
    {
        "path": "xaricd%c9%99-t%c9%99hsil-uzr%c9%99-t%c9%99limat"
    },
    {
        "path": "boyuk-britaniyada-qapali-mekteblerde-boarding-school-tehsil",
        "img": "https://celt.az/wp-content/uploads/2018/01/Glossary-of-Terms-Quicklink.jpg"
    },
    {
        "path": "italiyada-t%c9%99hsil-ala-bil%c9%99c%c9%99yiniz-universitetler",
        "img": "https://celt.az/wp-content/uploads/2018/01/italllyy-1024x683.png"
    },
    {
        "path": "avropada-tehsil-muddetinde-yasham-xercleri",
        "img": "https://celt.az/wp-content/uploads/2018/01/84321913_euro_holiday_thinkstock.jpg"
    },
    {
        "path": "xaricde-tehsil-muddetinde-qonaqlama",
        "img": "https://celt.az/wp-content/uploads/2018/01/58452df8390dd.jpg"
    },
    {
        "path": "birlesmis-kralliqda-foundation-tehsilinin-ustunlukleri",
        "img": "https://celt.az/wp-content/uploads/2018/01/London-Graduation-Shoot-Westminster-1.jpg"
    },
    {
        "path": "almaniyada-tehsil-muddetinde-ve-sonrasinda-is-imkanlari",
        "img": "https://celt.az/wp-content/uploads/2018/01/pathway-visas-review12-890x510.jpg"
    },
    {
        "path": "niy%c9%99-hollandiya",
        "img": "https://celt.az/wp-content/uploads/2018/02/amsterdam.jpg"
    },
    {
        "path": "cinde-tehsilin-ustunlukleri",
        "img": "https://celt.az/wp-content/uploads/2018/02/3_Country-Page-China.jpg"
    },
    {
        "path": "boyuk-britaniyada-a-level-proqrami-university-east-anglia",
        "img": "https://celt.az/wp-content/uploads/2018/02/INTO-Newton-A-level-Programme-students-science-laboratory.jpg"
    },
    {
        "path": "italiyada-foundation",
        "img": "https://celt.az/wp-content/uploads/2018/02/italy.jpg"
    },
    {
        "path": "kanadada-mekteb-tehsili-columbia-international-colleges",
        "img": "https://celt.az/wp-content/uploads/2018/03/Columbia-International-College.jpg"
    },
    {
        "path": "turkiy%c9%99d%c9%99-nec%c9%99-magistr-t%c9%99hsili-ala-bil%c9%99r%c9%99m",
        "img": "https://celt.az/wp-content/uploads/2018/03/0x0-img_7460.jpg"
    },
    {
        "path": "avropanin-m%c9%99shur-kitabxanalari",
        "img": "https://celt.az/wp-content/uploads/2018/03/Trinity-College-Library-Dublin.jpg"
    },
    {
        "path": "nece-xarici-dil-oyrenmek-lazimdir",
        "img": "https://celt.az/wp-content/uploads/2018/04/maxresdefault.jpg"
    },
    {
        "path": "corvinus-university-of-budapest",
        "img": "https://celt.az/wp-content/uploads/2018/05/IMG_0446.jpeg"
    },
    {
        "path": "celt-ic%c9%99ris%c9%99h%c9%99r-ofisinin-2017-2018-ci-ild%c9%99ki-ugurlari",
        "img": "https://celt.az/wp-content/uploads/2018/05/29354592_1659121164183793_2985804043540728365_o.jpg"
    },
    {
        "path": "budapest-business-school",
        "img": "https://celt.az/wp-content/uploads/2018/05/IMG_0289.jpeg"
    },
    {
        "path": "ingilt%c9%99r%c9%99d%c9%99-t%c9%99hsil-2",
        "img": "https://celt.az/wp-content/uploads/2018/07/ingillllll-1024x683.jpg"
    },
    {
        "path": "dil-oyr%c9%99n%c9%99n-zaman-listening-h%c9%99qiq%c9%99t%c9%99n-faydalidirmi",
        "img": "https://celt.az/wp-content/uploads/2018/07/are-you-a-good-listener-1024x681.jpg"
    },
    {
        "path": "ielts-v%c9%99-toefl-imtahanlarinin-f%c9%99rql%c9%99ri",
        "img": "https://celt.az/wp-content/uploads/2018/07/IELTS-VS-TOEFL-1024x223.jpg"
    },
    {
        "path": "ingilis-dilini-t%c9%99kmill%c9%99sdirm%c9%99k-ucun-etm%c9%99li-oldugunuz-13-addim",
        "img": "https://celt.az/wp-content/uploads/2018/08/34481536_909012585926957_5426306199102423040_o-1024x680.jpg"
    },
    {
        "path": "celt-colleges-kurs-qiymetleri",
        "img": "https://celt.az/wp-content/uploads/2021/05/pricing-600x400-c-center.png"
    },
    {
        "path": "xaricd%c9%99-harda-t%c9%99hsil-almaliyam-ingilt%c9%99r%c9%99-yoxsa-italiya",
        "img": "https://celt.az/wp-content/uploads/2018/08/eur-1024x328.png"
    },
    {
        "path": "sat-yoxsa-yos-f%c9%99rq-v%c9%99-ustunlukl%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2018/08/mth-1024x722.jpg"
    },
    {
        "path": "niy%c9%99-b%c9%99zi-olk%c9%99l%c9%99rin-v%c9%99-universitetl%c9%99rin-t%c9%99hsil-haqqi-dig%c9%99rl%c9%99rind%c9%99n-daha-bahalidir",
        "img": "https://celt.az/wp-content/uploads/2018/08/aaaa-1024x412.jpg"
    },
    {
        "path": "ev-yoxsa-t%c9%99l%c9%99b%c9%99-yataqxanasi",
        "img": "https://celt.az/wp-content/uploads/2018/08/conclusion-1024x273.png"
    },
    {
        "path": "ingilis-dilind%c9%99-soz-bazanizi-nec%c9%99-inkisaf-etdir%c9%99-bil%c9%99rsiz",
        "img": "https://celt.az/wp-content/uploads/2018/08/vocabulary-1024x576.png"
    },
    {
        "path": "niy%c9%99-uzun-ill%c9%99rdir-ingilis-dilini-oyr%c9%99n%c9%99-bilmir%c9%99m",
        "img": "https://celt.az/wp-content/uploads/2018/08/stress-1024x576.jpg"
    },
    {
        "path": "sat-2-imtahani-n%c9%99dir",
        "img": "https://celt.az/wp-content/uploads/2018/08/AdobeStock_89233378-760x555.jpeg"
    },
    {
        "path": "%d1%87%d1%82%d0%be-%d0%bb%d0%b5%d0%b3%d1%87%d0%b5-%d1%81%d0%b4%d0%b0%d1%82%d1%8c-toefl-%d0%b8%d0%bb%d0%b8-ielts",
        "img": "https://celt.az/wp-content/uploads/2018/09/toi2-862.jpg"
    },
    {
        "path": "what-do-we-offer",
        "img": "https://celt.az/wp-content/uploads/2018/09/F805D78C-4D80-4058-A032-3350D0EDA2E9-1024x768.jpeg"
    },
    {
        "path": "amerikada-t%c9%99hsil",
        "img": "https://celt.az/wp-content/uploads/2018/09/14446058_1414016641960221_7419924403005853681_nlk.jpg"
    },
    {
        "path": "erk%c9%99n-t%c9%99hsil-niy%c9%99-lazimdir"
    },
    {
        "path": "celt-colleges-%d0%bd%d0%be%d0%b2%d1%8b%d0%b9-%d0%bf%d0%be%d0%b4%d1%85%d0%be%d0%b4-%d0%ba-%d0%b8%d0%b7%d1%83%d1%87%d0%b5%d0%bd%d0%b8%d1%8e-%d0%b0%d0%bd%d0%b3%d0%bb%d0%b8%d0%b9%d1%81%d0%ba%d0%be%d0%b3",
        "img": "https://celt.az/wp-content/uploads/2018/09/14344097_1405380946157124_7570140751688470382_n.jpg"
    },
    {
        "path": "usaginizin-yaradiciliq-c%c9%99hdl%c9%99rini-hec-vaxt-t%c9%99nqid-etm%c9%99yin",
        "img": "https://celt.az/wp-content/uploads/2018/09/deti-risuyut-01-1024x708.jpg"
    },
    {
        "path": "celt-colleges-elml%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2018/09/13174211_1301722449856308_723048997494729233_nj.jpg"
    },
    {
        "path": "celtnarimanov"
    },
    {
        "path": "bremen-universiteti-neurosciences-uzr%c9%99-magistr-t%c9%99hsili",
        "img": "https://celt.az/wp-content/uploads/2018/09/bremen-universiteti-magistr.jpg"
    },
    {
        "path": "clausthal-universiteti-neft-muh%c9%99ndisliyi-uzr%c9%99-magistr-t%c9%99hsili",
        "img": "https://celt.az/wp-content/uploads/2018/03/masters-degree-usa.jpg"
    },
    {
        "path": "darmstadt-texniki-universiteti-elektrik-enerji-muh%c9%99ndisliyi-uzr%c9%99-magistr-t%c9%99hsili",
        "img": "https://celt.az/wp-content/uploads/2017/06/1030-School-four-high-school-students-laying-on-the-floor-studying-2500x1720-1024x705.jpg"
    },
    {
        "path": "ingilis-dili-2",
        "img": "https://celt.az/wp-content/uploads/2018/09/with-language-english-slogan.jpg"
    },
    {
        "path": "xaricd%c9%99-t%c9%99hsil-v%c9%99-t%c9%99l%c9%99b%c9%99l%c9%99rin-uzl%c9%99sdiyi-c%c9%99tinlikl%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2018/09/study-abroad.png"
    },
    {
        "path": "university-of-flensburg-magistr-uzr%c9%99-t%c9%99hsil",
        "img": "https://celt.az/wp-content/uploads/2018/06/uk-baki-image.jpg"
    },
    {
        "path": "frankfurt-university-of-applied-sciences-magistr-t%c9%99hsili",
        "img": "https://celt.az/wp-content/uploads/2018/03/masters-degree-usa.jpg"
    },
    {
        "path": "goethe-university-frankfurt-magistr-t%c9%99hsili",
        "img": "https://celt.az/wp-content/uploads/2017/12/entrepreneurs_2-598x331.jpg"
    },
    {
        "path": "university-of-giessen-huquq-uzr%c9%99-magistr-t%c9%99hsili",
        "img": "https://celt.az/wp-content/uploads/2017/12/BA01F095-A2E3-456E-857D-9E81596E7622.jpeg"
    },
    {
        "path": "university-of-gottingen-molekulyar-tibb-uzr%c9%99-magistr-t%c9%99hsili",
        "img": "https://celt.az/wp-content/uploads/2018/02/photodune-5079010-female-doctor-in-front-of-medical-group-m.jpg"
    },
    {
        "path": "bakalavr-magistr",
        "img": "https://celt.az/wp-content/uploads/2018/10/hd-wallpapers-dreams-pinterest-rhpinterestcomau-how-studying-abroad-can-change-your-liferhtheodysseyonlinecom-how-Travelling-Around-The-World-Tumblr-studying-abroad-can-change-your-1024x595.jpg"
    },
    {
        "path": "polsada-t%c9%99hsil-2",
        "img": "https://celt.az/wp-content/uploads/2018/11/poland.jpg"
    },
    {
        "path": "cinde-tehsil-imkanlari",
        "img": "https://celt.az/wp-content/uploads/2018/11/1_Country-Page-China.jpg"
    },
    {
        "path": "niy%c9%99-italiyada-t%c9%99hsil-almaliyam",
        "img": "https://celt.az/wp-content/uploads/2018/11/1_Country-Page-Italy.jpg"
    },
    {
        "path": "hansi-t%c9%99hsil-agentl%c9%99rin%c9%99-guv%c9%99nm%c9%99k-olar",
        "img": "https://celt.az/wp-content/uploads/2018/11/contracccccttt-1024x682.jpg"
    },
    {
        "path": "tqdk-bali-xaricde-tehsil-almaq",
        "img": "https://celt.az/wp-content/uploads/2018/05/Telebe.jpg"
    },
    {
        "path": "koc-universitetinin-numay%c9%99nd%c9%99si-il%c9%99-gorus",
        "img": "https://celt.az/wp-content/uploads/2018/11/IMG_20181128_162810-777x1024.jpg"
    },
    {
        "path": "hollandiyada-tehsil-groningen-universiteti",
        "img": "https://celt.az/wp-content/uploads/2018/11/Screen-Shot-2018-03-26-at-14.17.17-860x375.png"
    },
    {
        "path": "niy%c9%99-almaniyada-t%c9%99hsil-almalisiniz",
        "img": "https://celt.az/wp-content/uploads/2018/11/study-in-germany-1024x827.png"
    },
    {
        "path": "macaristanda-tehsil-almagin-ustunlukleri-2",
        "img": "https://celt.az/wp-content/uploads/2018/12/1111111111-1024x565.jpg"
    },
    {
        "path": "birl%c9%99smis-kralliqda-hazirliq-foundation-proqrami-n%c9%99dir",
        "img": "https://celt.az/wp-content/uploads/2018/12/foundation-study-uk-768x384.jpg"
    },
    {
        "path": "amerika-universitetlerinde-teqaud",
        "img": "https://celt.az/wp-content/uploads/2018/12/amerikan-universitetlərində-təqaüd-imkanları.jpeg"
    },
    {
        "path": "top-universitetlerde-tehsil",
        "img": "https://celt.az/wp-content/uploads/2018/12/IJ-jump-for-joy.png"
    },
    {
        "path": "uygun-budce-ile-avropa-tehsil",
        "img": "https://celt.az/wp-content/uploads/2018/12/study-in-europe-1-1024x508-1024x508.jpg"
    },
    {
        "path": "hansi-sah%c9%99ni-secm%c9%99liy%c9%99m",
        "img": "https://celt.az/wp-content/uploads/2019/01/445-1024x576.jpg"
    },
    {
        "path": "avstraliyada-t%c9%99hsil",
        "img": "https://celt.az/wp-content/uploads/2019/01/Study-Destination-in-Australia-inside.png"
    },
    {
        "path": "kicik-yaslilara-ingilis-dilinin-oyr%c9%99dilm%c9%99si%ef%bb%bf"
    },
    {
        "path": "almaniyada-real-tehsil-imkanlari",
        "img": "https://celt.az/wp-content/uploads/2019/02/almaniyada-Təhsil-1024x512.jpeg"
    },
    {
        "path": "niy%c9%99-ada-universiteti",
        "img": "https://celt.az/wp-content/uploads/2019/02/ada_university-1024x394.jpg"
    },
    {
        "path": "almaniyada-bakalavr-tehsili",
        "img": "https://celt.az/wp-content/uploads/2019/02/assets_media_product_cover-banner_main_20160927_82f45cd854d741aeaa2c22530c554898_56-ec-english-1.png"
    },
    {
        "path": "niderland-v%c9%99-ya-hollandiya-haqqinda-qisa-m%c9%99lumat",
        "img": "https://celt.az/wp-content/uploads/2019/02/91054d3142b1a20d66044a77bfcb937d.jpg"
    },
    {
        "path": "usaginizin-intelektual-dunya-goruslu-boyuyub-basa-catmasini-ist%c9%99yirsinizs%c9%99-biz%c9%99-etibar-edin",
        "img": "https://celt.az/wp-content/uploads/2019/02/deti-s-noutbukom-2256itland27012016.jpg"
    },
    {
        "path": "amerikada-dil-m%c9%99kt%c9%99bl%c9%99ri",
        "img": "https://celt.az/wp-content/uploads/2019/02/7942l.jpg"
    },
    {
        "path": "iubh-university-of-applied-sciences",
        "img": "https://celt.az/wp-content/uploads/2019/02/1525430589_IUBH.jpg"
    },
    {
        "path": "xaricd%c9%99-t%c9%99hsil-d%c9%99bdir-yoxsa-ehtiyac",
        "img": "https://celt.az/wp-content/uploads/2019/03/baku-naberezhnaya-1024x541.jpg"
    },
    {
        "path": "xarici-dil-oyr%c9%99nm%c9%99y%c9%99-n%c9%99-zaman-baslamaq-lazimdir",
        "img": "https://celt.az/wp-content/uploads/2019/03/learning_a_language.png"
    },
    {
        "path": "turkiy%c9%99nin-reytinq-siyahisina-liderlik-ed%c9%99n-7-universiteti",
        "img": "https://celt.az/wp-content/uploads/2019/03/tut-1024x576.jpg"
    },
    {
        "path": "niy%c9%99-orta-m%c9%99kt%c9%99bi-bitirdikd%c9%99-xarici-dil-biliyi-arzuolunan-s%c9%99viyy%c9%99d%c9%99-olmur",
        "img": "https://celt.az/wp-content/uploads/2019/03/blog-picture-23-mart.jpg"
    },
    {
        "path": "bu-yayiniz-f%c9%99rqli-olsun",
        "img": "https://celt.az/wp-content/uploads/2019/03/DSC_1405_United-Kingdom-Crown-Jewels.jpg"
    },
    {
        "path": "celt-colleges-elmler"
    },
    {
        "path": "canik-5-manat-da-ver-usaqligini-yadina-salim",
        "img": "https://celt.az/wp-content/uploads/2019/04/blog-picture-5-aprel.jpg"
    },
    {
        "path": "t%c9%99hsild%c9%99-m%c9%99saf%c9%99-n%c9%99-q%c9%99d%c9%99r-rol-oynayir",
        "img": "https://celt.az/wp-content/uploads/2019/04/blog-picture-13-aprel.jpg"
    },
    {
        "path": "%d0%bc%d1%83%d0%b7%d1%8b%d0%ba%d0%b0-%d0%bd%d0%b5-%d1%82%d0%be%d0%bb%d1%8c%d0%ba%d0%be-%d0%b4%d0%bb%d1%8f-%d0%b4%d1%83%d1%88%d0%b8-%d0%bd%d0%be-%d0%b8-%d0%b4%d0%bb%d1%8f-%d0%b8%d0%b7%d1%83%d1%87",
        "img": "https://celt.az/wp-content/uploads/2019/04/jfpd.jpg"
    },
    {
        "path": "kecmisd%c9%99n-bu-gun%c9%99-t%c9%99hsil",
        "img": "https://celt.az/wp-content/uploads/2019/04/blog-pic-21-april.jpg"
    },
    {
        "path": "%d0%bd%d0%b5%d0%bc%d0%bd%d0%be%d0%b3%d0%be-%d0%be-%d0%bd%d0%b0%d1%81-%d1%81elt-kids",
        "img": "https://celt.az/wp-content/uploads/2019/04/Фото-06.02.2019-11-18-01-768x1024.jpg"
    },
    {
        "path": "biznes-idar%c9%99ciliyi-magistraturasi-mba-v%c9%99-biznes-sah%c9%99sind%c9%99-magistratura-elmi-d%c9%99r%c9%99c%c9%99sinin-msc-in-business-f%c9%99rql%c9%99ri-n%c9%99l%c9%99rdir",
        "img": "https://celt.az/wp-content/uploads/2019/04/blog-picture-29-apr.jpg"
    },
    {
        "path": "t%c9%99l%c9%99b%c9%99l%c9%99ri-nec%c9%99-motivasiya-ed%c9%99-bil%c9%99rsiniz",
        "img": "https://celt.az/wp-content/uploads/2019/05/Школа.jpg"
    },
    {
        "path": "t%c9%99dris-prosesind%c9%99-oyr%c9%99n%c9%99nl%c9%99rin-diqq%c9%99tini-nec%c9%99-c%c9%99lb-etm%c9%99k-olar",
        "img": "https://celt.az/wp-content/uploads/2019/05/blog-7-may.jpg"
    },
    {
        "path": "sosial-s%c9%99b%c9%99k%c9%99l%c9%99rd%c9%99n-sinif-muhitind%c9%99-istifad%c9%99",
        "img": "https://celt.az/wp-content/uploads/2019/05/blog-pic-15-may.jpg"
    },
    {
        "path": "t%c9%99hsil-alacaginiz-xarici-olk%c9%99y%c9%99-adaptasiya-ucun-bir-nec%c9%99-kicik-qayda",
        "img": "https://celt.az/wp-content/uploads/2019/05/23-may.jpg"
    },
    {
        "path": "komputer-elml%c9%99ri-v%c9%99-komputer-muh%c9%99ndisliyi",
        "img": "https://celt.az/wp-content/uploads/2019/05/31-may-1024x323.jpg"
    },
    {
        "path": "britaniya-universitetl%c9%99rin%c9%99-ged%c9%99n-yol",
        "img": "https://celt.az/wp-content/uploads/2019/06/british-universities-1024x504.jpg"
    },
    {
        "path": "a-level-v%c9%99-hazirliq-d%c9%99r%c9%99c%c9%99si-foundation-degree",
        "img": "https://celt.az/wp-content/uploads/2019/06/Foundation-degree.jpg"
    },
    {
        "path": "%d0%bf%d1%80%d0%b0%d0%b7%d0%b4%d0%bd%d0%b8%d0%ba%d0%b8-%d0%bd%d0%b5%d0%be%d0%b1%d1%85%d0%be%d0%b4%d0%b8%d0%bc%d1%8b-%d0%b4%d0%bb%d1%8f-%d0%bd%d1%80%d0%b0%d0%b2%d1%81%d1%82%d0%b2%d0%b5%d0%bd%d0%bd",
        "img": "https://celt.az/wp-content/uploads/2019/06/maxresdefault-1024x683.jpg"
    },
    {
        "path": "50516-2",
        "img": "https://celt.az/wp-content/uploads/2019/07/IMG_3272-1024x664.jpg"
    },
    {
        "path": "memarliq-v%c9%99-memari-muh%c9%99ndislik",
        "img": "https://celt.az/wp-content/uploads/2019/09/architecture.jpg"
    },
    {
        "path": "m%c9%99n-almaniyada-goettingen-bielefeld-kimi-universitetl%c9%99r%c9%99-q%c9%99bul-oldum",
        "img": "https://celt.az/wp-content/uploads/2017/11/Waitlist-News-feature.jpg"
    },
    {
        "path": "m%c9%99suliyy%c9%99tliliyi-il%c9%99-butun-celt-kollektivinin-r%c9%99gb%c9%99tini-qazanmis-n%c9%99rgiz-m%c9%99mm%c9%99dli-yazir",
        "img": "https://celt.az/wp-content/uploads/2019/09/4-819x1024.jpg"
    },
    {
        "path": "bir-gun-kursa-g%c9%99l%c9%99nd%c9%99-baxdim-ki-q%c9%99bul-m%c9%99rk%c9%99zind%c9%99-bizim-aliko-oturub",
        "img": "https://celt.az/wp-content/uploads/2019/09/IMG_0649-1024x683.jpg"
    },
    {
        "path": "celt-colleges-artiq-nizamid%c9%99",
        "img": "https://celt.az/wp-content/uploads/2019/09/IMG_4789-1024x768.jpeg"
    },
    {
        "path": "viza-il%c9%99-bagli-suallar",
        "img": "https://celt.az/wp-content/uploads/2019/09/Student-Visa-in-Mexico.jpg"
    },
    {
        "path": "t%c9%99s%c9%99kkurl%c9%99r-m%c9%99nim-celt-n%c9%99rimanov-ail%c9%99m",
        "img": "https://celt.az/wp-content/uploads/2019/09/7-819x1024.jpg"
    },
    {
        "path": "boyuk-britaniya-t%c9%99l%c9%99b%c9%99-vizasi-tier-4",
        "img": "https://celt.az/wp-content/uploads/2019/09/uk-visas-and-imm-1024x610.jpg"
    },
    {
        "path": "celt-colleges-bakida-%c9%99n-boyuk-ingilis-dili-kursu-v%c9%99-xaricd%c9%99-t%c9%99hsil-m%c9%99rk%c9%99zidi",
        "img": "https://celt.az/wp-content/uploads/2019/09/IMG_6601-1024x683.jpg"
    },
    {
        "path": "ingilis-dili-bilm%c9%99yin-ustunlukl%c9%99ri",
        "img": "https://celt.az/wp-content/uploads/2019/09/blog-19-sept.jpg"
    },
    {
        "path": "ankarada-t%c9%99l%c9%99b%c9%99-olmaq",
        "img": "https://celt.az/wp-content/uploads/2019/09/ankara-25-1024x665.jpg"
    },
    {
        "path": "ielts-hazirligina-n%c9%99-vaxtdan-baslamaq-lazimdir",
        "img": "https://celt.az/wp-content/uploads/2019/09/ielts-registration-paper-based_0-1024x576.jpg"
    },
    {
        "path": "toefl-v%c9%99-ielts-eynilik-v%c9%99-f%c9%99rqlilikl%c9%99ri",
        "img": "https://celt.az/wp-content/uploads/2019/09/21-sept-bloq.png"
    },
    {
        "path": "hacettepe-universiteti",
        "img": "https://celt.az/wp-content/uploads/2019/09/hacettepe-university.jpg"
    },
    {
        "path": "starter-d%c9%99n-ingilis-dili-kurslari",
        "img": "https://celt.az/wp-content/uploads/2019/09/370116-PBAKQR-274-1024x954.jpg"
    },
    {
        "path": "xarici-dilin-t%c9%99drisi-zamani-ana-dilinin-%c9%99h%c9%99miyy%c9%99ti-var-mi",
        "img": "https://celt.az/wp-content/uploads/2019/09/24-sept-bloq-1024x1024.jpg"
    },
    {
        "path": "german-blocked-account-almaniya-bankinda-blok-hesab",
        "img": "https://celt.az/wp-content/uploads/2019/09/deutsche-1400x788-1024x576.jpg"
    },
    {
        "path": "what-is-ielts",
        "img": "https://celt.az/wp-content/uploads/2019/09/IMG_0651-1024x683.jpg"
    },
    {
        "path": "ingilis-dilini-oyr%c9%99n%c9%99-bilmir%c9%99m",
        "img": "https://celt.az/wp-content/uploads/2019/09/cla.jpg"
    },
    {
        "path": "indi-artiq-bizim-sevimli-t%c9%99l%c9%99b%c9%99miz-h%c9%99m-d%c9%99-jagiellonian-universitetinin-t%c9%99l%c9%99b%c9%99sidir",
        "img": "https://celt.az/wp-content/uploads/2019/09/Rehman-1-768x1024.jpg"
    },
    {
        "path": "bu-m%c9%99nim-ucun-donum-noqt%c9%99si-oldu",
        "img": "https://celt.az/wp-content/uploads/2019/09/Famin-riga-photo-1024x563.jpg"
    },
    {
        "path": "esse-nec%c9%99-yazilmalidir",
        "img": "https://celt.az/wp-content/uploads/2019/09/27-sept-blog-2.jpg"
    },
    {
        "path": "elte-eotvos-lorand-universiteti",
        "img": "https://celt.az/wp-content/uploads/2019/09/elte-pics-1024x331.jpg"
    },
    {
        "path": "xaricd%c9%99-t%c9%99hsil-zamani-ehtiyaciniz-olan-oz%c9%99llikl%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2019/09/WhatsApp-Image-2019-09-19-at-18.52.11-1-827x1024.jpeg"
    },
    {
        "path": "g%c9%99l%c9%99c%c9%99yiniz%c9%99-yatirim-edin-ingilis-dilin-biziml%c9%99-oyr%c9%99nin",
        "img": "https://celt.az/wp-content/uploads/2019/10/97e53685-f17d-4f70-b8d7-6b4b2963ef43-1024x776.jpg"
    },
    {
        "path": "t%c9%99l%c9%99b%c9%99m%c9%99zin-celt-collegesl%c9%99-tanisligi",
        "img": "https://celt.az/wp-content/uploads/2019/10/WhatsApp-Image-2019-09-25-at-16.40.41-1.jpeg"
    },
    {
        "path": "xaricd%c9%99-t%c9%99hsil-furs%c9%99tinizi-%c9%99ld%c9%99n-verm%c9%99m%c9%99k-ucun-bu-isin-mut%c9%99x%c9%99ssisi-il%c9%99-gorusun",
        "img": "https://celt.az/wp-content/uploads/2019/10/42a78ac0d044b05473d807dfb2f9f2a0.jpg"
    },
    {
        "path": "ingilis-dili-kursu-sec%c9%99rk%c9%99n-n%c9%99l%c9%99r%c9%99-diqq%c9%99t-etm%c9%99k-lazimdir",
        "img": "https://celt.az/wp-content/uploads/2019/10/celt-colleges-narimanov-pics.png"
    },
    {
        "path": "siz-qerar-verinbiz-d%c9%99st%c9%99k-olaq",
        "img": "https://celt.az/wp-content/uploads/2019/10/cbb9ca32-b097-4d5e-9e7d-e4d903a7fffc-1024x768.jpg"
    },
    {
        "path": "xaricd%c9%99-t%c9%99l%c9%99b%c9%99l%c9%99rin-qala-bil%c9%99c%c9%99yi-yer-alternativl%c9%99ri",
        "img": "https://celt.az/wp-content/uploads/2019/10/Screen-Shot-2017-12-05-at-15.40.31-860x375.jpg"
    },
    {
        "path": "mexanika-muh%c9%99ndisliyi-v%c9%99-robototexnika-muh%c9%99ndisliyi-ixtisaslari",
        "img": "https://celt.az/wp-content/uploads/2019/10/bloq-13-okt.jpg"
    },
    {
        "path": "sat-ielts-toefl-sual-cavab",
        "img": "https://celt.az/wp-content/uploads/2019/10/Screen-Shot-2019-10-14-at-19.20.48-1024x861.png"
    },
    {
        "path": "niye-mehz-ingilis-dili",
        "img": "https://celt.az/wp-content/uploads/2019/10/2.jpg"
    },
    {
        "path": "avropada-tehsil",
        "img": "https://celt.az/wp-content/uploads/2019/10/Student-Recruitment-Services-610x344.png"
    },
    {
        "path": "sat-imtahani-haqqinda-h%c9%99r-sey",
        "img": "https://celt.az/wp-content/uploads/2019/10/sat-pic-1024x410.png"
    },
    {
        "path": "tukiy%c9%99d%c9%99-hansi-ixtisasda-t%c9%99hsil-ala-bil%c9%99r%c9%99m",
        "img": "https://celt.az/wp-content/uploads/2019/10/10628582_900866869941870_2854574391925869242_n.jpg"
    },
    {
        "path": "hec-d%c9%99-f%c9%99rqli-dil-kimi-dusunm%c9%99yin",
        "img": "https://celt.az/wp-content/uploads/2019/10/4_23_2020-1024x1024.jpg"
    },
    {
        "path": "ana-dilimizi-v%c9%99-xarici-dill%c9%99ri-nec%c9%99-oyr%c9%99nirik",
        "img": "https://celt.az/wp-content/uploads/2019/10/16770-1024x683.jpg"
    },
    {
        "path": "niy%c9%99-turkiy%c9%99d%c9%99-t%c9%99hsil-2",
        "img": "https://celt.az/wp-content/uploads/2019/10/Turk-bayragi-olculer.jpg"
    },
    {
        "path": "almaniyada-master-t%c9%99hsili",
        "img": "https://celt.az/wp-content/uploads/2019/10/2156759-1.jpg"
    },
    {
        "path": "amerikada-magistratura-oxumaq-ucun-n%c9%99-etm%c9%99k-lazimdir",
        "img": "https://celt.az/wp-content/uploads/2019/10/Amerika-oploshala-i-proschitalas-v-svoih-proiskah-1.jpg"
    },
    {
        "path": "fransada-t%c9%99hsil",
        "img": "https://celt.az/wp-content/uploads/2019/10/eiffel-for-bloq.jpg"
    },
    {
        "path": "ingilis-dili-oyr%c9%99nm%c9%99k-is-h%c9%99yatinda-siz%c9%99-kom%c9%99k-olur-v%c9%99-karyeranizda-qabaga-getm%c9%99yiniz-t%c9%99min-edir",
        "img": "https://celt.az/wp-content/uploads/2019/11/english-word-education-banner_66675-157.jpg"
    },
    {
        "path": "maliyy%c9%99-v%c9%99-iqtisadiyyat",
        "img": "https://celt.az/wp-content/uploads/2019/11/f-1024x442.jpg"
    },
    {
        "path": "sat-n%c9%99tic%c9%99l%c9%99ri",
        "img": "https://celt.az/wp-content/uploads/2019/11/OGN3O00-1024x683.jpg"
    },
    {
        "path": "foundation-beyn%c9%99lxalq-hazirliq-ili-t%c9%99hsili",
        "img": "https://celt.az/wp-content/uploads/2019/11/cartoon-boy-and-girl-study-together-vector-2480068-1.jpg"
    },
    {
        "path": "varsavada-oxu",
        "img": "https://celt.az/wp-content/uploads/2019/11/wrs-6-1024x698.jpg"
    },
    {
        "path": "macaristan",
        "img": "https://celt.az/wp-content/uploads/2019/11/SPAice-Up-Your-Life-With-Hungarian-Culture-Debrecen-1024x644.jpg"
    },
    {
        "path": "britaniyada-btec-proqrami-n%c9%99dir-a-level-d%c9%99n-f%c9%99rqi",
        "img": "https://celt.az/wp-content/uploads/2019/11/BTEC.jpg"
    },
    {
        "path": "almaniyada-tibb-t%c9%99hsili",
        "img": "https://celt.az/wp-content/uploads/2019/11/main-qimg-687cef7230b95cfe496f641d14ef124f-c.jpg"
    },
    {
        "path": "c%c9%99nubi-koreyada-oxu",
        "img": "https://celt.az/wp-content/uploads/2019/11/seoul-1024x366.jpg"
    },
    {
        "path": "komputer-uzr%c9%99-muh%c9%99ndis",
        "img": "https://celt.az/wp-content/uploads/2019/11/video-1510-38091-2.jpg"
    },
    {
        "path": "__trashed-2",
        "img": "https://celt.az/wp-content/uploads/2019/11/Italy-1024x645.jpg"
    },
    {
        "path": "cexiyada-t%c9%99l%c9%99b%c9%99-olmaq",
        "img": "https://celt.az/wp-content/uploads/2019/11/Prague-Czech-Republic-1030x580-1024x577.jpg"
    },
    {
        "path": 51529,
        "img": "https://celt.az/wp-content/uploads/2019/11/faundation.png"
    },
    {
        "path": "xaricd%c9%99-t%c9%99hsil-almaq-ist%c9%99yir%c9%99m-n%c9%99-etm%c9%99liy%c9%99m",
        "img": "https://celt.az/wp-content/uploads/2019/11/1015938-1tjs080onlinegmt10-1024x576.jpg"
    },
    {
        "path": "istanbul-texnik-universiteti",
        "img": "https://celt.az/wp-content/uploads/2019/11/ITU-1024x625.jpg"
    },
    {
        "path": "macaristana-gets%c9%99niz-burada-qalin",
        "img": "https://celt.az/wp-content/uploads/2019/11/miskolc-sih.jpg"
    },
    {
        "path": "polsada-t%c9%99hsil-3",
        "img": "https://celt.az/wp-content/uploads/2019/12/polsha..jpg"
    },
    {
        "path": "biznes-sah%c9%99sinin-liderl%c9%99ri",
        "img": "https://celt.az/wp-content/uploads/2019/12/tumblr_78712d5ba6e71948e9e6c940b16b523b_18bf215e_640.jpg"
    },
    {
        "path": "yildiz-texnik-univerisiteti",
        "img": "https://celt.az/wp-content/uploads/2019/12/ytu.jpg"
    },
    {
        "path": "dunya-reytinq-siyahisindaki-%c9%99n-q%c9%99dim-universitetl%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2019/12/university-of-cambridge_0.jpg"
    },
    {
        "path": "polsa-universitetl%c9%99rin%c9%99-q%c9%99bul-s%c9%99rtl%c9%99ri-v%c9%99-t%c9%99hsil-haqqi",
        "img": "https://celt.az/wp-content/uploads/2019/12/KrakowWawel-540.jpg"
    },
    {
        "path": "bu-olk%c9%99d%c9%99-4-dili-eyni-anda-oyr%c9%99nm%c9%99k-olur",
        "img": "https://celt.az/wp-content/uploads/2019/12/Couverture-article-de-blog-Gael.jpg"
    },
    {
        "path": "harvard-qipqirmizi-yale-buldoqlar-brown-ayilar-pennsylvania-qara-albatroslar",
        "img": "https://celt.az/wp-content/uploads/2020/01/Ivy-League-Universities.jpg"
    },
    {
        "path": "tehsil-almaq-ucun-daha-gecdir",
        "img": "https://celt.az/wp-content/uploads/2019/11/faundation.png"
    },
    {
        "path": "niye-data-science-sahesi",
        "img": "https://celt.az/wp-content/uploads/2020/01/WhatsApp-Image-2020-01-28-at-16.36.18-1-822x1024.jpeg"
    },
    {
        "path": "muellim-olmaq-ucun-ne-lazimdir",
        "img": "https://celt.az/wp-content/uploads/2019/10/72375733_408693456513000_6965640499671597056_n.jpg"
    },
    {
        "path": "telefe-transferi-kocurme",
        "img": "https://celt.az/wp-content/uploads/2019/11/SPAice-Up-Your-Life-With-Hungarian-Culture-Debrecen-1024x644.jpg"
    },
    {
        "path": "prestijli-ixtisaslar",
        "img": "https://celt.az/wp-content/uploads/2019/10/1171809-wynajem-mieszkania-studenckiego-jest-bardziej-oplacalny-niz-samodzielnej.jpg"
    },
    {
        "path": "azerbaycan-fransiz-universiteti",
        "img": "https://celt.az/wp-content/uploads/2017/11/Politecnico_di_Milano_Bovisa_6.jpg"
    },
    {
        "path": "xarici-diplomlarin-azerbaycanda-taninmasi",
        "img": "https://celt.az/wp-content/uploads/2017/06/cel2.jpg"
    },
    {
        "path": "her-ilin-256-ci-gunu",
        "img": "https://celt.az/wp-content/uploads/2020/01/Footer-1024x412.png"
    },
    {
        "path": "pese-tehsiliyeni-ixtisaslar",
        "img": "https://celt.az/wp-content/uploads/2019/12/CTA-contact-us-new.png"
    },
    {
        "path": "k%c9%99nd-t%c9%99s%c9%99rrufati-macaristanda-t%c9%99qaud-proqrami",
        "img": "https://celt.az/wp-content/uploads/2014/12/1024px-Parliament_Building_Budapest_Hungary.jpg"
    },
    {
        "path": "robotun-muellimi-kimdir",
        "img": "https://celt.az/wp-content/uploads/2019/10/bloq-13-okt.jpg"
    },
    {
        "path": "itirdiyimiz-%c9%99n-boyuk-bacariq",
        "img": "https://celt.az/wp-content/uploads/2019/10/menulis-1024x684.jpg"
    },
    {
        "path": "latviyada-t%c9%99hsil-t%c9%99qaud-proqrami",
        "img": "https://celt.az/wp-content/uploads/2014/05/Riga-Latvia-1024x640.jpg"
    },
    {
        "path": "koreya-respublikasinda-t%c9%99qaud-proqrami",
        "img": "https://celt.az/wp-content/uploads/2020/02/study-in-korea-with-out-ielts-500x500-1.jpg"
    },
    {
        "path": "proqramlasdirma-v%c9%99-komputer-muh%c9%99ndisliyi-uzr%c9%99-xaricd%c9%99-t%c9%99hsil-almaq",
        "img": "https://celt.az/wp-content/uploads/2014/05/computer-science.jpg"
    },
    {
        "path": "yukselis-musabiqesi",
        "img": "https://celt.az/wp-content/uploads/2020/02/arm-wrestling-indian-wrestling-competition-567950-1024x682.jpg"
    },
    {
        "path": "t%c9%99hsil-insan-omurun%c9%99-nec%c9%99-t%c9%99sir-edir",
        "img": "https://celt.az/wp-content/uploads/2020/02/laptop-woman-education-3087585-1024x682.jpg"
    },
    {
        "path": "mudd%c9%99tli-muqavil%c9%99-il%c9%99-isl%c9%99m%c9%99k-ist%c9%99y%c9%99n-mu%c9%99lliml%c9%99r-n%c9%99-etm%c9%99lidir",
        "img": "https://celt.az/wp-content/uploads/2020/02/teacher-female-college-1280966-1024x681.jpg"
    },
    {
        "path": "koronavirus-ve-xaricde-tehsil",
        "img": "https://celt.az/wp-content/uploads/2020/02/corona-virus-pandemic-4801040-819x1024.jpg"
    },
    {
        "path": "muellimler-ve-musabiqe-islemek",
        "img": "https://celt.az/wp-content/uploads/2020/02/school-classroom-boys-79612-1024x731.jpg"
    },
    {
        "path": "vaxt-suretle-gedir-yasli",
        "img": "https://celt.az/wp-content/uploads/2016/05/Otto_von_Bismarck-748x1024.jpg"
    },
    {
        "path": "muellimler-ucun-qrant",
        "img": "https://celt.az/wp-content/uploads/2017/06/scholarships-abroad.jpg"
    },
    {
        "path": "celt-korona-visus-dizinfeksiya-tehsil",
        "img": "https://celt.az/wp-content/uploads/2020/03/WhatsApp-Image-2020-02-29-at-20.08.56-768x1024.jpeg"
    },
    {
        "path": "bir-t%c9%99l%c9%99b%c9%99nin-ugur-dusturu",
        "img": "https://celt.az/wp-content/uploads/2020/01/WhatsApp-Image-2020-01-28-at-16.48.54-822x1024.jpeg"
    },
    {
        "path": "t%c9%99hsil-nazirliyi-t%c9%99l%c9%99b%c9%99l%c9%99r-arasinda-konulluluyun-t%c9%99bligi-v%c9%99-t%c9%99l%c9%99b%c9%99-konullul%c9%99r-h%c9%99r%c9%99katinin-t%c9%99skili-movzusunda-qra",
        "img": "https://celt.az/wp-content/uploads/2020/03/data-information-knowledge-229113-1024x723.jpg"
    },
    {
        "path": "2019-cu-ild%c9%99-amerikada-ali-t%c9%99hsili-haqqinda-n%c9%99-oyr%c9%99ndik-v%c9%99-2020-ci-ild%c9%99n-n%c9%99-gozl%c9%99m%c9%99k-olar",
        "img": "https://celt.az/wp-content/uploads/2019/11/seoul-1024x366.jpg"
    },
    {
        "path": "ruminiyada-t%c9%99qaud-proqrami-2020-45-yasad%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2020/03/my-experience-bucharest-romania-geoffrey-a7b34caa18fce92b74573ca1f8d585ce-1024x576.jpg"
    },
    {
        "path": "ruminiyada-t%c9%99qaud-proqraminin-musahib%c9%99-m%c9%99rh%c9%99l%c9%99sind%c9%99-istirak-ucun-%c9%99sas-meyarlar",
        "img": "https://celt.az/wp-content/uploads/2020/03/Scholarship-Opportunities-01-768x403-1.jpg"
    },
    {
        "path": "dunyanin-g%c9%99l%c9%99c%c9%99k-t%c9%99hsil-x%c9%99rit%c9%99si",
        "img": "https://celt.az/wp-content/uploads/2020/03/193719-79278235-0c32-4a36-b08d-dc563b63eab4-1024x670.jpg"
    },
    {
        "path": "koronavirusa-gor%c9%99-az%c9%99rbaycanda-universitet-distant-t%c9%99hsil%c9%99-kecdi",
        "img": "https://celt.az/wp-content/uploads/2020/03/unec_bina_121217.jpg"
    },
    {
        "path": "artiq-g%c9%99ncl%c9%99r-ideyalarini-reallasdiracaqdir-qrant-musabiq%c9%99si",
        "img": "https://celt.az/wp-content/uploads/2020/03/ideanı-reallaşdır-web-1200x675-1-1024x576.png"
    },
    {
        "path": "koronavirus-viraldir-yoxsa-real",
        "img": "https://celt.az/wp-content/uploads/2020/03/MW-HY812_corona_20200122133436_ZQ.jpg"
    },
    {
        "path": "distant-t%c9%99hsil-onlayn-t%c9%99hsil-erasi-baslayir",
        "img": "https://celt.az/wp-content/uploads/2020/03/6JM4uoggC8.jpg"
    },
    {
        "path": "kanadada-magistr-t%c9%99hsili-almaq-ist%c9%99y%c9%99nl%c9%99r-ucun-t%c9%99qaud-proqrami",
        "img": "https://celt.az/wp-content/uploads/2020/03/ytPDOIwuu5.jpg"
    },
    {
        "path": "informasiya-texnologiyalari-it-sah%c9%99si-uzr%c9%99-t%c9%99hsil-haqqi-t%c9%99qaudu-imkani",
        "img": "https://celt.az/wp-content/uploads/2020/03/iXEtbpH007.jpg"
    },
    {
        "path": "evd%c9%99qal-amma",
        "img": "https://celt.az/wp-content/uploads/2020/03/9NjUn9grRc.jpg"
    },
    {
        "path": "italiyada-koronavirusdan-sonra-t%c9%99hsil",
        "img": "https://celt.az/wp-content/uploads/2020/03/1IViTEUDr3.jpg"
    },
    {
        "path": "oxumaqdan-qacanlar",
        "img": "https://celt.az/wp-content/uploads/2020/03/330b2e16c20f38c18990819621388ba2-1024x576.jpg"
    },
    {
        "path": "amerikada-koronavirus-covid-19-epidemiyasindan-sonra-t%c9%99hsil",
        "img": "https://celt.az/wp-content/uploads/2020/03/K5Nn1vOZzJ.jpg"
    },
    {
        "path": "ispanlarin-koronavirusla-doyusu",
        "img": "https://celt.az/wp-content/uploads/2020/04/3O9g1OU3r8.jpg"
    },
    {
        "path": "virtual-m%c9%99kt%c9%99b-layih%c9%99si-basladi-virtual-m%c9%99kt%c9%99bd%c9%99n-sagirdl%c9%99r-nec%c9%99-qeydiyyatdan-kecib-istifad%c9%99-ed%c9%99-bil%c9%99rl%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2020/04/O19dWLhzZg.jpg"
    },
    {
        "path": "celt-d%c9%99-onlayn-d%c9%99rsl%c9%99r-nec%c9%99-kecirilir-suallarinizi-cavabi-burada",
        "img": "https://celt.az/wp-content/uploads/2020/04/YsY8cZrsIA.jpg"
    },
    {
        "path": "t%c9%99hsil-ocaqlari-v%c9%99-dig%c9%99r-400-%c9%99-yaxin-f%c9%99aliyy%c9%99t-sah%c9%99l%c9%99rin%c9%99-maliyy%c9%99-d%c9%99st%c9%99k-proqrami-elan-edildi-sahibkarlar-nece-muraciet-etmeli",
        "img": "https://celt.az/wp-content/uploads/2020/04/yDCanAU7zE.jpg"
    },
    {
        "path": "ted-universiteti-ile-bagli-suallar-ve-cavablar",
        "img": "https://celt.az/wp-content/uploads/2020/04/IMG_000185.jpg"
    },
    {
        "path": "virtual-m%c9%99kt%c9%99b-d%c9%99-mu%c9%99lliml%c9%99rin-qeydiyyatina-start-verilib-m%c9%99lliml%c9%99r-nec%c9%99-qeydiyyatdan-kecm%c9%99li",
        "img": "https://celt.az/wp-content/uploads/2020/04/uc8qtQg2BQ.jpg"
    },
    {
        "path": "27-aprel-tarixind%c9%99n-etbar%c9%99n-karantin-rejimi-yumsaldilir-hansi-sah%c9%99l%c9%99r-f%c9%99aliyy%c9%99tini-b%c9%99rpa-ed%c9%99c%c9%99kdir",
        "img": "https://celt.az/wp-content/uploads/2020/04/mMciVQNIJA.jpg"
    },
    {
        "path": "koronavirus-covid-19-v%c9%99-cahil-c%c9%99sar%c9%99t",
        "img": "https://celt.az/wp-content/uploads/2020/04/5XEiRvwMD7-1.jpg"
    },
    {
        "path": "mu%c9%99lliml%c9%99rin-mudd%c9%99tli-muqavil%c9%99li-is%c9%99-q%c9%99bulu-ucun-onlayn-musahib%c9%99-baslayir",
        "img": "https://celt.az/wp-content/uploads/2020/04/MKBRDaszVr.jpg"
    },
    {
        "path": "isvecr%c9%99d%c9%99-turizm-t%c9%99hsili-international-management-institute-switzerland-imi-switzerland",
        "img": "https://celt.az/wp-content/uploads/2020/04/Undergraduate-Programme-Brochure_page-0001-929x1024.jpg"
    },
    {
        "path": "adpu-il%c9%99-birg%c9%99-corc-vasinqton-universitetind%c9%99-t%c9%99hsil-imkani",
        "img": "https://celt.az/wp-content/uploads/2020/04/funding-main.jpg"
    },
    {
        "path": "adpu-v%c9%99-corc-vasinqton-universitetinin-ikili-diplom-proqrami-il%c9%99-bagli-sual-cavab",
        "img": "https://celt.az/wp-content/uploads/2020/04/c4UdqUPiy6.jpg"
    },
    {
        "path": "istanbul-bilgi-universitetine-qebul",
        "img": "https://celt.az/wp-content/uploads/2020/05/2518977_810x458.jpg"
    },
    {
        "path": "ispaniyada-biznes-t%c9%99hsili-esic-business-marketing-school",
        "img": "https://celt.az/wp-content/uploads/2020/05/ESIC-Main-Image-1024x448.png"
    },
    {
        "path": "riga-graduate-school-of-law-riqa-huquq-m%c9%99kt%c9%99bi",
        "img": "https://celt.az/wp-content/uploads/2020/05/Riga-Graduate-School.jpg"
    },
    {
        "path": "eu-business-school",
        "img": "https://celt.az/wp-content/uploads/2020/05/EU-BUSINESS-SCHOOL-0-1024x629.jpg"
    },
    {
        "path": "esei-international-business-school-4-illik-t%c9%99hsil-5-f%c9%99rqli-diplom",
        "img": "https://celt.az/wp-content/uploads/2020/05/ESEI-Barcelona-1024x575.jpg"
    },
    {
        "path": "green-card-lotoreyasi-dv-2021-l%c9%99gv-edildi",
        "img": "https://celt.az/wp-content/uploads/2020/05/OmoimFo6Jj.jpg"
    },
    {
        "path": "evd%c9%99-qalmaq-biz%c9%99-n%c9%99-oyr%c9%99tdi",
        "img": "https://celt.az/wp-content/uploads/2020/05/QMxpBet0sl.jpg"
    },
    {
        "path": "dim-2020-2021-ci-il-ucun-t%c9%99l%c9%99b%c9%99-q%c9%99bulu-prosesin%c9%99-start-verib-abituryentl%c9%99r-nec%c9%99-%c9%99riz%c9%99-ver%c9%99-bil%c9%99rl%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2020/05/dQPDaCehu1.jpg"
    },
    {
        "path": "covid-19-v%c9%99-iqlim-d%c9%99yisikliyi-t%c9%99hsil-dunyani-xilas-ed%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2020/05/VZGpG6wzm0.jpg"
    },
    {
        "path": "boyuk-britaniyada-m%c9%99kt%c9%99b-t%c9%99hsili-earlscliffe-college",
        "img": "https://celt.az/wp-content/uploads/2020/05/Picture1-1024x753.jpg"
    },
    {
        "path": "abituryentl%c9%99r-s%c9%99hv%c9%99-yol-veridkl%c9%99ri-t%c9%99sdiql%c9%99nmis-%c9%99riz%c9%99l%c9%99rini-l%c9%99gv-edibyenid%c9%99n-muraci%c9%99t-etm%c9%99k-ucun-n%c9%99-etm%c9%99lidirl%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2020/05/XYRUhrBD4j.jpg"
    },
    {
        "path": "mayin-18-d%c9%99n-bakida-sms-icaz%c9%99-l%c9%99gv-edilir-v%c9%99-karantin-rejimi-yumsaldilir",
        "img": "https://celt.az/wp-content/uploads/2020/05/423510813.jpg"
    },
    {
        "path": "tallin-texnoloji-universiteti",
        "img": "https://celt.az/wp-content/uploads/2020/05/TUT_1-1024x768.jpg"
    },
    {
        "path": "sat-imtahanlari-l%c9%99gv-olunan-sagirdl%c9%99r-n%c9%99-etm%c9%99lidir",
        "img": "https://celt.az/wp-content/uploads/2020/05/SAT-1-1024x640.jpeg"
    },
    {
        "path": "ingilis-dili-insan-h%c9%99yatini-nec%c9%99-d%c9%99yisir",
        "img": "https://celt.az/wp-content/uploads/2020/04/mMciVQNIJA.jpg"
    },
    {
        "path": "ispaniyada-t%c9%99hsil",
        "img": "https://celt.az/wp-content/uploads/2020/05/27704120_s-2048x934-1-1024x467.jpg"
    },
    {
        "path": "albert-eynsteyn-v%c9%99-dunyanin-6-nomr%c9%99li-universiteti",
        "img": "https://celt.az/wp-content/uploads/2020/05/IsZ4u5Iqx1.jpg"
    },
    {
        "path": "turkiyede-tehsil-almaq-isteyenler-nelere-diqq%c9%99t-etmelidir",
        "img": "https://celt.az/wp-content/uploads/2020/05/5c8b8bf1c03c0e52b4a39186.jpg"
    },
    {
        "path": "norvecin-1-nomr%c9%99li-biznes-universiteti-bi-norwegian-business-school",
        "img": "https://celt.az/wp-content/uploads/2020/05/Oslo-Campus-1024x456.jpg"
    },
    {
        "path": "koronavirusun-gucu-duynanin-%c9%99n-yaxsi-s%c9%99hiyy%c9%99-sistemi-v%c9%99-az%c9%99rbaycan",
        "img": "https://celt.az/wp-content/uploads/2020/05/52kg0UwRh9.jpg"
    },
    {
        "path": "c%c9%99nubi-ispaniya-sahill%c9%99rind%c9%99-biznes-t%c9%99hsili-schellhammer-business-school",
        "img": "https://celt.az/wp-content/uploads/2020/05/screenshot_20200521_115117-1024x340.png"
    },
    {
        "path": "toefl-ibt-special-home-edition",
        "img": "https://celt.az/wp-content/uploads/2020/05/download.jpg"
    },
    {
        "path": "az%c9%99rbaycanda-universitetl%c9%99r%c9%99-t%c9%99l%c9%99b%c9%99-q%c9%99bulu-qaydalarinda-hansi-d%c9%99yisiklikl%c9%99r-edilib",
        "img": "https://celt.az/wp-content/uploads/2020/05/0RzJwQEruy.jpg"
    },
    {
        "path": "covid-19-pandemiyasi-s%c9%99b%c9%99bil%c9%99-hansi-t%c9%99l%c9%99b%c9%99l%c9%99rin-t%c9%99hsil-haqqi-dovl%c9%99t-t%c9%99r%c9%99find%c9%99n-od%c9%99nil%c9%99c%c9%99k-bunun-ucun-nec%c9%99-onlayn-qeydiy",
        "img": "https://celt.az/wp-content/uploads/2020/05/0j8kK5to2K.jpg"
    },
    {
        "path": "boyuk-britaniyada-foundation-v%c9%99-a-level-t%c9%99hsili-university-of-east-anglia",
        "img": "https://celt.az/wp-content/uploads/2020/05/2-1024x575.png"
    },
    {
        "path": "dunyanin-virus-adli-dusm%c9%99ni-v%c9%99-covid-19-tarix-v%c9%99-fakt",
        "img": "https://celt.az/wp-content/uploads/2020/05/shutterstock_1674933541-1024x603.jpg"
    },
    {
        "path": "iubh-online-akkredit%c9%99-olunmus-beyn%c9%99lxalq-diplom",
        "img": "https://celt.az/wp-content/uploads/2020/05/cover-1024x579.jpg"
    },
    {
        "path": "mu%c9%99lliml%c9%99r-onlayn-t%c9%99hsili-nec%c9%99-s%c9%99m%c9%99r%c9%99li-t%c9%99skil-ed%c9%99-bil%c9%99rl%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2020/05/uWCeHOYz6Y.jpg"
    },
    {
        "path": "isvecr%c9%99d%c9%99-biznes-t%c9%99hsili-sbs-swiss-business-school",
        "img": "https://celt.az/wp-content/uploads/2020/05/screenshot_20200528_130616-1024x765.png"
    },
    {
        "path": "karantin-rejimi-15-iyun-tarixin%c9%99-kimi-uzadildi-hansi-yenilikl%c9%99r-var-maska-taxmaq-m%c9%99cburi-oldumu",
        "img": "https://celt.az/wp-content/uploads/2020/05/157391_zec2wb6wxz.jpg"
    },
    {
        "path": "iyun-ayindan-etibar%c9%99n-h%c9%99r-h%c9%99ft%c9%99sonu-az%c9%99rbaycanda-hansi-imtahanlar-keciril%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2020/05/close-up-students-writing-reading-exam-answer-sheets-exercises-classroom-school-with-stress_73622-887.jpg"
    },
    {
        "path": "boyuk-britaniyada-foundation-t%c9%99hsil-university-of-manchester",
        "img": "https://celt.az/wp-content/uploads/2020/05/manchester-uni-1024x576.jpeg"
    },
    {
        "path": "ue-university-applied-sciencesl%c9%99-tanis-olun",
        "img": "https://celt.az/wp-content/uploads/2020/05/business-alumnis-web-en__galleryhhh.jpg"
    },
    {
        "path": "universitet%c9%99-muraci%c9%99t-ed%c9%99rk%c9%99n-etm%c9%99li-v%c9%99-etm%c9%99m%c9%99li-oldugunuz-seyl%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2020/05/image_15-1024x683.png"
    },
    {
        "path": "9-v%c9%99-11-ci-sinifl%c9%99rin-buraxilis-imtahan-ballar-nec%c9%99-hesablanacaq",
        "img": "https://celt.az/wp-content/uploads/2020/05/ielts-exaqm-pdf-1024x576.jpg"
    },
    {
        "path": "n%c9%99-zaman-v%c9%99-harada-maska-taxmaq-m%c9%99cburidir-hansi-c%c9%99rim%c9%99l%c9%99r-t%c9%99tbiq-olunacaqdir",
        "img": "https://celt.az/wp-content/uploads/2020/05/maskaa.jpg"
    },
    {
        "path": "9-v%c9%99-11-ci-sinifl%c9%99r-ucun-buraxilis-imtahanlari-baslayir-imtahanlarin-s%c9%99h%c9%99r-v%c9%99-rayonlar-uzr%c9%99-kecirilm%c9%99-tarixl%c9%99ri",
        "img": "https://celt.az/wp-content/uploads/2020/06/2Fd0fVrCsc.jpg"
    },
    {
        "path": "buraxilis-v%c9%99-q%c9%99bul-imtahanlarinda-istirak-ed%c9%99n-s%c9%99xsl%c9%99r-ozl%c9%99ri-il%c9%99-n%c9%99l%c9%99ri-g%c9%99tirm%c9%99lidirl%c9%99r-imtahan-istirakcilarinin-bilm%c9%99li-oldugu-vacib",
        "img": "https://celt.az/wp-content/uploads/2020/06/MKyIX6O6Do.jpg"
    },
    {
        "path": "kolleci-bitir%c9%99n-subbakalavr-v%c9%99-universitetl%c9%99r%c9%99-q%c9%99bul-olmaq-ist%c9%99y%c9%99n-s%c9%99xsl%c9%99rin-n%c9%99z%c9%99rin%c9%99-%c9%99riz%c9%99-q%c9%99bulu-basladi-v%c9%99-tarixl",
        "img": "https://celt.az/wp-content/uploads/2020/06/1kb6n8FGKh-1.jpg"
    },
    {
        "path": "6-v%c9%99-ya-7-iyun-2020-ci-il-tarixl%c9%99rind%c9%99-imtahan-ver%c9%99c%c9%99k-abituriyentl%c9%99rin-n%c9%99z%c9%99rin%c9%99",
        "img": "https://celt.az/wp-content/uploads/2020/06/6jzfEE6jGL.jpg"
    },
    {
        "path": "az%c9%99rbaycanda-h%c9%99ft%c9%99sonu-kuc%c9%99y%c9%99-cixma-qadagasi-t%c9%99tbiq-edil%c9%99c%c9%99k-h%c9%99ft%c9%99sonu-keciril%c9%99c%c9%99k-imtahanlar-l%c9%99gv-oluna-bil%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2020/06/5ebe330855428314dc7515e5.jpg"
    },
    {
        "path": "vatel-hotel-and-tourism-business-school",
        "img": "https://celt.az/wp-content/uploads/2020/06/0-1024x576.jpg"
    },
    {
        "path": "fransada-biznes-t%c9%99hsili-neoma-business-school",
        "img": "https://celt.az/wp-content/uploads/2020/06/EXZxu74XYAAkISw.jpg"
    },
    {
        "path": "6-v%c9%99-7-iyun-2020-ci-il-tarixl%c9%99rind%c9%99-kecirilm%c9%99si-n%c9%99z%c9%99rd%c9%99-tutulan-imtahanlar-n%c9%99-vaxt-keciril%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2020/05/ielts-exaqm-pdf-1024x576.jpg"
    },
    {
        "path": "9-v%c9%99-11-ci-sinifl%c9%99r-uzr%c9%99-buraxilis-imtahanlarinda-istirak-etm%c9%99y%c9%99nl%c9%99r-ucun-sentyabr-ayinda-yenid%c9%99n-imtahanlar-t%c9%99skil-olunacaq",
        "img": "https://celt.az/wp-content/uploads/2020/06/L8zpuorsRR.jpg"
    },
    {
        "path": "az%c9%99rbaycanda-magistraturaya-q%c9%99bul-olmaq-ist%c9%99y%c9%99nl%c9%99rin-n%c9%99z%c9%99rin%c9%99-proqram-v%c9%99-ixtisaslasma-secimi-basladi",
        "img": "https://celt.az/wp-content/uploads/2020/06/wfUu5x5PdN.jpg"
    },
    {
        "path": "jurnalistika-v%c9%99-dig%c9%99r-44-v%c9%99-70-sayli-qabiliyy%c9%99t-imtahani-t%c9%99l%c9%99b-olunan-magistr-proqramlari-uzr%c9%99-qeydiyyat-ucun-son-gun",
        "img": "https://celt.az/wp-content/uploads/2020/06/mQkJrfGqB0.jpg"
    },
    {
        "path": "hansi-tarixde-hansi-rayonlarda-buraxilish-imtahanlari-kecirilecek",
        "img": "https://celt.az/wp-content/uploads/2020/06/QtR6iM7vO3.jpg"
    },
    {
        "path": "karantin-rejimi-1-iyul-tarixin%c9%99d%c9%99k-uzaldildi-hansi-s%c9%99h%c9%99r-v%c9%99-rayonlarda-evd%c9%99n-cixma-qadagasi-t%c9%99tbiq-edil%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2020/06/dIUIf6WhXK.jpg"
    },
    {
        "path": "az%c9%99rbaycan-dovl%c9%99t-iqtisad-universitetind%c9%99-unec-yeni-ixtisaslar-yaradilib",
        "img": "https://celt.az/wp-content/uploads/2020/06/hd-aspect-1472583202-students-980.jpg"
    },
    {
        "path": "fransada-biznes-uzr%c9%99-1-nomr%c9%99li-bakalavr-t%c9%99hsili-toulouse-business-school-tbs",
        "img": "https://celt.az/wp-content/uploads/2020/06/EY3-wMSXsAErYQx.jpg"
    },
    {
        "path": "avropa-qiym%c9%99tl%c9%99ri-il%c9%99-amerikan-t%c9%99hsili-alin",
        "img": "https://celt.az/wp-content/uploads/2020/06/rit-croatia-1024x683.jpg"
    },
    {
        "path": "mu%c9%99lliml%c9%99rin-is%c9%99-q%c9%99bulunda-yenilik",
        "img": "https://celt.az/wp-content/uploads/2020/06/55mBkPhyzE.jpg"
    },
    {
        "path": "18-19-iyun-tarixl%c9%99rind%c9%99-keciril%c9%99c%c9%99k-buraxilis-q%c9%99bul-imtahanlarinda-istirak-ed%c9%99c%c9%99k-s%c9%99xsl%c9%99rin-n%c9%99z%c9%99rin%c9%99",
        "img": "https://celt.az/wp-content/uploads/2020/06/bz8nzqWrJm.jpg"
    },
    {
        "path": "s%c9%99rt-iki-gunluk-karantin-rejimind%c9%99-n%c9%99-ed%c9%99-bil%c9%99rsiniz-n%c9%99y%c9%99-icaz%c9%99-var",
        "img": "https://celt.az/wp-content/uploads/2020/06/lE2iawJvmr.jpg"
    },
    {
        "path": 58002,
        "img": "https://celt.az/wp-content/uploads/2020/06/gallery_ish_1476262168_720-1024x683.jpg"
    },
    {
        "path": "mu%c9%99lliml%c9%99r-ucun-d%c9%99rsd%c9%99-az-danismaq-yoxsa-cox-danismaq",
        "img": "https://celt.az/wp-content/uploads/2020/06/sFrBHnYCzR.jpg"
    },
    {
        "path": "covid-19-virusuna-qarsi-daha-effektiv-d%c9%99rman-tapilib",
        "img": "https://celt.az/wp-content/uploads/2020/06/WQnbzIS7kx-1.jpg"
    },
    {
        "path": "bu-universitetd%c9%99-biznesmenl%c9%99rd%c9%99n-d%c9%99rs-alma-sansiniz-var",
        "img": "https://celt.az/wp-content/uploads/2020/06/52441777_10156359499974164_4982362775771676672_n.jpg"
    },
    {
        "path": "umumi-9-illik-v%c9%99-tam-11-illik-orta-t%c9%99hsil-s%c9%99viyy%c9%99si-uzr%c9%99-24-v%c9%99-25-iyunda-keciril%c9%99c%c9%99k-buraxilis-q%c9%99bul-imtahanlarinin-qrafiki",
        "img": "https://celt.az/wp-content/uploads/2020/06/WhatsApp-Image-2020-06-17-at-19.21.28.jpeg"
    },
    {
        "path": "adpu-v%c9%99-corc-vasinqton-universiteti-ikili-diplom-proqrami-c%c9%99rciv%c9%99sind%c9%99-s%c9%99n%c9%99d-q%c9%99bulu-elan-edir",
        "img": "https://celt.az/wp-content/uploads/2020/06/student-master.jpeg"
    },
    {
        "path": "isvecr%c9%99d%c9%99-biznes-t%c9%99hsili-ssbm-swiss-school-of-business-and-management",
        "img": "https://celt.az/wp-content/uploads/2020/06/134569_Artboard1-100.jpg"
    },
    {
        "path": "az%c9%99rbaycanda-karantin-rejimi-avqustun-1-d%c9%99k-uzadildi",
        "img": "https://celt.az/wp-content/uploads/2020/06/v33oaDAhRl.jpg"
    },
    {
        "path": "15-gunluk-ciddi-karantin-rejimi-edilir",
        "img": "https://celt.az/wp-content/uploads/2020/06/Syam2bta0S.jpg"
    },
    {
        "path": "chalmers-university-of-technology-skandinaviyanin-%c9%99n-yaxsi-texniki-universiteti",
        "img": "https://celt.az/wp-content/uploads/2020/06/Kuggen_Skylt_180515.jpg"
    },
    {
        "path": "mu%c9%99lliml%c9%99r-ucun-t%c9%99drisi-qrup-isl%c9%99ri-il%c9%99-nec%c9%99-s%c9%99m%c9%99r%c9%99li-t%c9%99skil-ed%c9%99-bil%c9%99rl%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2020/06/teacher-12.jpeg"
    },
    {
        "path": "24-25-iyun-tarixl%c9%99rind%c9%99-keciril%c9%99c%c9%99k-imtahanlarda-istirak-ed%c9%99c%c9%99k-s%c9%99xsl%c9%99rin-n%c9%99z%c9%99rin%c9%99-karantin%c9%99-deyisiklik-edilib",
        "img": "https://celt.az/wp-content/uploads/2020/06/mUAJ6Ta70d.jpg"
    },
    {
        "path": "1-2-3-4-v%c9%99-5-qruplar-uzr%c9%99-hansi-yeni-ixtisaslar-yaradilib",
        "img": "https://celt.az/wp-content/uploads/2020/06/fT8G5ps7bZ.jpg"
    },
    {
        "path": "mu%c9%99lliml%c9%99rin-is%c9%99-q%c9%99bul-qaydalarinda-d%c9%99yisiklik-edilib",
        "img": "https://celt.az/wp-content/uploads/2020/06/L5nE76WevO.jpg"
    },
    {
        "path": "imtahana-1-gun-qalmis-n%c9%99-etm%c9%99li",
        "img": "https://celt.az/wp-content/uploads/2020/06/3I76Ag5q4W.jpg"
    },
    {
        "path": "gbsb-global-business-school",
        "img": "https://celt.az/wp-content/uploads/2020/06/GBSB-1024x576.jpg"
    },
    {
        "path": "2-v%c9%99-3-iyul-tarixl%c9%99rind%c9%99-keciril%c9%99c%c9%99k-buraxilis-q%c9%99bul-imtahanlarinin-qrafiki",
        "img": "https://celt.az/wp-content/uploads/2020/06/ilk-1024x768.jpeg"
    },
    {
        "path": "2-3-iyul-tarixl%c9%99rind%c9%99-keciril%c9%99c%c9%99k-imtahanlarinda-istirak-ed%c9%99c%c9%99k-s%c9%99xsl%c9%99rin-n%c9%99z%c9%99rin%c9%99",
        "img": "https://celt.az/wp-content/uploads/2020/06/26kLttsazn.jpg"
    },
    {
        "path": "kompyuter-elml%c9%99ri-ixtisasi-uzr%c9%99-magistr-t%c9%99qaud-proqrami",
        "img": "https://celt.az/wp-content/uploads/2020/06/929mzNzAI2.jpg"
    },
    {
        "path": "az%c9%99rbaycanda-ilk-onlayn-xaricd%c9%99-t%c9%99hsil-s%c9%99rgisind%c9%99-niy%c9%99-istirak-etm%c9%99lisiniz",
        "img": "https://celt.az/wp-content/uploads/2020/06/106480918_3079622972133598_1297316954316964799_n.jpg"
    },
    {
        "path": "elan-dovl%c9%99t-proqrami-c%c9%99rciv%c9%99sind%c9%99-xaricd%c9%99-doktorantura-t%c9%99hsili",
        "img": "https://celt.az/wp-content/uploads/2020/07/9e2eIV7660.jpg"
    },
    {
        "path": "d%c9%99rsin-s%c9%99m%c9%99r%c9%99liyini-artiran-5-qeyri-verbal-oyr%c9%99tm%c9%99-metodlari",
        "img": "https://celt.az/wp-content/uploads/2020/07/iCXGEXB6yp.jpg"
    },
    {
        "path": "xususi-karantin-rejimi-20-iyul-tarixinedek-uzadilir",
        "img": "https://celt.az/wp-content/uploads/2020/07/rsKMzadZ7o.jpg"
    },
    {
        "path": "xususi-karantin-rejimi-20-iyul-tarixin%c9%99d%c9%99k-uzadilir-hansi-rayonlarda-s%c9%99rt-karantin-rejimi-t%c9%99tbiq-edil%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2020/07/rsKMzadZ7o.jpg"
    },
    {
        "path": "macaristanda-dovl%c9%99t-proqrami-c%c9%99rciv%c9%99sind%c9%99-t%c9%99hsil-almaq-huququ-qazananlar-mu%c9%99yy%c9%99nl%c9%99sib",
        "img": "https://celt.az/wp-content/uploads/2020/07/Capture-1024x576.png"
    },
    {
        "path": "mu%c9%99lliml%c9%99r-ucun-yenid%c9%99n-onlayn-musahib%c9%99l%c9%99r%c9%99-start-verilir",
        "img": "https://celt.az/wp-content/uploads/2020/07/WhatsApp-Image-2020-07-05-at-19.14.10.jpeg"
    },
    {
        "path": "2-3-iyul-tarixind%c9%99-keciril%c9%99n-imtahanlarin-n%c9%99tic%c9%99l%c9%99ri-aciqlandi",
        "img": "https://celt.az/wp-content/uploads/2020/07/WhatsApp-Image-2020-07-05-at-19.27.09.jpeg"
    },
    {
        "path": "%c9%99vv%c9%99lki-ill%c9%99rin-m%c9%99zunlari-ucun-ali-v%c9%99-orta-ixtisas-t%c9%99hsili-mu%c9%99ssis%c9%99l%c9%99rin%c9%99-q%c9%99bul-imtahani-baslayir-i-ci-m%c9%99rh%c9%99l%c9%99-imtahanlar-hansi",
        "img": "https://celt.az/wp-content/uploads/2020/07/KOmMijZV84.jpg"
    },
    {
        "path": "imtahanda-istirak-ed%c9%99c%c9%99k-s%c9%99xsl%c9%99rin-n%c9%99z%c9%99rin%c9%99-10-iyul",
        "img": "https://celt.az/wp-content/uploads/2020/07/YRvWsUSdcr.jpg"
    },
    {
        "path": "az%c9%99rbaycanda-ilk-xaricd%c9%99-t%c9%99hsil-s%c9%99rgisi-basladi",
        "img": "https://celt.az/wp-content/uploads/2020/07/m1Uwy74Ltu.jpg"
    },
    {
        "path": "online-d%c9%99rsl%c9%99rimiz-bar%c9%99d%c9%99-m%c9%99lumat",
        "img": "https://celt.az/wp-content/uploads/2020/07/SMM-12-1024x1024.png"
    },
    {
        "path": "nu-poqodi-cizgi-serialina-uc-yeni-q%c9%99hr%c9%99man-g%c9%99lir",
        "img": "https://celt.az/wp-content/uploads/2020/07/unnamed-2.jpg"
    },
    {
        "path": "rusiya-federasiyasi-xarici-isl%c9%99r-nazirliyinin-moskva-dovl%c9%99t-beyn%c9%99lxalq-%c9%99laq%c9%99l%c9%99r-universiteti-az%c9%99rbaycan-v%c9%99t%c9%99ndaslari-ucun-t%c9%99l%c9%99b%c9%99-q%c9%99bulu",
        "img": "https://celt.az/wp-content/uploads/2020/07/Oq7JCLZ329.jpg"
    },
    {
        "path": "usaqlarin-m%c9%99kt%c9%99b-secimin%c9%99-start-verilir",
        "img": "https://celt.az/wp-content/uploads/2020/07/1-2.jpg"
    },
    {
        "path": "ada-universiteti-hazirliq",
        "img": "https://celt.az/wp-content/uploads/2020/07/ADASHEKIL-1024x678.jpg"
    },
    {
        "path": "toefl-qeydiyyat",
        "img": "https://celt.az/wp-content/uploads/2020/07/unnamed.png"
    },
    {
        "path": "iubh-almaniyada-t%c9%99hsil-iubh-school-of-business-and-management",
        "img": "https://celt.az/wp-content/uploads/2020/07/CD10E5E6-1FC9-40D3-B803-3E3B844EEAE1-1024x896.jpeg"
    },
    {
        "path": "2020-ci-il-uzr%c9%99-mu%c9%99lliml%c9%99rin-is%c9%99-q%c9%99bulu-musabiq%c9%99si-baslayir",
        "img": "https://celt.az/wp-content/uploads/2020/07/pyMfKKPhJm.jpg"
    },
    {
        "path": "celt-beyn%c9%99lxlaq-m%c9%99kt%c9%99bi-kanadada-t%c9%99hsil-beyn%c9%99lxalq-q%c9%99bul-proqramini-elan-edir",
        "img": "https://celt.az/wp-content/uploads/2020/07/sseEQjS64V.jpg"
    },
    {
        "path": "universitetl%c9%99r%c9%99-q%c9%99bul-olmaq-ist%c9%99y%c9%99n-subbakalavrlarin-n%c9%99z%c9%99rin%c9%99",
        "img": "https://celt.az/wp-content/uploads/2020/07/vm68YkSApv.jpg"
    },
    {
        "path": "niy%c9%99-sabanci-universiteti",
        "img": "https://celt.az/wp-content/uploads/2020/07/unnamed-3.jpg"
    },
    {
        "path": "yenimu%c9%99lliml%c9%99rin-is%c9%99-q%c9%99bulu-uzr%c9%99-qeydiyyat-basladi",
        "img": "https://celt.az/wp-content/uploads/2020/07/4F4ONk913X.jpg"
    },
    {
        "path": "yeni-mu%c9%99lliml%c9%99rin-is%c9%99-q%c9%99bulu-il%c9%99-bagli-%c9%99n-cox-veril%c9%99n-suallara-cavab-burada",
        "img": "https://celt.az/wp-content/uploads/2020/07/rvXcooDWXE.jpg"
    },
    {
        "path": "operativ-q%c9%99rargah-iyulun-20-d%c9%99n-s%c9%99rt-karantin-rejimi-uzadilir",
        "img": "https://celt.az/wp-content/uploads/2020/07/wdwraBMMJU.jpg"
    },
    {
        "path": "23-24-iyul-tarixl%c9%99rind%c9%99-novb%c9%99ti-buraxilis-q%c9%99bul-imtahanlari-keciril%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2020/07/D8tM8Sm8iZ.jpg"
    },
    {
        "path": "cind%c9%99-t%c9%99hsil-almaq-huququ-qazanmis-s%c9%99xsl%c9%99rin-siyahisi-t%c9%99sdiql%c9%99nib",
        "img": "https://celt.az/wp-content/uploads/2020/07/Fi56AQltaq.jpg"
    },
    {
        "path": "rusiya-gomruk-akademiyasinda-gomruk-isi-ixtisasi-uzr%c9%99-t%c9%99hsil-almaq-ist%c9%99y%c9%99nl%c9%99rl%c9%99-onlayn-test-imtahani-v%c9%99-musahib%c9%99-kecirilib",
        "img": "https://celt.az/wp-content/uploads/2020/07/6cRiai2Nht.jpg"
    },
    {
        "path": "q%c9%99hr%c9%99manliq-salnam%c9%99si-v%c9%99-%c9%99srl%c9%99rdir-davam-ed%c9%99n-erm%c9%99ni-t%c9%99cavuzu",
        "img": "https://celt.az/wp-content/uploads/2020/07/qehremanlar-1024x594.jpeg"
    },
    {
        "path": "moht%c9%99s%c9%99m-sans-harvard-universitetin%c9%99-imtahansiz-q%c9%99bul-ol",
        "img": "https://celt.az/wp-content/uploads/2020/07/CELT-HIGH-SCHOOL-1.jpeg"
    },
    {
        "path": "23-24-iyul-tarixl%c9%99rind%c9%99-hansi-s%c9%99h%c9%99r-v%c9%99-rayonlarda-imtahanlar-keciril%c9%99c%c9%99kdir-imtahan-qrafiki-il%c9%99-tanis-olun",
        "img": "https://celt.az/wp-content/uploads/2020/07/WhatsApp-Image-2020-07-20-at-18.31.34-1.jpeg"
    },
    {
        "path": "23-24-iyul-tarixl%c9%99rind%c9%99-keciril%c9%99c%c9%99k-imtahanlarda-istirak-ed%c9%99c%c9%99k-s%c9%99xsl%c9%99rin-n%c9%99z%c9%99rin%c9%99",
        "img": "https://celt.az/wp-content/uploads/2020/07/KOmMijZV84.jpg"
    },
    {
        "path": "bu-il-mu%c9%99lliml%c9%99rin-is%c9%99-q%c9%99bulu-imtahanlari-n%c9%99-vaxt-keciril%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2020/07/muellimlerin-imtahani.jpeg"
    },
    {
        "path": "kiml%c9%99r-miq-imtahaninda-istirak-ed%c9%99-bilm%c9%99y%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2020/07/rJG6Cp8VRS.jpg"
    },
    {
        "path": "muxt%c9%99lif-olk%c9%99l%c9%99rd%c9%99-t%c9%99hsil-haqqinda-maraqli-faktlar",
        "img": "https://celt.az/wp-content/uploads/2020/07/3ca94606dfdd23f9246ad470abbea383.jpg"
    },
    {
        "path": "oxford-universiteti-covid-19-vaksinini-ugurla-sinaqdan-kecirdi",
        "img": "https://celt.az/wp-content/uploads/2020/07/lMn4Gr9I4s.jpg"
    },
    {
        "path": "ali-m%c9%99kt%c9%99b%c9%99-q%c9%99bul-imtahanlari-hansi-tarixl%c9%99rd%c9%99-keciril%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2020/07/ls0TTqQYq2.jpg"
    },
    {
        "path": "modart-international-paris",
        "img": "https://celt.az/wp-content/uploads/2020/07/moda-1024x576.jpg"
    },
    {
        "path": "eotvos-lorand-elte-universiteti",
        "img": "https://celt.az/wp-content/uploads/2020/07/eötvös-1024x683.jpg"
    },
    {
        "path": "rezidentura-imtahaninin-i-m%c9%99rh%c9%99l%c9%99sind%c9%99-istirak-ed%c9%99c%c9%99k-s%c9%99xsl%c9%99rin-diqq%c9%99tin%c9%99",
        "img": "https://celt.az/wp-content/uploads/2020/07/Fk0oxPGnUy.jpg"
    },
    {
        "path": "kiml%c9%99r-universitetl%c9%99r%c9%99-musabiq%c9%99d%c9%99nk%c9%99nar-q%c9%99bul-olacaqlar",
        "img": "https://celt.az/wp-content/uploads/2020/07/ebTOXskuDM.jpg"
    },
    {
        "path": "latviyada-t%c9%99hsil-t%c9%99qaud-proqraminin-qalibleri-kimlerdir",
        "img": "https://celt.az/wp-content/uploads/2020/07/YJCN05m6df.jpg"
    },
    {
        "path": "xaricde-tehsil-alan-telebelerimiz-hemyerlilerimiz-ermeni-texribatina-uymayin",
        "img": "https://celt.az/wp-content/uploads/2020/07/1594982727330_ZUdx9jA3.jpg"
    },
    {
        "path": "10-iyul-2020-ci-il-tarixind%c9%99-kecirilmis-q%c9%99bul-imtahanlarinin-n%c9%99ti",
        "img": "https://celt.az/wp-content/uploads/2020/07/WF64IZFvJL.jpg"
    },
    {
        "path": "29-30-iyul-tarixl%c9%99rind%c9%99-novb%c9%99ti-buraxilis-q%c9%99bul-imtahanlari-keciril%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2020/07/02685e052346a851533f18c8539c153e-1024x682.jpeg"
    },
    {
        "path": "rezidenturaya-q%c9%99bul-imtahaninin-i-m%c9%99rh%c9%99l%c9%99sind%c9%99-istirak-ed%c9%99c%c9%99k-namiz%c9%99dl%c9%99rin-n%c9%99z%c9%99rin%c9%99",
        "img": "https://celt.az/wp-content/uploads/2020/07/qRXiH2QjGz.jpg"
    },
    {
        "path": "i-v%c9%99-ii-qruplar-uzr%c9%99-200-balliq-ixtisaslar-hansilardir-nec%c9%99-q%c9%99bul-olmaq-olar",
        "img": "https://celt.az/wp-content/uploads/2020/07/BLC24u2LvX.jpg"
    },
    {
        "path": "nasa-hansi-problemin-h%c9%99lli-ucun-t%c9%99l%c9%99b%c9%99l%c9%99ri-is%c9%99-c%c9%99lb-ed%c9%99c%c9%99kdir-t%c9%99l%c9%99b%c9%99l%c9%99ri-180-min-dollar-gozl%c9%99yir",
        "img": "https://celt.az/wp-content/uploads/2020/07/nK1V21rbrJ.jpg"
    },
    {
        "path": "xaricd%c9%99-t%c9%99hsil-alanlan-az%c9%99rbaycana-nece-kocurul%c9%99-biler",
        "img": "https://celt.az/wp-content/uploads/2020/07/9FYG72lQtT.jpg"
    },
    {
        "path": "orta-m%c9%99kt%c9%99b-d%c9%99rslikl%c9%99rinin-qiym%c9%99tl%c9%99ndirilm%c9%99si-sah%c9%99sind%c9%99-mut%c9%99x%c9%99sis-t%c9%99l%c9%99b-olunur",
        "img": "https://celt.az/wp-content/uploads/2020/07/1FsAiiHxUV.jpg"
    },
    {
        "path": "dublin-institute-of-technology",
        "img": "https://celt.az/wp-content/uploads/2020/08/EN2Fg6vX4AA-_MI-1024x683.jpg"
    },
    {
        "path": "az%c9%99rbaycanda-karantin-rejimi-yumsaldildi-31-avqust-tarixin%c9%99d%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2020/08/Karantin.jpg"
    },
    {
        "path": "31-avqust-tarixin%c9%99d%c9%99k-hansi-s%c9%99h%c9%99r-v%c9%99-rayonlarda-s%c9%99rt-karantin-rejimi-t%c9%99tbiq-edil%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2020/08/hb51e5r1g51.jpg"
    },
    {
        "path": "m%c9%99kt%c9%99b-d%c9%99rslikl%c9%99ri-uzr%c9%99-onlayn-sinaq-imtahan-portali-istifad%c9%99y%c9%99-verilib",
        "img": "https://celt.az/wp-content/uploads/2020/08/03.png"
    },
    {
        "path": "xaricd%c9%99-doktorantura-t%c9%99hsili-il%c9%99-bagli-s%c9%99n%c9%99d-q%c9%99bulunun-mudd%c9%99ti-uzadildi",
        "img": "https://celt.az/wp-content/uploads/2020/07/9e2eIV7660.jpg"
    },
    {
        "path": "d%c9%99rsl%c9%99rin-baslamasi-il%c9%99-bagli-sad-x%c9%99b%c9%99r-ilk-olaraq-bu-sinifl%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2020/08/117302464_219668086014519_3355592695078509861_n.jpg"
    },
    {
        "path": "t%c9%99hsil-nazirliyi-yeni-d%c9%99rs-ilind%c9%99-t%c9%99dris-prosesinin-t%c9%99skili-haqqinda",
        "img": "https://celt.az/wp-content/uploads/2020/08/zgal26Kh22.jpg"
    },
    {
        "path": "t%c9%99drisd%c9%99-baliq-skeleti-diaqrami",
        "img": "https://celt.az/wp-content/uploads/2020/08/77cc6e4324b83ca4f5b8d3dba73f97b2_729.jpg"
    },
    {
        "path": "t%c9%99hsild%c9%99-diferensial-t%c9%99lim-n%c9%99dir",
        "img": "https://celt.az/wp-content/uploads/2020/08/1BDyCq7M5Z.jpg"
    },
    {
        "path": "ali-m%c9%99kt%c9%99b-v%c9%99-kollecl%c9%99rin-xususi-qabiliyy%c9%99t-t%c9%99l%c9%99b-ed%c9%99n-ixtisaslarina-q%c9%99bul-olmaq-ist%c9%99y%c9%99n-abituriyentl%c9%99rin-n%c9%99z%c9%99rin%c9%99",
        "img": "https://celt.az/wp-content/uploads/2020/08/os8T6ygFjU.jpg"
    },
    {
        "path": "xususi-qabiliyy%c9%99t-t%c9%99l%c9%99b-ed%c9%99n-ixtisaslar-uzr%c9%99-musabiq%c9%99-s%c9%99rtl%c9%99ri-nec%c9%99dir",
        "img": "https://celt.az/wp-content/uploads/2020/08/O5XeOzRQW2.jpg"
    },
    {
        "path": "q%c9%99bul-imtahanlarinin-ikinci-m%c9%99rh%c9%99l%c9%99sinin-qrafiki-elan-olunub",
        "img": "https://celt.az/wp-content/uploads/2020/06/26kLttsazn.jpg"
    },
    {
        "path": "imtahanda-istirak-edecek-sexslerin-nezerine",
        "img": "https://celt.az/wp-content/uploads/2020/08/2hbHbOBcBW.jpg"
    },
    {
        "path": "i-v%c9%99-iv-ixtisas-qrupu-uzr%c9%99-imtahanlarin-ikinci-m%c9%99rh%c9%99l%c9%99si-n%c9%99-zaman-keciril%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2020/08/80feHIMBuT.jpg"
    },
    {
        "path": "kanadada-t%c9%99hsil-alan-az%c9%99rbaycanli-yeniyetm%c9%99-boyuk-ugura-imza-atib",
        "img": "https://celt.az/wp-content/uploads/2020/08/woXhtgKizY.jpg"
    },
    {
        "path": "ali-t%c9%99hsil-mu%c9%99ssis%c9%99l%c9%99rin%c9%99-q%c9%99bul-olmaq-ucun-qeydiyyatdan-kecmis-subbakalavrlarin-n%c9%99z%c9%99rin%c9%99",
        "img": "https://celt.az/wp-content/uploads/2020/08/vIoeEkrTak.jpg"
    },
    {
        "path": "yeni-t%c9%99dris-ilind%c9%99n-orta-m%c9%99kt%c9%99bl%c9%99rd%c9%99-d%c9%99rs%c9%99-davamiyy%c9%99t-m%c9%99cburi-olmayacaq",
        "img": "https://celt.az/wp-content/uploads/2020/08/1567931697_untitled-1.jpg"
    },
    {
        "path": "mu%c9%99lliml%c9%99rin-is%c9%99-q%c9%99bulu-miq-uzr%c9%99-musabiq%c9%99nin-ikinci-m%c9%99rh%c9%99l%c9%99si-n%c9%99-zaman-baslayir",
        "img": "https://celt.az/wp-content/uploads/2020/08/945d0fef156b6d2.gif"
    },
    {
        "path": "hansi-mu%c9%99llim-namiz%c9%99dl%c9%99r-vakant-secimind%c9%99-istirak-ed%c9%99-bil%c9%99rl%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2020/08/rixmp75ivvc8toiltq8k-1.jpg"
    },
    {
        "path": "9-illik-orta-tehsil-bazasindan-kolleclere-qebul-olmaq-isteyenlerin-nezerine",
        "img": "https://celt.az/wp-content/uploads/2020/08/kYNOq76ikO.jpg"
    },
    {
        "path": "20-21-avqustda-keciril%c9%99c%c9%99k-q%c9%99bul-imtahanlarinin-ikinci-m%c9%99rh%c9%99l%c9%99sinin-qrafiki-elan-olunub",
        "img": "https://celt.az/wp-content/uploads/2020/08/7469_gy4hccmyvi.jpg"
    },
    {
        "path": "uzr-ist%c9%99m%c9%99y%c9%99-c%c9%99sar%c9%99ti-olmayan-savadli-v%c9%99-agir-pasinyan",
        "img": "https://celt.az/wp-content/uploads/2020/08/113912074_whatsubject.jpg"
    },
    {
        "path": "avqustun-14-d-keciriln-imtahanda-istifad-olunan-test-tapsiriqlari",
        "img": "https://celt.az/wp-content/uploads/2020/08/test-testing-exam-sat.jpg"
    },
    {
        "path": "20-21-avqust-2020-ci-tarixl%c9%99rind%c9%99-keciril%c9%99c%c9%99k-imtahanlarda-istirak-ed%c9%99n-abituriyentl%c9%99rin-n%c9%99z%c9%99rin%c9%99",
        "img": "https://celt.az/wp-content/uploads/2020/08/6d5c4dc095541c97ea2e43e44d0e4367.jpg"
    },
    {
        "path": "29-30-iyul-2020-ci-il-tarixl%c9%99rind%c9%99-kecirilmis-buraxilis-v%c9%99-q%c9%99bul-imtahanlarinin-n%c9%99tic%c9%99l%c9%99ri-elan-olunub",
        "img": "https://celt.az/wp-content/uploads/2020/08/OaK8V6IXPh.jpg"
    },
    {
        "path": "turkm%c9%99nistanda-sagirdl%c9%99r-m%c9%99kt%c9%99b%c9%99-sanitar-cantalarla-ged%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2020/08/ShowImage.jpg"
    },
    {
        "path": "ust-covid-19-peyv%c9%99ndi-il%c9%99-bagli-strategiyani-aciqlayib",
        "img": "https://celt.az/wp-content/uploads/2020/08/DmZuiyw7fd.jpg"
    },
    {
        "path": "fontys-university-of-applied-sciences",
        "img": "https://celt.az/wp-content/uploads/2020/08/Fo0016561_e3a001.jpg"
    },
    {
        "path": "i-v-iv-ixtisas-qruplari-uzr-avqustun-20-d-keciriln-imtahanda-istifad-olunan-testlerin-duzgun-cavablari",
        "img": "https://celt.az/wp-content/uploads/2020/08/SaGOu4nOyj.jpg"
    },
    {
        "path": "i-qrup-uzr%c9%99-kiml%c9%99r-ixtisas-secimin%c9%99-buraxilacaqlar",
        "img": "https://celt.az/wp-content/uploads/2020/08/f18af724-2112-4bb5-8f51-39c1acf39127_610.jpg"
    },
    {
        "path": "buraxilis-imtahanlarinin-n%c9%99tic%c9%99l%c9%99ri-il%c9%99-razi-deyils%c9%99n-el%c9%99-indi-muraci%c9%99t-et-son-2-gun",
        "img": "https://celt.az/wp-content/uploads/2020/08/uVc24fMxJ9.jpg"
    },
    {
        "path": "novb%c9%99ti-miq-imtahanlari-hansi-tarixd%c9%99-keciril%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2020/08/03-19.08.20_264-1024x654.jpg"
    },
    {
        "path": "az%c9%99rbaycan-tibb-universitetinin-h%c9%99rbi-tibb-m%c9%99kt%c9%99binin-feldser-ixtisasina-q%c9%99bul-imtahaninda-istirak-ed%c9%99c%c9%99k-s%c9%99xsl%c9%99rin-n%c9%99z%c9%99rin%c9%99",
        "img": "https://celt.az/wp-content/uploads/2020/08/unnamed-3-1.jpg"
    },
    {
        "path": "xarici-olk%c9%99l%c9%99rd%c9%99-m%c9%99kt%c9%99bl%c9%99r-hansi-formada-f%c9%99aliyy%c9%99t-gost%c9%99r%c9%99c%c9%99k-hansi-olk%c9%99d%c9%99-sagirdi-d%c9%99rs%c9%99-qoymayan-valideyn-c%c9%99rim%c9%99l",
        "img": "https://celt.az/wp-content/uploads/2020/08/bCRuaWGgp2.jpg"
    },
    {
        "path": "magistraturalarinin-bos-qalan-yerl%c9%99rin%c9%99-q%c9%99bul-elan-edilir",
        "img": "https://celt.az/wp-content/uploads/2020/08/7On19VNh9F.jpg"
    },
    {
        "path": "boyuk-britaniya-koronavirus-vaksininin-sinaqlarina-basladi",
        "img": "https://celt.az/wp-content/uploads/2020/08/7YBEiODQOW.jpg"
    },
    {
        "path": "gulhan%c9%99-tibb-fakult%c9%99sin%c9%99-v%c9%99-rusiya-h%c9%99rbi-tibb-akademiyasina-kursantlarin-q%c9%99bulu-ucun-musabiq%c9%99-elan-olunur",
        "img": "https://celt.az/wp-content/uploads/2020/08/LI8L6BWlQv.jpg"
    },
    {
        "path": "umumi-t%c9%99hsil-mu%c9%99ssis%c9%99l%c9%99rind%c9%99-t%c9%99hsil-alan-birinci-sinif-sagirdl%c9%99rinin-sayi-aciqlanib",
        "img": "https://celt.az/wp-content/uploads/2020/08/1302068049579_bulletin-1.jpg"
    },
    {
        "path": "tvente-universiteti",
        "img": "https://celt.az/wp-content/uploads/2020/08/Стипендиальная-программа-университета-Univesity-of-Twente-1024x576.jpg"
    },
    {
        "path": "bakinin-b%c9%99zi-m%c9%99kt%c9%99bl%c9%99rind%c9%99-artiq-d%c9%99rsl%c9%99r-baslayir",
        "img": "https://celt.az/wp-content/uploads/2020/08/Vz6Z4I5kRI.jpg"
    },
    {
        "path": "mu%c9%99lliml%c9%99rin-maskadan-istifad%c9%99si-m%c9%99cburi-olacaq",
        "img": "https://celt.az/wp-content/uploads/2020/08/bXalUTlk32.jpg"
    },
    {
        "path": "az%c9%99rbaycanda-t%c9%99hsilin-baslanma-tarixl%c9%99ri-v%c9%99-qaranliq-qalan-m%c9%99s%c9%99l%c9%99l%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2020/08/5BLMxl3WPe.jpg"
    },
    {
        "path": "valideyn-iclaslari-nec%c9%99-t%c9%99skil-olunmalidir",
        "img": "https://celt.az/wp-content/uploads/2020/08/mT6ARwMvuD.jpg"
    },
    {
        "path": "xususi-karantin-rejimi-sentyabrin-30-d%c9%99k-uzadilib",
        "img": "https://celt.az/wp-content/uploads/2020/08/unnamed.jpg"
    },
    {
        "path": "f%c9%99rdi-t%c9%99dris-v%c9%99-repetitor-xidm%c9%99tl%c9%99ri-hansi-formada-isl%c9%99y%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2020/08/IRbCSSwo4E.jpg"
    },
    {
        "path": "rayonlararasi-gedis-g%c9%99lis-tam-acilacaqmi",
        "img": "https://celt.az/wp-content/uploads/2020/08/post.jpg"
    },
    {
        "path": "nazirl%c9%99r-kabineti-xususi-karantin-rejimi-dovrund%c9%99-t%c9%99hsil-mu%c9%99ssis%c9%99l%c9%99rind%c9%99-t%c9%99dris-v%c9%99-t%c9%99lim-t%c9%99rbiy%c9%99-prosesinin-t%c9%99skili-bar%c9%99d%c9%99-q",
        "img": "https://celt.az/wp-content/uploads/2020/08/8g8IcFYUfn.jpg"
    },
    {
        "path": "ingilis-fransiz-v-alman-dillri-uzr-mutxssislr-axtarilir",
        "img": "https://celt.az/wp-content/uploads/2020/08/Zanry9Aesq.jpg"
    },
    {
        "path": "hibrid-t%c9%99hsil-n%c9%99dir-celt-olaraq-ot%c9%99n-6-ayliq-karantin-dovrund%c9%99-biz-hibrid-t%c9%99hsili-nec%c9%99-t%c9%99tbiq-etdik",
        "img": "https://celt.az/wp-content/uploads/2020/08/ZfRp2ompjh.jpg"
    },
    {
        "path": "rezidenturaya-q%c9%99bul-imtahaninin-ii-m%c9%99rh%c9%99l%c9%99sind%c9%99-istirak-etm%c9%99k-ucun-qeydiyyat-baslayir",
        "img": "https://celt.az/wp-content/uploads/2020/07/86FUF35WjJ.jpg"
    },
    {
        "path": "bu-il-miq-imtahanlarinin-musahib%c9%99-m%c9%99rh%c9%99l%c9%99si-hansi-formada-keciril%c9%99c%c9%99k-sual-cavab",
        "img": "https://celt.az/wp-content/uploads/2020/09/1ls8iqTbF7-1.jpg"
    },
    {
        "path": "miq-in-musahib%c9%99-m%c9%99rh%c9%99l%c9%99sind%c9%99-mu%c9%99llim-namiz%c9%99dl%c9%99r%c9%99-%c9%99n-cox-hansi-suallar-verilir",
        "img": "https://celt.az/wp-content/uploads/2020/09/psSJrQ0tvk.jpg"
    },
    {
        "path": "tartu-universiteti",
        "img": "https://celt.az/wp-content/uploads/2020/09/ut-1024x660.jpg"
    },
    {
        "path": "9-illik-t%c9%99hsil-bazasindan-kollecl%c9%99r%c9%99-ixtisas-secimi-basladi",
        "img": "https://celt.az/wp-content/uploads/2020/05/2E1A3975-edit2-min-1024x683.jpg"
    },
    {
        "path": "i-ixtisas-qrupuna-aid-memarliq-ixtisasina-q%c9%99bul-olmaq-ist%c9%99y%c9%99nl%c9%99rin-n%c9%99z%c9%99rin%c9%99",
        "img": "https://celt.az/wp-content/uploads/2020/09/D8RIAQa96Y.jpg"
    },
    {
        "path": "20-21-avqust-2020-ci-il-tarixl%c9%99rind%c9%99-kecirilmis-q%c9%99bul-imtahanlarinin-n%c9%99tic%c9%99l%c9%99ri-elan-edilib",
        "img": "https://celt.az/wp-content/uploads/2020/09/jjbQCfH83C.jpg"
    },
    {
        "path": "sentyabrin-8-d%c9%99n-etibar%c9%99n-baki-sumqayit-v%c9%99-abseron-rayonlarina-gedis-g%c9%99lis-b%c9%99rpa-olunur",
        "img": "https://celt.az/wp-content/uploads/2020/08/post.jpg"
    },
    {
        "path": "koreya-respublikasinin-inha-ali-t%c9%99hsil-mu%c9%99ssis%c9%99si-il%c9%99-baki-muh%c9%99ndislik-universitetinin-ikili-diplom-proqrami-uzr%c9%99-s%c9%99n%c9%99d-q%c9%99bulunun-mudd%c9%99ti-uzadilib",
        "img": "https://celt.az/wp-content/uploads/2020/09/aaLMubFrEx-1.jpg"
    },
    {
        "path": "d%c9%99rslikl%c9%99rin-qiym%c9%99tl%c9%99ndirilm%c9%99si-uzr%c9%99-mut%c9%99x%c9%99ssis-xidm%c9%99tl%c9%99ri-t%c9%99l%c9%99b-olunur",
        "img": "https://celt.az/wp-content/uploads/2020/09/pIpdBgmYNH.jpg"
    },
    {
        "path": "abituriyentl%c9%99rin-n%c9%99z%c9%99rin%c9%99-ixtisas-secimi-baslayir",
        "img": "https://celt.az/wp-content/uploads/2020/09/Q6i8FnLIQt.jpg"
    },
    {
        "path": "ali-t%c9%99hsil-mu%c9%99ssis%c9%99l%c9%99rin%c9%99-q%c9%99bul-ucun-musabiq%c9%99-s%c9%99rtl%c9%99ri-nec%c9%99-mu%c9%99yy%c9%99n-olunur",
        "img": "https://celt.az/wp-content/uploads/2020/09/hDdupyyyjL.jpg"
    },
    {
        "path": "lublin-texniki-universiteti-lublin-politexnik",
        "img": "https://celt.az/wp-content/uploads/2020/09/9119_WEiI-1024x768.jpg"
    },
    {
        "path": "yeni-d%c9%99rs-ili-ucun-numun%c9%99vi-c%c9%99dv%c9%99ll%c9%99ri-t%c9%99qdim-edirik",
        "img": "https://celt.az/wp-content/uploads/2020/09/Cn1yrK1CW0.jpg"
    },
    {
        "path": "t%c9%99hsil-mu%c9%99ssis%c9%99l%c9%99rind%c9%99-sosial-m%c9%99saf%c9%99-nec%c9%99-t%c9%99min-edil%c9%99c%c9%99k-t%c9%99limat",
        "img": "https://celt.az/wp-content/uploads/2020/09/VjHqYcf6FB.jpg"
    },
    {
        "path": "harvardin-universitetinin-t%c9%99qaudunu-qazanan-az%c9%99rbaycanli-g%c9%99nc",
        "img": "https://celt.az/wp-content/uploads/2020/09/36Ad2nYOY1.jpg"
    },
    {
        "path": "ii-ixtisas-qrupuna-aid-ixtisaslari-sec%c9%99c%c9%99k-abituriyentl%c9%99rin-n%c9%99z%c9%99rin%c9%99",
        "img": "https://celt.az/wp-content/uploads/2020/09/modern.az_modern.az_imtahan_977_516.jpg"
    },
    {
        "path": "ulster-universiteti",
        "img": "https://celt.az/wp-content/uploads/2020/09/5760-1024x614.jpg"
    },
    {
        "path": "umumi-9-illik-orta-t%c9%99hsil-bazasinda-q%c9%99bul-imtahanlarinin-n%c9%99tic%c9%99l%c9%99ri-elan-olunub",
        "img": "https://celt.az/wp-content/uploads/2020/09/IVHwaxcI30.jpg"
    },
    {
        "path": "koreya-respublikasinin-inha-ali-t%c9%99hsil-mu%c9%99ssis%c9%99si-il%c9%99-bmu-nin-ikili-diplom-proqrami-uzr%c9%99-%c9%99lav%c9%99-q%c9%99bul-elan-edilir",
        "img": "https://celt.az/wp-content/uploads/2020/09/eC9v450VFa.jpg"
    },
    {
        "path": "yeni-t%c9%99dris-ilind%c9%99-virtual-m%c9%99kt%c9%99bd%c9%99-hansi-yenilikl%c9%99r-var",
        "img": "https://celt.az/wp-content/uploads/2020/09/Si9lHczdJl-1.jpg"
    },
    {
        "path": "european-institute-of-design-italy",
        "img": "https://celt.az/wp-content/uploads/2020/09/ied.jpg"
    },
    {
        "path": "ali-t%c9%99hsil-mu%c9%99ssis%c9%99l%c9%99rin%c9%99-musabiq%c9%99d%c9%99nk%c9%99nar-kiml%c9%99r-q%c9%99bul-ola-bil%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2020/09/4G7bRVThsI.jpg"
    },
    {
        "path": "11-ci-sinif-thsil-bazasinda-kollec-qbul-olmaq-istyn-abituriyentlrin-nzrin",
        "img": "https://celt.az/wp-content/uploads/2020/09/UYM8nqgfNY.jpg"
    },
    {
        "path": "t%c9%99l%c9%99b%c9%99-adini-kiml%c9%99r-qazandi-dim-n%c9%99tic%c9%99l%c9%99ri-aciqladi",
        "img": "https://celt.az/wp-content/uploads/2020/09/TOgE08RBIY.jpg"
    },
    {
        "path": "qbul-imtahanlarinda-yuksk-bal-toplayib-lakin-ali-mktb-qbul-olmayan-abituryentlr",
        "img": "https://celt.az/wp-content/uploads/2020/09/jMxKJOS04e.jpg"
    },
    {
        "path": "ali-m%c9%99kt%c9%99bl%c9%99rd%c9%99-bos-qalan-yerl%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2020/09/RmjCAEhQnf.jpg"
    },
    {
        "path": "mudafi%c9%99-nazirliyi-c%c9%99bh%c9%99d%c9%99ki-v%c9%99ziyy%c9%99tl%c9%99-bagli-m%c9%99lumat-yaydi",
        "img": "https://celt.az/wp-content/uploads/2020/09/1583565599_00d4ad81-e9ab-4131-8e54-6383ba50f7b5.jpg"
    },
    {
        "path": "az%c9%99rbaycan-prezidenti-ilham-%c9%99liyev-h%c9%99rbi-v%c9%99ziyy%c9%99t-elan-edilm%c9%99si-haqqinda-f%c9%99rman-imzalayib",
        "img": "https://celt.az/wp-content/uploads/2020/09/1601219538040_u3BCa8kE.jpg"
    },
    {
        "path": "murov-dagi-silsil%c9%99sind%c9%99ki-murov-zirv%c9%99si-v%c9%99-7-k%c9%99nd-dusm%c9%99nd%c9%99n-azad-olunub",
        "img": "https://celt.az/wp-content/uploads/2020/07/qehremanlar-1024x594.jpeg"
    },
    {
        "path": "az%c9%99rbaycanda-qism%c9%99n-s%c9%99f%c9%99rb%c9%99rlik-elan-edildi",
        "img": "https://celt.az/wp-content/uploads/2020/09/1601219538040_u3BCa8kE.jpg"
    },
    {
        "path": "erm%c9%99nistanin-t%c9%99cavuzu-zamani-iki-m%c9%99kt%c9%99blimiz-h%c9%99lak-olub",
        "img": "https://celt.az/wp-content/uploads/2020/09/Mektebliler.jpg"
    },
    {
        "path": "karantin-rejiminin-mudd%c9%99ti-2020-ci-il-2-noyabr-tarixin%c9%99d%c9%99k-uzadilir",
        "img": "https://celt.az/wp-content/uploads/2020/08/unnamed.jpg"
    },
    {
        "path": "celt-xaricd%c9%99-t%c9%99hsil-alan-t%c9%99l%c9%99b%c9%99l%c9%99r%c9%99-muraci%c9%99t-edir",
        "img": "https://celt.az/wp-content/uploads/2020/09/1583565599_00d4ad81-e9ab-4131-8e54-6383ba50f7b5.jpg"
    },
    {
        "path": "oktyabrin-1-d%c9%99n-v-ix-sinifl%c9%99r-%c9%99yani-d%c9%99rsl%c9%99r%c9%99-baslayacaqlar",
        "img": "https://celt.az/wp-content/uploads/2020/09/eGvFL3aTpl.jpg"
    },
    {
        "path": "gozumuz-aydin-c%c9%99brayil-dusm%c9%99nd%c9%99n-azad-olundu",
        "img": "https://celt.az/wp-content/uploads/2020/10/xuda21.jpg"
    },
    {
        "path": "prezident-ilham-%c9%99liyev-pasinyan-az%c9%99rbaycan-xalqindan-uzr-ist%c9%99sin-v%c9%99-desin-ki-qarabag-erm%c9%99nistan-deyil",
        "img": "https://celt.az/wp-content/uploads/2020/09/1601219538040_u3BCa8kE.jpg"
    },
    {
        "path": "prezident-ilham-%c9%99liyev-bir-il-%c9%99vv%c9%99l-pasinyan-qarabag-erm%c9%99nistandir-v%c9%99-noqt%c9%99-dem%c9%99kl%c9%99-butun-danisiqlar-prosesin%c9%99-z%c9%99rb%c9%99",
        "img": "https://celt.az/wp-content/uploads/2020/10/1601837773847_yR2tAp6s.jpeg"
    },
    {
        "path": "american-graduate-school-of-business-agsb",
        "img": "https://celt.az/wp-content/uploads/2020/10/XXL.jpeg"
    },
    {
        "path": "g%c9%99nc%c9%99-q%c9%99tliami-erm%c9%99niterrounason",
        "img": "https://celt.az/wp-content/uploads/2020/10/ERMENI-TERRORU-1024x994.jpg"
    },
    {
        "path": "niy%c9%99-koc-universiteti",
        "img": "https://celt.az/wp-content/uploads/2020/10/ku-2.jpg"
    },
    {
        "path": "cexiyada-t%c9%99hsil-charles-universiteti",
        "img": "https://celt.az/wp-content/uploads/2020/10/image-6.png"
    },
    {
        "path": "virtual-m%c9%99kt%c9%99bd%c9%99-birinci-sinif-uzr%c9%99-qeydiyyat-artiq-mumkundur",
        "img": "https://celt.az/wp-content/uploads/2020/11/azedu.az_849D0270-6DA4-4123-8499-414D9CBFFA8E_399-1024x576.jpeg"
    },
    {
        "path": "virtual-m%c9%99kt%c9%99bi-y%c9%99ni-microsoft-teams-platformasini-nec%c9%99-az%c9%99rbaycan-dilin%c9%99-nec%c9%99-d%c9%99yis%c9%99-bil%c9%99rsiniz",
        "img": "https://celt.az/wp-content/uploads/2020/11/ldl5ZftacF.jpg"
    },
    {
        "path": "internet-uz%c9%99rind%c9%99-olan-m%c9%99hdudiyy%c9%99t-goturulur",
        "img": "https://celt.az/wp-content/uploads/2020/11/Best-ISP-Internet-Service-Provider-1024x576.jpg"
    },
    {
        "path": "dovl%c9%99t-proqrami-c%c9%99rciv%c9%99sind%c9%99-xaricd%c9%99-doktorantura-t%c9%99hsili-2021-ci-il-yaz-semestri-ucun-s%c9%99n%c9%99d-q%c9%99bulu-elan-olunur",
        "img": "https://celt.az/wp-content/uploads/2020/11/OtRBEiLvM5.jpg"
    },
    {
        "path": "dovl%c9%99t-proqrami-c%c9%99rciv%c9%99sind%c9%99-xaricd%c9%99-doktorantura-s%c9%99viyy%c9%99si-uzr%c9%99-t%c9%99hsili-ucun-prioritet-ixtisaslar-hansilardir",
        "img": "https://celt.az/wp-content/uploads/2020/11/EgUEOoAW8D.jpg"
    },
    {
        "path": "international-university-in-geneva",
        "img": "https://celt.az/wp-content/uploads/2020/11/c.jpg"
    },
    {
        "path": "sokoladlar-olk%c9%99sind%c9%99-ali-t%c9%99hsil",
        "img": "https://celt.az/wp-content/uploads/2020/11/Switzerland-1170x630-1-1024x551.jpg"
    },
    {
        "path": "aciq-m%c9%99kanda-maska-taxmayanlar-100-manat-m%c9%99bl%c9%99gind%c9%99-c%c9%99rim%c9%99l%c9%99n%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2020/11/woman-wearing-mask.original-1024x604.jpg"
    },
    {
        "path": "curtin-universityaustralia",
        "img": "https://celt.az/wp-content/uploads/2020/11/curtin-university-australia-1024x683.jpeg"
    },
    {
        "path": "koronavirus-pandemiyasi-dovru-bits%c9%99-d%c9%99-hibrid-distant-t%c9%99hsil-t%c9%99hsil-davam-ed%c9%99c%c9%99kmi",
        "img": "https://celt.az/wp-content/uploads/2020/11/EDgTK2JIFN.jpg"
    },
    {
        "path": "miqkiml%c9%99r-%c9%99lav%c9%99-yerl%c9%99sdirm%c9%99d%c9%99-istirak-ed%c9%99-bil%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2020/11/mN0Pr5YliV.jpg"
    },
    {
        "path": "k%c9%99lb%c9%99c%c9%99r%c9%99-biz-qayitdiq",
        "img": "https://celt.az/wp-content/uploads/2020/11/127196472_3590886680968256_3144418510508696233_n.jpg"
    },
    {
        "path": "s%c9%99f%c9%99rb%c9%99rliy%c9%99-c%c9%99lb-edil%c9%99n-t%c9%99l%c9%99b%c9%99l%c9%99rl%c9%99-bagli-q%c9%99rar-q%c9%99bul-edildi",
        "img": "https://celt.az/wp-content/uploads/2020/11/Azerbaijan-Military-October-22-2020-e1603709200824-1024x676.jpg"
    },
    {
        "path": "virtual-m%c9%99kt%c9%99bd%c9%99-mu%c9%99llim-mu%c9%99llimi-%c9%99v%c9%99z-ed%c9%99-bil%c9%99rmi",
        "img": "https://celt.az/wp-content/uploads/2020/11/online-class-120520-01-1140x660-1-1024x593.jpg"
    },
    {
        "path": "virtual-m%c9%99kt%c9%99b-v%c9%99-onlayn-t%c9%99dris-mu%c9%99lliml%c9%99r-ucun-m%c9%99cburidir",
        "img": "https://celt.az/wp-content/uploads/2020/11/oI4so2vwF9.jpg"
    },
    {
        "path": "turkiy%c9%99d%c9%99-t%c9%99hsil-alanlarin-n%c9%99z%c9%99rin%c9%99-imtahanlar-n%c9%99-zaman-keciril%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2020/11/crnWPtQJFB-1.jpg"
    },
    {
        "path": "elektron-jurnal-v%c9%99-onlayn-qiym%c9%99tl%c9%99ndirm%c9%99-yenilikl%c9%99rin-biz%c9%99-n%c9%99-v%c9%99d-ed%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2020/11/gTW5eJAf6P.jpg"
    },
    {
        "path": "diqq%c9%99tsinif-jurnalinin-yazilmasi-il%c9%99-bagli-yeni-t%c9%99limatlar",
        "img": "https://celt.az/wp-content/uploads/2020/11/y1s7AdNDBK.jpg"
    },
    {
        "path": "xaricd%c9%99-t%c9%99hsil-almaq-ist%c9%99y%c9%99nl%c9%99r-n%c9%99l%c9%99ri-bilm%c9%99lidirl%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2020/11/1GR7iItNuP.jpg"
    },
    {
        "path": "qiym%c9%99tl%c9%99ndirm%c9%99-vasit%c9%99l%c9%99rinin-yoxlanilmasina-dair-hazirliq-kurslarina-qeydiyyat-davam-edir",
        "img": "https://celt.az/wp-content/uploads/2020/11/XHsnmZ8kjC.jpg"
    },
    {
        "path": "inkluziv-t%c9%99hsil%c9%99-dair-yenilik",
        "img": "https://celt.az/wp-content/uploads/2020/11/HHKxyGlxxu.jpg"
    },
    {
        "path": "qiyabi-ikinci-bakalavr-t%c9%99hsili-alan-s%c9%99xs-magistratura-imtahaninda-istirak-ed%c9%99-bil%c9%99rmi",
        "img": "https://celt.az/wp-content/uploads/2020/11/L5Yn4624cZ.jpg"
    },
    {
        "path": "koronavirusu-m%c9%99hv-ed%c9%99n-cay-yeni-k%c9%99sf",
        "img": "https://celt.az/wp-content/uploads/2020/11/zLDe96jkz7.jpg"
    },
    {
        "path": "nostrifikasiya-arayislarini-agentliy%c9%99-getm%c9%99d%c9%99n-nec%c9%99-%c9%99ld%c9%99-etm%c9%99k-olar",
        "img": "https://celt.az/wp-content/uploads/2020/11/RWLm0IAXIC.jpg"
    },
    {
        "path": "a-level-yoxsa-ib-hansini-secm%c9%99li",
        "img": "https://celt.az/wp-content/uploads/2020/11/ib-or-a-levels_2_002-1024x375.jpg"
    },
    {
        "path": "iubh-university-of-applied-sciences-2",
        "img": "https://celt.az/wp-content/uploads/2020/11/IUBH-4.jpg"
    },
    {
        "path": "q%c9%99bul-imtahaninda-istirak-ed%c9%99c%c9%99k-abituriyentl%c9%99r-ucun-hazirliq-prosesi-nec%c9%99-olmalidir",
        "img": "https://celt.az/wp-content/uploads/2020/11/58uNs445g1.jpg"
    },
    {
        "path": "mu%c9%99llim-dovl%c9%99t-qulluqcusu-olarsa-hansi-ustunluk-v%c9%99-t%c9%99minat-%c9%99ld%c9%99-edir",
        "img": "https://celt.az/wp-content/uploads/2020/11/83SuR474g3.jpg"
    },
    {
        "path": "az%c9%99rbaycanda-distant-t%c9%99hsilin-mudd%c9%99ti-uzadildi",
        "img": "https://celt.az/wp-content/uploads/2020/11/4zFNmlz1Vs.jpg"
    },
    {
        "path": "mu%c9%99lliml%c9%99rin-sertifikasiya-imtahanin-musahib%c9%99-m%c9%99rh%c9%99l%c9%99si-hansi-meyarlar-%c9%99sasinda-keciril%c9%99c%c9%99kdir",
        "img": "https://celt.az/wp-content/uploads/2020/12/THFCsdIBOV.jpg"
    },
    {
        "path": "mu%c9%99lliml%c9%99rin-sertifikasiya-imtahanlari-baslayir-vacib-qadagalar",
        "img": "https://celt.az/wp-content/uploads/2020/12/blJXPzoLLC.jpg"
    },
    {
        "path": "mu%c9%99lliml%c9%99rin-is%c9%99-q%c9%99bulunun-musahib%c9%99-m%c9%99rh%c9%99l%c9%99sin%c9%99-start-verilir",
        "img": "https://celt.az/wp-content/uploads/2020/12/ehHHni6zYD.jpg"
    },
    {
        "path": "jacobs-university",
        "img": "https://celt.az/wp-content/uploads/2020/12/da257706d4d787d140a967b4225ab753_XL.jpg"
    },
    {
        "path": "t%c9%99l%c9%99b%c9%99l%c9%99rin-n%c9%99z%c9%99rin%c9%99",
        "img": "https://celt.az/wp-content/uploads/2020/12/i9mIST2xk9.jpg"
    },
    {
        "path": "eksternat-imtahanlarina-s%c9%99n%c9%99d-q%c9%99bulu-davam-edir",
        "img": "https://celt.az/wp-content/uploads/2020/12/9TM0EbZtUL.jpg"
    },
    {
        "path": 60119,
        "img": "https://celt.az/wp-content/uploads/2020/12/1415172753_vernazza-vernacca-gorod-italy-1024x640.jpg"
    },
    {
        "path": "florida-polytechnic-university",
        "img": "https://celt.az/wp-content/uploads/2020/12/t_1519323678725_name_os_women_at_florida_polytechnic_20180222_scaled-1024x576.jpg"
    },
    {
        "path": "bu-s%c9%99xsl%c9%99r-d%c9%99-miq-in-onlayn-musahib%c9%99-m%c9%99rh%c9%99l%c9%99sind%c9%99-istirak-ed%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2020/12/azedu.az_vakansiya_442-1024x681.jpg"
    },
    {
        "path": "mu%c9%99lliml%c9%99rin-m%c9%99zuniyy%c9%99t-maasi-nec%c9%99-hesablanir",
        "img": "https://celt.az/wp-content/uploads/2020/12/gt06mDJ40I.jpg"
    },
    {
        "path": "hansi-sinifl%c9%99rd%c9%99-d%c9%99rsd%c9%99nk%c9%99nar-m%c9%99sg%c9%99l%c9%99l%c9%99r-kecirilm%c9%99y%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2020/12/BVrutHt3v9.jpg"
    },
    {
        "path": "m%c9%99kt%c9%99b%c9%99hazirliq-qruplarinda-d%c9%99rs-dey%c9%99n-mu%c9%99lliml%c9%99rin-maasi-nec%c9%99-hesablanir",
        "img": "https://celt.az/wp-content/uploads/2020/12/WiuWuALRPm.jpg"
    },
    {
        "path": "xususi-karantin-rejimi-t%c9%99tbiq-edilir",
        "img": "https://celt.az/wp-content/uploads/2020/12/9b641-1592564082.jpg"
    },
    {
        "path": "h%c9%99rbi-xidm%c9%99td%c9%99-olan-mu%c9%99llim-namiz%c9%99dl%c9%99r-yenid%c9%99n-imtahan-ver%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2020/12/11794-1597735662.jpg"
    },
    {
        "path": "ikili-diplom-proqrami-uzr%c9%99-%c9%99lav%c9%99-q%c9%99bul-son-tarix-31-dekabr",
        "img": "https://celt.az/wp-content/uploads/2020/12/DMFgsnkjG0.jpg"
    },
    {
        "path": "xaricd%c9%99-dovl%c9%99t-proqrami-c%c9%99rsiv%c9%99sind%c9%99-t%c9%99hsil-ucun-s%c9%99n%c9%99d-q%c9%99bulu",
        "img": "https://celt.az/wp-content/uploads/2020/12/xhXoGU1jmU.jpg"
    },
    {
        "path": "namiz%c9%99d-miq-in-musahib%c9%99-m%c9%99rh%c9%99l%c9%99sin%c9%99-geciks%c9%99-n%c9%99-bas-ver%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2020/12/01-22.08.17-15-1024x645.jpg"
    },
    {
        "path": "%d0%b2%d0%bf%d0%b5%d1%87%d0%b0%d1%82%d0%bb%d0%b5%d0%bd%d0%b8%d1%8f-%d1%88%d0%ba%d0%be%d0%bb%d1%8c%d0%bd%d0%b8%d1%86%d1%8b-9%d0%b3%d0%be-%d0%ba%d0%bb%d0%b0%d1%81%d1%81%d0%b0-%d0%be-%d0%ba%d0%b0%d0%bd",
        "img": "https://celt.az/wp-content/uploads/2020/12/130820749_205436407830903_4227521327070741614_n.jpg"
    },
    {
        "path": "diaqnostik-qiym%c9%99tl%c9%99ndirm%c9%99d%c9%99-istirak-ed%c9%99c%c9%99k-mu%c9%99lliml%c9%99r-ucun-test-numun%c9%99l%c9%99rini-t%c9%99qdim-edirik",
        "img": "https://celt.az/wp-content/uploads/2020/12/412239988.jpg"
    },
    {
        "path": "%c9%99vv%c9%99lki-ill%c9%99rin-m%c9%99zunlari-hansi-formada-imtahan-ver%c9%99c%c9%99kl%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2020/12/modern.az_imtahan_977_876.jpg"
    },
    {
        "path": "x%c9%99st%c9%99likl%c9%99rd%c9%99n-qorunmaq-ucun-immun-sistemimizi-nec%c9%99-gucl%c9%99ndir%c9%99-bil%c9%99rik",
        "img": "https://celt.az/wp-content/uploads/2020/12/4deVF71Vr1.jpg"
    },
    {
        "path": "marshall-universiteti",
        "img": "https://celt.az/wp-content/uploads/2020/12/Picture-0-1024x693-1.jpg"
    },
    {
        "path": "s%c9%99f%c9%99rb%c9%99rlik-s%c9%99b%c9%99bi-il%c9%99-s%c9%99n%c9%99d-q%c9%99bulunda-istirak-ed%c9%99-bilm%c9%99y%c9%99n-subbakalvlarin-n%c9%99z%c9%99rin%c9%99",
        "img": "https://celt.az/wp-content/uploads/2020/08/f18af724-2112-4bb5-8f51-39c1acf39127_610.jpg"
    },
    {
        "path": "virtual-m%c9%99kt%c9%99bd%c9%99-f%c9%99nl%c9%99r-uzr%c9%99-qiym%c9%99tl%c9%99ndirm%c9%99-t%c9%99skil-olunacaq",
        "img": "https://celt.az/wp-content/uploads/2020/12/azedu.az_01-02.04.20-01_2_115-1024x576.jpg"
    },
    {
        "path": "mu%c9%99llim-namiz%c9%99dl%c9%99r-miq-in-onlayn-musahib%c9%99-m%c9%99rh%c9%99l%c9%99sin%c9%99-nec%c9%99-qosula-bil%c9%99rl%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2020/12/azedu.az_vakansiya_442-1024x681.jpg"
    },
    {
        "path": "turizm-sah%c9%99sind%c9%99-t%c9%99hsil",
        "img": "https://celt.az/wp-content/uploads/2020/12/IMG_1685-1024x683.jpg"
    },
    {
        "path": "magistraturaya-q%c9%99bul-imtahani-onlayn-keciril%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2020/12/UjrUmyqHt4.jpg"
    },
    {
        "path": "turk-aliml%c9%99rinin-covid-19-tovsiy%c9%99l%c9%99ri-bol-maye-q%c9%99bul-etm%c9%99liyik",
        "img": "https://celt.az/wp-content/uploads/2020/12/co4qBLexE6.jpg"
    },
    {
        "path": "new-york-film-akademiyasi",
        "img": "https://celt.az/wp-content/uploads/2020/12/new-york-film-academy-los-angeles-campus-8-1024x576.jpg"
    },
    {
        "path": "qiym%c9%99tl%c9%99ndirm%c9%99-imtahaninda-istirak-ed%c9%99c%c9%99k-sagirdl%c9%99r-n%c9%99-etm%c9%99lidirl%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2020/12/gVdgQlDlkS.jpg"
    },
    {
        "path": "milli-qiym%c9%99tl%c9%99ndirm%c9%99-imtahani-hansi-saatlarda-t%c9%99skil-edil%c9%99c%c9%99k-v%c9%99-bu-imtahanin-m%c9%99qs%c9%99di-n%c9%99dir",
        "img": "https://celt.az/wp-content/uploads/2020/12/UNr32DbCev.jpg"
    },
    {
        "path": "virtual-m%c9%99kt%c9%99bl%c9%99-bagli-z%c9%99ruri-prosedurlar",
        "img": "https://celt.az/wp-content/uploads/2020/12/OOpfJUxJAi.jpg"
    },
    {
        "path": "t%c9%99hsil-nazirliyi-turkiy%c9%99d%c9%99-t%c9%99til-hansi-mudd%c9%99t%c9%99-davam-ed%c9%99c%c9%99k-v%c9%99-d%c9%99rsl%c9%99r-hansi-tarixd%c9%99n-etibar%c9%99n-baslayacaq",
        "img": "https://celt.az/wp-content/uploads/2020/12/AxPilpuJrA.jpg"
    },
    {
        "path": "az%c9%99rbaycanda-hansi-ixtisaslar-iii-qrupa-%c9%99lav%c9%99-edildi",
        "img": "https://celt.az/wp-content/uploads/2020/12/FpPHG4Zqrw.jpg"
    },
    {
        "path": "eu-business-school-2",
        "img": "https://celt.az/wp-content/uploads/2020/12/Munich_Campus_1_basic-1024x530.jpeg"
    },
    {
        "path": "fransanin-v%c9%99-dunyanin-%c9%99n-boyuk-biznes-universitetl%c9%99rind%c9%99n-birind%c9%99-t%c9%99hsil-kedge-business-school",
        "img": "https://celt.az/wp-content/uploads/2020/12/107289_kedge_1180x290-1024x252.jpg"
    },
    {
        "path": "t%c9%99hsil-institutu-mut%c9%99x%c9%99ssis-vakansiyasi-elan-edir",
        "img": "https://celt.az/wp-content/uploads/2020/12/vector-job-vacancy-banner-1024x717.jpg"
    },
    {
        "path": "9-v%c9%9911-ci-sinif-buraxilis-imtahani-modelind%c9%99-hansi-d%c9%99yisiklik-var",
        "img": "https://celt.az/wp-content/uploads/2020/12/XIRc36B6Yw.jpg"
    },
    {
        "path": "hansi-s%c9%99xsl%c9%99r-d%c9%99-ali-m%c9%99kt%c9%99bl%c9%99r%c9%99-imtahansiz-q%c9%99bul-oluna-bil%c9%99c%c9%99kl%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2020/12/OHuG1EotDX.jpg"
    },
    {
        "path": "2021-ild%c9%99-q%c9%99bul-imtahanindad%c9%99yisiklik-edil%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2021/01/xm4wNTqTid.jpg"
    },
    {
        "path": "hansi-mu%c9%99lliml%c9%99r-t%c9%99dris-ilinin-sonunad%c9%99k-m%c9%99kt%c9%99bl%c9%99rd%c9%99-isl%c9%99y%c9%99-bil%c9%99c%c9%99kl%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2021/01/7R4TGnubzd.jpg"
    },
    {
        "path": "saint-louis-universiteti",
        "img": "https://celt.az/wp-content/uploads/2021/01/saint.jpeg"
    },
    {
        "path": "mu%c9%99lliml%c9%99rin-sertiikatlasdirilmasi-hansi-tarixd%c9%99-keciril%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2021/01/Y54sP2vqUf.jpg"
    },
    {
        "path": "sertiikatlasdirmadan-kecmyn-mullimin-ishden-cixarilacaq",
        "img": "https://celt.az/wp-content/uploads/2021/01/SXD9QL9j7x.jpg"
    },
    {
        "path": "cind%c9%99-dovl%c9%99t-proqrami-il%c9%99-t%c9%99hsil-almaq-imkani-muraci%c9%99t-ucun-son-1-gun",
        "img": "https://celt.az/wp-content/uploads/2021/01/GIMiENhc9o.jpg"
    },
    {
        "path": "dunyanin-ve-isvecrenin-qonaqperverlik-sahesinde-en-boyuk-tehsil-qurumu-swiss-education-group-seg",
        "img": "https://celt.az/wp-content/uploads/2021/01/LOGOS-compiled-1024x546.png"
    },
    {
        "path": "budapest-metropolitan-universiteti",
        "img": "https://celt.az/wp-content/uploads/2021/01/d5dcd38390e57994ad7b091c8b7aa6c45d074c61-1024x576.jpg"
    },
    {
        "path": "distant-t%c9%99hsilin-m%c9%99kt%c9%99blil%c9%99r%c9%99-t%c9%99hluk%c9%99li-t%c9%99siri-n%c9%99l%c9%99rdir",
        "img": "https://celt.az/wp-content/uploads/2021/01/sharon-mccutcheon-x28N9KpQasg-unsplash-1024x683.jpg"
    },
    {
        "path": "sagirdl%c9%99rin-yerd%c9%99yism%c9%99si-il%c9%99-bagli-sorgularin-tamamlanmasi-yanvarin-13-d%c9%99k-davam-ed%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2021/01/421534926.jpg"
    },
    {
        "path": "dovl%c9%99t-proqrami-c%c9%99rciv%c9%99sind%c9%99-xaricd%c9%99-doktorantura-t%c9%99hsili-uzr%c9%99-s%c9%99n%c9%99d-q%c9%99bulu-aparilir",
        "img": "https://celt.az/wp-content/uploads/2021/01/azedu.az_44-31_1574413335_160.jpg"
    },
    {
        "path": "university-of-the-pacific",
        "img": "https://celt.az/wp-content/uploads/2021/01/6001_wdqgkeuq-1024x768.png"
    },
    {
        "path": "t%c9%99l%c9%99b%c9%99l%c9%99rin-t%c9%99qaudl%c9%99ri-nec%c9%99-hesablanir-kiml%c9%99r-t%c9%99qaud-ala-bil%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2021/01/5YbEWtmHoj.jpg"
    },
    {
        "path": "hansi-xarici-universitetl%c9%99rin-diplomlari-olk%c9%99mizd%c9%99-taninir",
        "img": "https://celt.az/wp-content/uploads/2021/01/roR8RcoKLS.jpg"
    },
    {
        "path": "t%c9%99l%c9%99b%c9%99l%c9%99rin-kocurulm%c9%99si-il%c9%99-bagli-%c9%99n-cox-veril%c9%99n-suallar-cavablar",
        "img": "https://celt.az/wp-content/uploads/2021/01/9TQfA5Ldx0.jpg"
    },
    {
        "path": "az%c9%99rbaycanda-karantin-rejimi-1-aprel-tarixin%c9%99d%c9%99k-uzadildi-hansi-sah%c9%99l%c9%99r-18-yanvar-tarixind%c9%99n-f%c9%99aliyy%c9%99t-gost%c9%99r%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2020/08/unnamed.jpg"
    },
    {
        "path": "sinif-jurnali-nec%c9%99-yazilmalidir-t%c9%99hsil-nazirliyinin-t%c9%99qdim-etdiyi-numun%c9%99l%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2020/07/aae7b420-1819-4527-a780-48726757a17e.jpg"
    },
    {
        "path": "q%c9%99bul-imtahaninda-%c9%99sas-xarici-dild%c9%99n-basqa-dig%c9%99r-bir-dild%c9%99n-d%c9%99-imtahan-verm%c9%99k-olarmi-sual-cavab",
        "img": "https://celt.az/wp-content/uploads/2021/01/WcHB0g4eIx.jpg"
    },
    {
        "path": "az%c9%99rbaycanda-m%c9%99kt%c9%99bl%c9%99r-n%c9%99-vaxtdan-f%c9%99aliyy%c9%99t%c9%99-baslayir",
        "img": "https://celt.az/wp-content/uploads/2021/01/nrDzO1EIiD.jpg"
    },
    {
        "path": "hansi-sagirdl%c9%99r%c9%99-0-qiym%c9%99ti-yazilacaq",
        "img": "https://celt.az/wp-content/uploads/2021/01/172eb0cbdb606f812c8733d6beab228f.jpg"
    },
    {
        "path": "turkiy%c9%99d%c9%99-dovl%c9%99t-proqrami-c%c9%99civ%c9%99sind%c9%99-doktorantura-t%c9%99hsili-almaq-imkani",
        "img": "https://celt.az/wp-content/uploads/2021/01/umoNBmVRcY.jpg"
    },
    {
        "path": "az%c9%99rbaycanda-fevralin-1-d%c9%99n-t%c9%99hsil-mu%c9%99ssis%c9%99l%c9%99ri-%c9%99yani-t%c9%99dris%c9%99-baslayir",
        "img": "https://celt.az/wp-content/uploads/2021/01/GL8PJ8OUYZ.jpg"
    },
    {
        "path": "azt%c9%99minatli-t%c9%99l%c9%99b%c9%99l%c9%99r%c9%99-t%c9%99hsil-kreditl%c9%99rinin-faizsiz-verilm%c9%99si-n%c9%99z%c9%99rd%c9%99-tutulur",
        "img": "https://celt.az/wp-content/uploads/2021/01/jn8SFARIkU.jpg"
    },
    {
        "path": "mu%c9%99lliml%c9%99rin-is%c9%99-q%c9%99bulu-uzr%c9%99-novb%c9%99ti-onlayn-musahib%c9%99l%c9%99r%c9%99-hansi-tarixd%c9%99n-start-veril%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2021/01/0115.jpg"
    },
    {
        "path": "belcikada-ali-t%c9%99hsil",
        "img": "https://celt.az/wp-content/uploads/2021/01/depositphotos_100006590-stock-photo-brussels-pinned-on-a-map.jpg"
    },
    {
        "path": "ruminiyanin-xarici-isl%c9%99r-nazirliyi-2021-2022-ci-t%c9%99dris-ili-ucun-t%c9%99qaud-proqrami-elan-edir",
        "img": "https://celt.az/wp-content/uploads/2021/01/UTy1P6PlG6.jpg"
    },
    {
        "path": "ovladini-mektebe-gondermeyecek-valideynler-ne-etmelidir",
        "img": "https://celt.az/wp-content/uploads/2021/01/8sWLCuvy0a.jpg"
    },
    {
        "path": "dovl%c9%99t-qulluqcusu-olmaq-ist%c9%99y%c9%99nl%c9%99rin-n%c9%99z%c9%99rin%c9%99-onlara-hansi-%c9%99lav%c9%99-f%c9%99aliyy%c9%99tl%c9%99r-qadagandir",
        "img": "https://celt.az/wp-content/uploads/2021/01/Pr4rHmVu1F.jpg"
    },
    {
        "path": "koronavirus-covid-19-pandemiyasi-dovrund%c9%99-sertifikasiya-keciril%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2021/01/SXD9QL9j7x.jpg"
    },
    {
        "path": "qanunla-kiml%c9%99r-direktor-ola-bilm%c9%99z",
        "img": "https://celt.az/wp-content/uploads/2021/01/leFtXoGL8B.jpg"
    },
    {
        "path": "2021-ci-ild%c9%99-ali-m%c9%99kt%c9%99bl%c9%99r%c9%99-q%c9%99bul-imtahani-suallari-hansi-formada-olacaqdir",
        "img": "https://celt.az/wp-content/uploads/2021/02/X73tXVrnni.jpg"
    },
    {
        "path": "m%c9%99kt%c9%99bl%c9%99rd%c9%99-v%c9%99-universitetl%c9%99rd%c9%99-t%c9%99hsil-n%c9%99-vaxtdan-v%c9%99-nec%c9%99-b%c9%99rpa-olunur",
        "img": "https://celt.az/wp-content/uploads/2021/02/002-25.08.2020-2-1024x683.jpg"
    },
    {
        "path": "m%c9%99kt%c9%99b%c9%99-g%c9%99lm%c9%99y%c9%99n-sagirdl%c9%99r%c9%99-onlayn-d%c9%99rs-keciril%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2021/02/82d76f0c47edcf3bf5556fd28053f77791244809-22041-ku-soe-howdistancelearningevolved.png"
    },
    {
        "path": "berlin-school-of-business-innovation-bsbi",
        "img": "https://celt.az/wp-content/uploads/2021/02/9f255f1f3fbb60e27b03168a16391af6.png"
    },
    {
        "path": "m%c9%99kt%c9%99bl%c9%99r-acildi-d%c9%99rs-c%c9%99dv%c9%99ll%c9%99ri-hansi-formada-hazirlanmalidir",
        "img": "https://celt.az/wp-content/uploads/2020/11/y1s7AdNDBK.jpg"
    },
    {
        "path": "fevralin-15-d%c9%99-hansi-universitetl%c9%99rd%c9%99-%c9%99yani-t%c9%99drisi-b%c9%99rpa-ed%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2021/02/BJph6pmZJj.jpg"
    },
    {
        "path": "az%c9%99rbaycanda-15-fevral-tarixind%c9%99n-hansi-universitetl%c9%99rd%c9%99-d%c9%99rsl%c9%99r-b%c9%99rpa-olunmayacaq",
        "img": "https://celt.az/wp-content/uploads/2020/09/UYM8nqgfNY.jpg"
    },
    {
        "path": "hal-hazirda-t%c9%99hsill%c9%99-bagli-hansi-qadagalar-var",
        "img": "https://celt.az/wp-content/uploads/2021/02/Fjh2lmrbWG.jpg"
    },
    {
        "path": "%c9%99n-yaxsi-olk%c9%99l%c9%99rin-2021-2022-t%c9%99dris-ili-ucun-t%c9%99qdim-etdiyi-t%c9%99qaud-proqramlari",
        "img": "https://celt.az/wp-content/uploads/2021/02/KnAU6JQLNK.jpg"
    },
    {
        "path": "azerbaycanda-magistratura-imtahanina-qeydiyyat-ne-vaxt-bashlayir-imtahanlar-odenishsiz-olacaq",
        "img": "https://celt.az/wp-content/uploads/2021/02/Q61wyEvd9U.jpg"
    },
    {
        "path": "bakalavr-dim-nin-s%c9%99xsi-kabinetind%c9%99-parolunu-unutduqda-n%c9%99-etm%c9%99lidir",
        "img": "https://celt.az/wp-content/uploads/2021/02/2myoAT8UoR.jpg"
    },
    {
        "path": "buraxilis-imtahanlarinda-azrbaycan-dili-fnnindn-hansi-movzulara-uygun-suallar-olacaq",
        "img": "https://celt.az/wp-content/uploads/2021/02/587-1024x567.png"
    },
    {
        "path": "buraxilis-imtahanlarinda-ingilis-dili-f%c9%99nnind%c9%99n-hansi-movzulara-uygun-suallar-olacaq",
        "img": "https://celt.az/wp-content/uploads/2021/02/9874-1024x674.png"
    },
    {
        "path": "turkiy%c9%99-burslari-proqramina-s%c9%99n%c9%99d-q%c9%99bulu-davam-edir",
        "img": "https://celt.az/wp-content/uploads/2021/02/turk-1024x680.png"
    },
    {
        "path": "macaristanin-nazirliyi-v%c9%99-bmt-nin-t%c9%99skilati-il%c9%99-birg%c9%99-2021-2022-ci-t%c9%99dris-ili-ucun-t%c9%99qaud-proqrami",
        "img": "https://celt.az/wp-content/uploads/2021/02/UTyWewp6bu.jpg"
    },
    {
        "path": "abituriyentl%c9%99r-iki-ixtisas-qrupu-uzr%c9%99-imtahan-ver%c9%99-bil%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2021/02/87-1024x680.png"
    },
    {
        "path": "buraxilis-v%c9%99-q%c9%99bul-imtahanlarinin-n%c9%99tic%c9%99l%c9%99ri-nec%c9%99-il-mudd%c9%99td%c9%99-quvv%c9%99d%c9%99-qalir",
        "img": "https://celt.az/wp-content/uploads/2021/02/97-1024x685.png"
    },
    {
        "path": "az%c9%99rbaycanla-turkiy%c9%99-arasinda-t%c9%99hsil%c9%99-dair-protokol-imzalanib-protokol-n%c9%99l%c9%99r-v%c9%99d-edir",
        "img": "https://celt.az/wp-content/uploads/2021/02/153688084_242614980872312_5992063727295707297_o-1024x630.jpg"
    },
    {
        "path": "b%c9%99rb%c9%99rl%c9%99r-v%c9%99-sac-ustalari-t%c9%99hsil-nazirliyind%c9%99n-oz-pes%c9%99l%c9%99ri-uzr%c9%99-nec%c9%99-diplom-%c9%99ld%c9%99-ed%c9%99-bil%c9%99rl%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2021/02/hmm-1024x673.png"
    },
    {
        "path": "az%c9%99rbaycanda-%c9%99yani-d%c9%99rsl%c9%99rin-sayi-artirildi",
        "img": "https://celt.az/wp-content/uploads/2021/02/8475-1024x681.png"
    },
    {
        "path": "gisma-business-school",
        "img": "https://celt.az/wp-content/uploads/2021/02/gisma-business-school.jpg"
    },
    {
        "path": "doktoranturaya-q%c9%99bulda-kiml%c9%99r%c9%99-h%c9%99rbi-xidm%c9%99td%c9%99n-3-ay-mohl%c9%99t-veril%c9%99c%c9%99k-yeni-qanun",
        "img": "https://celt.az/wp-content/uploads/2021/02/qWxCUHvwxZ.jpg"
    },
    {
        "path": "bu-ild%c9%99n-bavariya-m%c9%99kt%c9%99bl%c9%99rind%c9%99-islam-d%c9%99rsl%c9%99ri-keciril%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2021/02/fl0ToJpRWz.jpg"
    },
    {
        "path": "mu%c9%99lliml%c9%99r-v%c9%99-ozunu-inkisaf-etdirm%c9%99k-ist%c9%99y%c9%99n-h%c9%99r-k%c9%99s-ucun-faydali-internet-platformalar",
        "img": "https://celt.az/wp-content/uploads/2021/02/57832-1024x682.png"
    },
    {
        "path": "dovl%c9%99t-proqrami-c%c9%99civ%c9%99sind%c9%99-taylandda-t%c9%99hsil-almaq-imkani",
        "img": "https://celt.az/wp-content/uploads/2021/02/yHEmBuCyhc.jpg"
    },
    {
        "path": "xarici-diplomlarin-az%c9%99rbaycanda-taninmasi-ucun-hansi-s%c9%99n%c9%99dl%c9%99r-t%c9%99l%c9%99b-olunur-bakalavr",
        "img": "https://celt.az/wp-content/uploads/2021/02/diploma-1024x569.png"
    },
    {
        "path": "diplomu-leqallasdirmaq-n%c9%99dir-v%c9%99-n%c9%99y%c9%99-lazimdir",
        "img": "https://celt.az/wp-content/uploads/2021/02/leqal-1024x681.png"
    },
    {
        "path": "t%c9%99l%c9%99b%c9%99l%c9%99r-niy%c9%99-yoga-il%c9%99-m%c9%99sgul-olmalidirlar",
        "img": "https://celt.az/wp-content/uploads/2021/02/yoga.png"
    },
    {
        "path": "nec%c9%99nci-sinif-sagirdl%c9%99ri-ucun-milli-qiym%c9%99tl%c9%99ndirm%c9%99-keciril%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2021/03/206268_xt7y81ji6o.jpg"
    },
    {
        "path": "magistr-imtahan-suallarinda-m%c9%99ntiq-yoxsa-uygunsuzluq",
        "img": "https://celt.az/wp-content/uploads/2021/03/97214-1024x675.png"
    },
    {
        "path": "musahibdn-ugurla-kecn-hansi-mullimlr-ikiqat-emekhaqqi-verilck",
        "img": "https://celt.az/wp-content/uploads/2021/03/39235600_204060150467134_7817384397673332736_n.jpg"
    },
    {
        "path": "nazirlikd%c9%99n-d%c9%99rsl%c9%99rin-dayandirilacagi-il%c9%99-bagli-m%c9%99lumat",
        "img": "https://celt.az/wp-content/uploads/2021/03/24137e23-34df-3baa-aee7-9fe754507bee_850.jpg"
    },
    {
        "path": "szczecin-d%c9%99nizcilik-universiteti",
        "img": "https://celt.az/wp-content/uploads/2021/03/Akademia-Dormitory-from-above-1024x577.jpg"
    },
    {
        "path": "yeni-q%c9%99rarburaxilis-imtahanlarinda-hansi-d%c9%99yisiklik-edildi",
        "img": "https://celt.az/wp-content/uploads/2021/03/0d7ff6e9-3544-4fba-b30d-cd6798791ccf.jpg"
    },
    {
        "path": "kiml%c9%99r-novb%c9%99ti-magistratura-imtahanlarinda-istirak-ed%c9%99-bil%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2021/03/student-question-1024x671.png"
    },
    {
        "path": "t%c9%99l%c9%99b%c9%99l%c9%99r%c9%99-akademik-m%c9%99zuniyy%c9%99t-nec%c9%99-v%c9%99-hansi-hallarda-veril%c9%99-bil%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2021/03/s1bUKFu38l.jpg"
    },
    {
        "path": "t%c9%99hsilli-qadin-mill%c9%99tin-goz%c9%99l-g%c9%99l%c9%99c%c9%99y%c9%99dir",
        "img": "https://celt.az/wp-content/uploads/2021/03/qadinn-1024x678.png"
    },
    {
        "path": "brno-texniki-universiteti",
        "img": "https://celt.az/wp-content/uploads/2021/03/Sign_of_Brno_University_of_Technology_at_building_in_Královo_Pole_Brno_Brno-City_District-1024x683.jpg"
    },
    {
        "path": "ilk-imtahandan-kec%c9%99n-s%c9%99xsl%c9%99r%c9%99-h%c9%99rbi-xidm%c9%99td%c9%99n-uc-ayliq-mohl%c9%99t-veril%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2021/03/student-aze-1024x675.png"
    },
    {
        "path": "universitetl%c9%99r%c9%99-q%c9%99bul-plani-artirildi",
        "img": "https://celt.az/wp-content/uploads/2021/03/STUDENTS-1024x682.png"
    },
    {
        "path": "anisha-ossd-d%c9%99-3600-kmd%c9%99n-uzaq-isl%c9%99yir",
        "img": "https://celt.az/wp-content/uploads/2021/03/159835970_1759014310946268_2685959500536035617_n.jpg"
    },
    {
        "path": "miq-uzr%c9%99-apelyasiya-prosesi-hansi-tarixd%c9%99-keciril%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2021/03/d10507b852d05cf0a2811cf88f663f8a-1024x602.jpg"
    },
    {
        "path": "collegium-civitas",
        "img": "https://celt.az/wp-content/uploads/2021/03/Collegium-Civitas-Mural-1024x683.jpeg"
    },
    {
        "path": "11-illik-t%c9%99hsil-s%c9%99viyy%c9%99si-uzr%c9%99-buraxilis-imtahanlarinin-rayon-v%c9%99-s%c9%99h%c9%99rl%c9%99r-uzr%c9%99-vaxti-aciqlandi",
        "img": "https://celt.az/wp-content/uploads/2021/03/student-exam.png"
    },
    {
        "path": "aprelin-1-d%c9%99n-yeni-s%c9%99xsiyy%c9%99t-v%c9%99siq%c9%99si-il%c9%99-turkiy%c9%99y%c9%99-s%c9%99f%c9%99r-etm%c9%99k-mumkun-olacaqdir",
        "img": "https://celt.az/wp-content/uploads/2021/03/turkey-1024x683.png"
    },
    {
        "path": "akkreditasiya-prosesl%c9%99rin%c9%99-keyfiyy%c9%99t-t%c9%99minati-uzr%c9%99-ekspertl%c9%99r-d%c9%99v%c9%99t-edilir",
        "img": "https://celt.az/wp-content/uploads/2021/03/axtaririq-1024x680.png"
    },
    {
        "path": "az%c9%99rbaycanda-t%c9%99hsil-nec%c9%99-davam-etdiril%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2021/03/aztibb.az_1514361475_02-17.10.16_432-1024x628.jpg"
    },
    {
        "path": "abituriyentl%c9%99r-elektron-%c9%99riz%c9%99ni-nec%c9%99-doldurmalidirlar",
        "img": "https://celt.az/wp-content/uploads/2021/03/student1-1024x687.png"
    },
    {
        "path": "xususi-karantin-rejiminin-mudd%c9%99ti-uzadilib",
        "img": "https://celt.az/wp-content/uploads/2020/12/9b641-1592564082.jpg"
    },
    {
        "path": "bayram-gunl%c9%99rind%c9%99-h%c9%99r-hansi-bir-s%c9%99rtl%c9%99sdirm%c9%99-t%c9%99dbirl%c9%99ri-h%c9%99yata-kecirilm%c9%99y%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2021/03/Ac9Ue5CbcH.jpg"
    },
    {
        "path": "usaqlar-vaxtindan-%c9%99vv%c9%99l-m%c9%99kt%c9%99b%c9%99-nec%c9%99-q%c9%99bul-ola-bil%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2021/03/183qVTTnUq.jpg"
    },
    {
        "path": "university-college-dublin-ucd",
        "img": "https://celt.az/wp-content/uploads/2021/03/dublin.jpeg"
    },
    {
        "path": "abituryent-buraxilis-imtahani-ucun-yoxsa-q%c9%99bul-imtahani-ucun-qeydiyyatdan-kecm%c9%99lidir",
        "img": "https://celt.az/wp-content/uploads/2021/03/94516433-46f7-44a8-8a2e-7692d866d18c.jpg"
    },
    {
        "path": "abituryent-ot%c9%99n-ilki-s%c9%99xsi-kabinet-il%c9%99-imtahandan-yenid%c9%99n-qeydiyyatdan-kec%c9%99-bil%c9%99rmi",
        "img": "https://celt.az/wp-content/uploads/2021/03/student-exam-1-1024x686.png"
    },
    {
        "path": "nikola-tesla-haqqinda-n%c9%99-bilirsiniz",
        "img": "https://celt.az/wp-content/uploads/2021/03/b7jo70wcdL-1.jpg"
    },
    {
        "path": "mu%c9%99lliml%c9%99rin-is%c9%99-q%c9%99bulu-miq-imtahanlarinda-hansi-d%c9%99yisiklikl%c9%99r-var",
        "img": "https://celt.az/wp-content/uploads/2021/03/411802327.jpg"
    },
    {
        "path": "xaricd-thsil-alan-tlblrin-hansi-sbbdn-diplomlari-taninmir",
        "img": "https://celt.az/wp-content/uploads/2021/03/student-question-1024x671.png"
    },
    {
        "path": "hansi-xarici-universitetl%c9%99rin-baki-filiallarinin-diplomlari-taninmadi",
        "img": "https://celt.az/wp-content/uploads/2021/03/istockphoto-483937378-640x640-1.jpg"
    },
    {
        "path": "qdansk-texnologiya-universiteti",
        "img": "https://celt.az/wp-content/uploads/2021/03/experience-gdansk-university-technology-poland-senzie-18da927578d89ad1262087b2d908f540-1024x692.jpeg"
    },
    {
        "path": "mu%c9%99lliml%c9%99rin-is-vaxti-nec%c9%99-t%c9%99nziml%c9%99nir",
        "img": "https://celt.az/wp-content/uploads/2021/03/fi6CNKfw4e.jpg"
    },
    {
        "path": "mu%c9%99lliml%c9%99rin-m%c9%99zuniyy%c9%99t-maasi-nec%c9%99-hesablanir-2",
        "img": "https://celt.az/wp-content/uploads/2021/03/EjDinnnw9U.jpg"
    },
    {
        "path": "3-yasindan-t%c9%99hsil-yaponiyanin-qeyri-adi-t%c9%99hsil-sistemi",
        "img": "https://celt.az/wp-content/uploads/2021/03/N0DhGNzMs0.jpg"
    },
    {
        "path": "az%c9%99rbaycanda-bakalavr-pill%c9%99sind%c9%99-yeni-ixtisas-yaradildi",
        "img": "https://celt.az/wp-content/uploads/2021/03/digital-media.png"
    },
    {
        "path": "azrbaycanda-magistratura-sviyysi-uzr-yeni-ixtisaslar-yaradildi",
        "img": "https://celt.az/wp-content/uploads/2021/02/diploma-1024x569.png"
    },
    {
        "path": "west-bohemia-universiteti",
        "img": "https://celt.az/wp-content/uploads/2021/04/Alba-5-1024x1024.jpeg"
    },
    {
        "path": "mu%c9%99lliml%c9%99rin-is%c9%99-q%c9%99bulu-uzr%c9%99-onlayn-qeydiyyata-start-verilir",
        "img": "https://celt.az/wp-content/uploads/2021/04/5959fe6d-a27b-36b5-8b86-e7b60f761564_850.jpg"
    },
    {
        "path": "fransanin-t%c9%99hsil-sistemind%c9%99-qeyri-adi-qaydalar",
        "img": "https://celt.az/wp-content/uploads/2021/04/DERS1.png"
    },
    {
        "path": "hazirda-isl%c9%99y%c9%99n-mu%c9%99lliml%c9%99r-bu-il-miq-d%c9%99-istirak-ed%c9%99-bil%c9%99rl%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2021/03/411802327.jpg"
    },
    {
        "path": "kiml%c9%99r-pedaqoji-f%c9%99aliyy%c9%99tl%c9%99-m%c9%99sgul-ola-bilm%c9%99z",
        "img": "https://celt.az/wp-content/uploads/2021/04/QUESTION.png"
    },
    {
        "path": "hansi-universitetl%c9%99rin-diplomlari-az%c9%99rbaycanda-taninmir",
        "img": "https://celt.az/wp-content/uploads/2021/04/diplom-1.png"
    },
    {
        "path": "xorvatiyada-amerika-thsili-rit-rochester-institute-of-technology-croatia",
        "img": "https://celt.az/wp-content/uploads/2021/04/areuhqatqrx-1024x576.jpeg"
    },
    {
        "path": "fransada-biznes-t%c9%99hsili-ieseg-school-of-management",
        "img": "https://celt.az/wp-content/uploads/2021/04/IESEG-Promenade_de_l_Arche_-_Zoom_logo-Barbara_GROSSMANN-30-1200px-1024x650.jpg"
    },
    {
        "path": "mu%c9%99llim-olmaq-ist%c9%99y%c9%99nl%c9%99r-miq-d%c9%99-kec%c9%99n-ilki-s%c9%99xsi-hesabda-istifad%c9%99-ed%c9%99-bil%c9%99rl%c9%99rmi",
        "img": "https://celt.az/wp-content/uploads/2020/11/1GR7iItNuP.jpg"
    },
    {
        "path": "buraxilis-imtahanina-istirak-etm%c9%99y%c9%99n-abituriyentl%c9%99rin-aqib%c9%99ti-nec%c9%99-olacaq",
        "img": "https://celt.az/wp-content/uploads/2021/04/student11-1024x685.png"
    },
    {
        "path": "abituriyentl%c9%99r%c9%99-muraci%c9%99t-s%c9%99n%c9%99dl%c9%99ri-qaydaya-salin",
        "img": "https://celt.az/wp-content/uploads/2020/12/i9mIST2xk9.jpg"
    },
    {
        "path": "xaricd%c9%99-alinan-distant-t%c9%99hsil-diplomlari-az%c9%99rbaycanda-taninacaqmi",
        "img": "https://celt.az/wp-content/uploads/2021/04/study_college_190612-1024x576.jpg"
    },
    {
        "path": "mu%c9%99lliml%c9%99rin-hansi-huquqlari-vardir",
        "img": "https://celt.az/wp-content/uploads/2021/04/345118-1024x682.jpg"
    },
    {
        "path": "hansi-mu%c9%99lliml%c9%99r-yerd%c9%99yism%c9%99-ed%c9%99-bilm%c9%99zl%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2021/04/ElvKwvDV2O.jpg"
    },
    {
        "path": "i-sinf%c9%99-sagird-q%c9%99bulu-nec%c9%99-aparilacaq-sual-cavab",
        "img": "https://celt.az/wp-content/uploads/2021/04/CHILDREN-1024x687.png"
    },
    {
        "path": "birinci-sinf%c9%99-onlayn-qeydiyyat-baslayir",
        "img": "https://celt.az/wp-content/uploads/2021/04/i-sinif-1024x683.png"
    },
    {
        "path": "az%c9%99rbaycanda-bakalavriat-pill%c9%99sinin-3-d%c9%99r%c9%99c%c9%99si-olacaq",
        "img": "https://celt.az/wp-content/uploads/2021/04/student-1-1024x686.png"
    },
    {
        "path": "m%c9%99kt%c9%99bdaxili-qiym%c9%99tl%c9%99ndirilm%c9%99-n%c9%99-vaxt-keciril%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2021/04/school-exam-1024x677.png"
    },
    {
        "path": "x-sinif-sagirdi-hansi-halda-buraxilis-imtahaninda-istirak-ed%c9%99-bil%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2021/04/student2-1024x628.png"
    },
    {
        "path": "hindistanda-muxt%c9%99lif-ixtisaslar-uzr%c9%99-t%c9%99qaud-proqrami-elani",
        "img": "https://celt.az/wp-content/uploads/2021/04/STUDY-INDIA-1024x691.png"
    },
    {
        "path": "mu%c9%99lliml%c9%99r%c9%99-od%c9%99nissiz-m%c9%99zuniyy%c9%99tl%c9%99r-hansi-halda-veril%c9%99-bil%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2021/04/question-1-1024x675.png"
    },
    {
        "path": "t%c9%99hsil-iscil%c9%99rinin-%c9%99m%c9%99k-haqqisi-nec%c9%99-v%c9%99-kim-t%c9%99r%c9%99find%c9%99n-t%c9%99yin-edilir",
        "img": "https://celt.az/wp-content/uploads/2021/04/teacher-2-1024x698.png"
    },
    {
        "path": "buraxilis-imtahanlarinin-n%c9%99tic%c9%99l%c9%99ri-n%c9%99-vaxt-elan-edil%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2021/04/1592443551999225468-1024x683.jpg"
    },
    {
        "path": "magistraturaya-t%c9%99krar-q%c9%99bul-baslayir",
        "img": "https://celt.az/wp-content/uploads/2021/04/15967939671856128240_1000x669.jpg"
    },
    {
        "path": "musahib%c9%99l%c9%99rd%c9%99-ugur-qazanmayan-mu%c9%99lliml%c9%99r-ucun-t%c9%99krar-musahib%c9%99-n%c9%99-vaxt-olacaq",
        "img": "https://celt.az/wp-content/uploads/2021/03/97214-1024x675.png"
    },
    {
        "path": "sloveniyada-doktorantura-uzr%c9%99-t%c9%99qaud-proqrami",
        "img": "https://celt.az/wp-content/uploads/2021/04/ygd-1024x686.png"
    },
    {
        "path": "isvecr%c9%99d%c9%99-t%c9%99hsil-sistemi-v%c9%99-onun-oz%c9%99llikl%c9%99ri",
        "img": "https://celt.az/wp-content/uploads/2021/04/7Be4WaBOIi.jpg"
    },
    {
        "path": "30-aprel-2009-adna-terrorizm%c9%99-m%c9%99ruz-qalan-dunya-universitetl%c9%99ri",
        "img": "https://celt.az/wp-content/uploads/2021/04/1588222572_1c3346f6-6eff-4b5b-8f18-8f883e1cd3cc_640.jpg"
    },
    {
        "path": "umumi-9-illik-orta-t%c9%99hsil-s%c9%99viyy%c9%99si-uzr%c9%99-buraxilis-imtahanin-modeli-v%c9%99-m%c9%99zmunu-nec%c9%99dir-test-numun%c9%99l%c9%99ri",
        "img": "https://celt.az/wp-content/uploads/2021/04/buraxlis-3-1024x682.jpg"
    },
    {
        "path": "9-cu-v%c9%99-11-ci-sinif-sagirdl%c9%99ri-ucun-az%c9%99rbaycan-diliimtahaninin-tarixi-mu%c9%99yy%c9%99n-edilib",
        "img": "https://celt.az/wp-content/uploads/2021/04/buraxilis-2-1024x682.jpg"
    },
    {
        "path": "buraxilis-imtahanlarinin-n%c9%99tic%c9%99l%c9%99ri-aciqlandi",
        "img": "https://celt.az/wp-content/uploads/2020/12/modern.az_imtahan_977_876.jpg"
    },
    {
        "path": "t%c9%99bibl%c9%99rin-hokmdari-ibn-sina",
        "img": "https://celt.az/wp-content/uploads/2021/05/ibnsinaandsports1.jpg"
    },
    {
        "path": "az%c9%99rbaycanda-1-sagirdi-olan-m%c9%99kt%c9%99b-var",
        "img": "https://celt.az/wp-content/uploads/2020/11/oI4so2vwF9.jpg"
    },
    {
        "path": "s%c9%99hid-ail%c9%99l%c9%99rinin-uzvl%c9%99ri-ali-m%c9%99kt%c9%99bl%c9%99r%c9%99-q%c9%99bul-imtahaninda-istirak-ucun-v%c9%99sait-od%c9%99m%c9%99y%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2021/03/STUDENTS-1024x682.png"
    },
    {
        "path": "az%c9%99rbaycanda-17-may-tarixind%c9%99n-%c9%99yani-t%c9%99dris-qism%c9%99n-b%c9%99rpa-olunur",
        "img": "https://celt.az/wp-content/uploads/2021/02/587-1024x567.png"
    },
    {
        "path": "yaponiyada-uc-t%c9%99hsil-pill%c9%99si-uzr%c9%99-t%c9%99qaud-proqrami-elani",
        "img": "https://celt.az/wp-content/uploads/2021/05/XKZ9dlueII.jpg"
    },
    {
        "path": "university-of-humanities-and-economics",
        "img": "https://celt.az/wp-content/uploads/2021/05/University-of-Humanities-and-Economics-in-Lodz-1024x576.png"
    },
    {
        "path": "yeni-t%c9%99dris-ilind%c9%99n-m%c9%99kt%c9%99bl%c9%99rd%c9%99-hansi-yeni-qayda-olacaq",
        "img": "https://celt.az/wp-content/uploads/2021/05/student-school-1024x675.png"
    },
    {
        "path": "bu-son-semestr-hansi-t%c9%99l%c9%99b%c9%99l%c9%99r-t%c9%99hsil-haqqindan-azad-olunacaq",
        "img": "https://celt.az/wp-content/uploads/2021/05/student1-55-1024x684.png"
    },
    {
        "path": "dilin-yaranmasinda-s%c9%99sl%c9%99rin-roluna-dair-t%c9%99tdqiqatlar-aparilib",
        "img": "https://celt.az/wp-content/uploads/2021/05/voice-1024x675.png"
    },
    {
        "path": "polsada-yapon-keyfiyy%c9%99tli-texnologiya-t%c9%99hsili-polish-japanese-academy-of-information-technology",
        "img": "https://celt.az/wp-content/uploads/2021/05/Logo_EN_1-1024x536.jpg"
    },
    {
        "path": "magistraturaya-q%c9%99bul-imtahani-n%c9%99-vaxt-keciril%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2021/04/buraxlis-3-1024x682.jpg"
    },
    {
        "path": "dunyanin-%c9%99n-istedadli-usaqlari-v%c9%99-onlarin-h%c9%99yat-hekay%c9%99l%c9%99ri",
        "img": "https://celt.az/wp-content/uploads/2021/05/usaq-1024x680.png"
    },
    {
        "path": "insan-yuxu-zamani-t%c9%99hsil-ala-bil%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2021/05/yuxu-1024x592.png"
    },
    {
        "path": "t%c9%99l%c9%99b%c9%99-kocurm%c9%99l%c9%99ri-il%c9%99-bagli-%c9%99n-cox-veril%c9%99n-suallar-v%c9%99-cavablari",
        "img": "https://celt.az/wp-content/uploads/2020/09/RmjCAEhQnf.jpg"
    },
    {
        "path": "ali-m%c9%99kt%c9%99bl%c9%99r%c9%99-v%c9%99-kollecl%c9%99r%c9%99-s%c9%99n%c9%99d-q%c9%99bulu-basladi",
        "img": "https://celt.az/wp-content/uploads/2021/04/buraxilis-2-1024x682.jpg"
    },
    {
        "path": "bu-il-ali-t%c9%99hsil-mu%c9%99ssis%c9%99l%c9%99rin%c9%99-q%c9%99bul-imtahanlari-n%c9%99-vaxt-keciril%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2021/04/student11-1024x685.png"
    },
    {
        "path": "rezidenturaya-s%c9%99n%c9%99d-q%c9%99bulu-elan-edildi",
        "img": "https://celt.az/wp-content/uploads/2020/09/UYM8nqgfNY.jpg"
    },
    {
        "path": "az%c9%99rbaycanda-jurnalistika-ixtisasina-q%c9%99bul-olmaq-ist%c9%99y%c9%99nl%c9%99rin-n%c9%99z%c9%99rin%c9%99",
        "img": "https://celt.az/wp-content/uploads/2020/06/mQkJrfGqB0.jpg"
    },
    {
        "path": "virtual-m%c9%99kt%c9%99bd%c9%99-milli-qiym%c9%99tl%c9%99ndirm%c9%99d%c9%99-nec%c9%99-istirak-etm%c9%99k-olar",
        "img": "https://celt.az/wp-content/uploads/2021/04/school-exam-1024x677.png"
    },
    {
        "path": "magistraturaya-q%c9%99bul-imtahaninin-ii-c%c9%99hdinin-n%c9%99tic%c9%99l%c9%99ri-aciqlanib",
        "img": "https://celt.az/wp-content/uploads/2021/04/buraxilis-2-1024x682.jpg"
    },
    {
        "path": "covid-19-virusuna-yoluxan-s%c9%99xsin-immuniteti-ill%c9%99rl%c9%99-qala-bil%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2021/05/fimmu-11-01979-g001-1024x775.jpg"
    },
    {
        "path": "2021-ci-il-uzr%c9%99-akademik-f%c9%99nl%c9%99rin-qlobal-reytinqi-aciqlanib",
        "img": "https://celt.az/wp-content/uploads/2021/05/5d79e9925429bee92e104d33.jpeg"
    },
    {
        "path": "mu%c9%99llim-namiz%c9%99din-%c9%99m%c9%99k-muqavil%c9%99sind%c9%99n-imtina-veril%c9%99rs%c9%99-o-n%c9%99-etm%c9%99lidir",
        "img": "https://celt.az/wp-content/uploads/2021/04/5959fe6d-a27b-36b5-8b86-e7b60f761564_850.jpg"
    },
    {
        "path": "f%c9%99rql%c9%99nm%c9%99-attestati-v%c9%99-is-t%c9%99crub%c9%99si-universitet%c9%99-q%c9%99bul-zamani-n%c9%99-rol-oynayir",
        "img": "https://celt.az/wp-content/uploads/2021/05/94516433-46f7-44a8-8a2e-7692d866d18c.jpg"
    },
    {
        "path": "d%c9%99rsl%c9%99rd%c9%99-ugurlu-n%c9%99tic%c9%99-%c9%99ld%c9%99-etm%c9%99yin-5-yolu",
        "img": "https://celt.az/wp-content/uploads/2021/05/14.png"
    },
    {
        "path": "xaricd%c9%99-t%c9%99hsil-almaq-ucun-hansi-beyn%c9%99lxalq-sertifikati-almaliyam",
        "img": "https://celt.az/wp-content/uploads/2021/05/zxrMP3kDAB.jpg"
    },
    {
        "path": "oyr%c9%99nm%c9%99yin-usulu-s%c9%99n-oyr%c9%99n%c9%99rk%c9%99n-hansi-usulu-t%c9%99tbiq-etm%c9%99lis%c9%99n",
        "img": "https://celt.az/wp-content/uploads/2021/05/learn-1024x685.png"
    },
    {
        "path": "xaricd%c9%99-phd-t%c9%99hsilin-karyeraniza-t%c9%99siri-realliqlar-v%c9%99-mifl%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2021/05/phd-1024x682.png"
    },
    {
        "path": "mu%c9%99lliml%c9%99rin-is%c9%99-q%c9%99bulu-uzr%c9%99-musabiq%c9%99y%c9%99-start-verilir",
        "img": "https://celt.az/wp-content/uploads/2021/06/04-01.06.21-01-1024x841.jpg"
    },
    {
        "path": "mu%c9%99lliml%c9%99r-hansi-pedaqoji-bacariqlara-sahib-olmalidirlar",
        "img": "https://celt.az/wp-content/uploads/2021/03/aztibb.az_1514361475_02-17.10.16_432-1024x628.jpg"
    },
    {
        "path": "orta-ixtisas-t%c9%99hsili-mu%c9%99ssis%c9%99l%c9%99rind%c9%99-t%c9%99hsilalanlarin-biliyi-yeni-qaydada-qiym%c9%99tl%c9%99ndiril%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2021/04/student2-1024x628.png"
    },
    {
        "path": "fontys-university-of-applied-sciences-2",
        "img": "https://celt.az/wp-content/uploads/2021/06/Fontys-University-of-Applied-Sciences.png"
    },
    {
        "path": "ali-m%c9%99kt%c9%99bl%c9%99r%c9%99-q%c9%99bul-imtahanlarinin-tarixl%c9%99ri-elan-olundu",
        "img": "https://celt.az/wp-content/uploads/2021/06/3d352bcc-4ebb-34a6-982a-fb65ee62e17d_850.jpg"
    },
    {
        "path": "yaxsi-mu%c9%99llim-olmaq-ucun-n%c9%99-etm%c9%99k-lazimdir",
        "img": "https://celt.az/wp-content/uploads/2021/06/Albert-Einstein-Teacher-2560x1600-1-1024x640.jpg"
    },
    {
        "path": "sevil%c9%99n-mu%c9%99llim-olmaq-ucun-n%c9%99-etm%c9%99lisiz",
        "img": "https://celt.az/wp-content/uploads/2021/06/545657488-primary-school-pupil-blackboard-school-child-teacher.jpg"
    },
    {
        "path": "toronto-da-texnoloji-tehsil",
        "img": "https://celt.az/wp-content/uploads/2021/06/canada-toronto-city-3290310-1024x634.jpg"
    },
    {
        "path": "hansi-t%c9%99l%c9%99b%c9%99l%c9%99r%c9%99-sosial-kreditl%c9%99r-veril%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2021/06/student-12-1024x683.png"
    },
    {
        "path": "mu%c9%99lliml%c9%99rin-sertifikatlasdirilmasi-il%c9%99-bagli-proqram-hazirlanacaq",
        "img": "https://celt.az/wp-content/uploads/2021/06/teacher1-1024x680.png"
    },
    {
        "path": "dunyada-elmin-inkisafinda-mus%c9%99lmanlarin-rolu",
        "img": "https://celt.az/wp-content/uploads/2021/06/824561-1024x576.jpg"
    },
    {
        "path": "aliml%c9%99r-beyin%c9%99-bilikl%c9%99r-yolunu-tapiblar",
        "img": "https://celt.az/wp-content/uploads/2021/06/AdobeStock_226212015-1597x898-1-1024x576.jpeg"
    },
    {
        "path": "az%c9%99rbaycanda-t%c9%99hsil-t%c9%99l%c9%99b%c9%99-krediti-fondu-yaradildi",
        "img": "https://celt.az/wp-content/uploads/2021/06/AdobeStock_144016078-scaled-e1617625594977-2048x1152-1-1024x576.jpeg"
    },
    {
        "path": "schellhammer-business-school-sbs",
        "img": "https://celt.az/wp-content/uploads/2021/06/Schellhammer-Business-School-Spain-Marbella-17-1024x503.jpeg"
    },
    {
        "path": "mu%c9%99llim-namiz%c9%99dl%c9%99r-hansi-m%c9%99rk%c9%99zl%c9%99rd%c9%99-imtahan-ver%c9%99c%c9%99kl%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2021/04/5959fe6d-a27b-36b5-8b86-e7b60f761564_850.jpg"
    },
    {
        "path": "ali-m%c9%99kt%c9%99bl%c9%99r%c9%99-q%c9%99bul-imtahanlarinin-vaxti-d%c9%99yisdi",
        "img": "https://celt.az/wp-content/uploads/2021/04/student11-1024x685.png"
    },
    {
        "path": "mu%c9%99lliml%c9%99rin-sertifikasiyasinda-hansi-movzulardan-suallar-salinacaq",
        "img": "https://celt.az/wp-content/uploads/2021/06/muellim12.jpg.jpg"
    },
    {
        "path": "bu-il-kecid-ballari-asagi-salina-bil%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2021/02/8475-1024x681.png"
    },
    {
        "path": "iyulun-1-d%c9%99-v%c9%99-2-d%c9%99-dim-t%c9%99r%c9%99find%c9%99n-hansi-imtahanlar-keciril%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2021/06/1.jpg"
    },
    {
        "path": "magistraturaya-ixtisas-secimi-n%c9%99-vaxt-baslayir",
        "img": "https://celt.az/wp-content/uploads/2021/06/KnAU6JQLNK.jpg"
    },
    {
        "path": "isvecr%c9%99nin-imi-international-management-institute-haqqinda-m%c9%99lumat",
        "img": "https://celt.az/wp-content/uploads/2021/06/page_1-850x1024.jpg"
    },
    {
        "path": "turkiy%c9%99nin-8-universitetind%c9%99-10-yeni-fakult%c9%99-yaradildi",
        "img": "https://celt.az/wp-content/uploads/2021/06/1608964171_Study-in-Turkey.jpg"
    },
    {
        "path": "t%c9%99l%c9%99b%c9%99l%c9%99r-n%c9%99-vaxt-t%c9%99hsil-kreditini-od%c9%99m%c9%99k-m%c9%99cburiyy%c9%99tind%c9%99-qalmayacaq",
        "img": "https://celt.az/wp-content/uploads/2021/06/image-1024x576.jpg"
    },
    {
        "path": "1-v%c9%99-ya-2-iyul-2021-ci-il-tarixl%c9%99rind%c9%99-keciril%c9%99c%c9%99k-imtahanlarda-istirak-ed%c9%99n-abituriyentl%c9%99rin-n%c9%99z%c9%99rin%c9%99",
        "img": "https://celt.az/wp-content/uploads/2021/06/1595592921_bb.jpg"
    },
    {
        "path": "artiq-dusunc%c9%99l%c9%99ri-d%c9%99-oxumaq-mumkun-ola-bil%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2021/06/machine-learning-vs-deep-learning.png"
    },
    {
        "path": "bu-gund%c9%99n-magistraturaya-q%c9%99bul-ucun-proqram-v%c9%99-ixtisas-secimi-baslayir",
        "img": "https://celt.az/wp-content/uploads/2021/03/slider-1-1-2-1024x480.jpg"
    },
    {
        "path": "q%c9%99bul-imtahaninda-ugur-qazanmaq-ucun-10-strategiya",
        "img": "https://celt.az/wp-content/uploads/2021/06/5146342-1024x600.jpg"
    },
    {
        "path": "mu%c9%99lliml%c9%99rin-etik-davranis-standartlari",
        "img": "https://celt.az/wp-content/uploads/2021/06/teacher-property-plant-and-teaching.jpg"
    },
    {
        "path": "xarici-olk%c9%99l%c9%99rd%c9%99-ali-t%c9%99hsil-alanlar-az%c9%99rbaycanda-magistraturaya-s%c9%99n%c9%99d-verm%c9%99k-ucun-n%c9%99-etm%c9%99lidir",
        "img": "https://celt.az/wp-content/uploads/2021/06/student-master.png"
    },
    {
        "path": "q%c9%99bul-imtahaninda-0-bal-toplayan-abituriyent-t%c9%99l%c9%99b%c9%99-ola-bil%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2021/03/94516433-46f7-44a8-8a2e-7692d866d18c.jpg"
    },
    {
        "path": "miq-imtahaninda-artiq-n%c9%99y%c9%99-icaz%c9%99-verilm%c9%99y%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2021/06/imtahan-muellim.jpg"
    },
    {
        "path": "kollec-m%c9%99zunlari-abituriyent-kimi-musabiq%c9%99d%c9%99-istirak-ed%c9%99-bil%c9%99rl%c9%99r",
        "img": "https://celt.az/wp-content/uploads/2021/06/imtahanyeni-768x403-1.png"
    },
    {
        "path": "doktoranturaya-q%c9%99bul-imtahanlarinda-xarici-dil-uzr%c9%99-kecid-bali-n%c9%99-q%c9%99d%c9%99rdir",
        "img": "https://celt.az/wp-content/uploads/2021/02/diploma-1024x569.png"
    },
    {
        "path": "az%c9%99rbaycan-dili-f%c9%99nni-uzr%c9%99-yenid%c9%99n-imtahan-kecir%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2021/06/1583043213_1492451084-0637.jpeg"
    },
    {
        "path": "xaricd%c9%99-t%c9%99hsili-nec%c9%99-planlamaq-lazimdir",
        "img": "https://celt.az/wp-content/uploads/2021/07/study-abroad-1024x680.png"
    },
    {
        "path": "hansi-s%c9%99xsl%c9%99r-qanunla-attestasiyadan-azad-olunub",
        "img": "https://celt.az/wp-content/uploads/2021/07/QUES-1024x613.png"
    },
    {
        "path": "ali-t%c9%99hsil-mu%c9%99ssis%c9%99l%c9%99rin%c9%99-q%c9%99bul-olmaq-ist%c9%99y%c9%99n-subbakalavrlarin-qeydiyyati-baslayir",
        "img": "https://celt.az/wp-content/uploads/2021/04/question-1-1024x675.png"
    },
    {
        "path": "turkiy%c9%99-az%c9%99rbaycan-pes%c9%99-t%c9%99hsili-qurumu-yaradilir",
        "img": "https://celt.az/wp-content/uploads/2021/07/Aliyev-erdogan-05-03-21-1024x683-1.jpg"
    },
    {
        "path": "dovl%c9%99t-sifarisi-%c9%99sasinda-magistratura-uzr%c9%99-t%c9%99hsil-alacaq-t%c9%99l%c9%99b%c9%99l%c9%99rin-sayi-aciqlandi",
        "img": "https://celt.az/wp-content/uploads/2021/03/A9207535-1024x682.jpg"
    },
    {
        "path": "bu-il-ali-t%c9%99hsil-mu%c9%99ssis%c9%99l%c9%99rin%c9%99-q%c9%99bul-imtahanlarinin-ikinci-m%c9%99rh%c9%99l%c9%99si-bir-d%c9%99f%c9%99-keciril%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2021/02/X73tXVrnni.jpg"
    },
    {
        "path": "xarici-diplom-v%c9%99-sertifikatlarin-olk%c9%99mizd%c9%99-taninmasi-n%c9%99-dem%c9%99kdir",
        "img": "https://celt.az/wp-content/uploads/2021/02/leqal-1024x681.png"
    },
    {
        "path": "q%c9%99bul-imtahanlari-respublikanin-hansis%c9%99h%c9%99r-v%c9%99-rayonunda-t%c9%99skil-olunacaq",
        "img": "https://celt.az/wp-content/uploads/2021/03/0d7ff6e9-3544-4fba-b30d-cd6798791ccf.jpg"
    },
    {
        "path": "magistraturaya-q%c9%99bul-olanlarin-universitetl%c9%99r%c9%99-elektron-qeydiyyat-baslayir",
        "img": "https://celt.az/wp-content/uploads/2021/03/s1bUKFu38l.jpg"
    },
    {
        "path": "az%c9%99rbaycanda-t%c9%99hsilin-inkisafi-fondu-yaradildi",
        "img": "https://celt.az/wp-content/uploads/2021/07/ilham-eliyev.jpg"
    },
    {
        "path": "ii-ixtisas-qrupu-uzr%c9%99-16-mind%c9%99n-cox-abituriyent-q%c9%99bul-imtahani-ver%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2021/07/IMTAHAN-AZE.jpg"
    },
    {
        "path": "d%c9%99rsl%c9%99r-nec%c9%99-t%c9%99skil-edilm%c9%99lidir",
        "img": "https://celt.az/wp-content/uploads/2021/07/lesson-time.jpg"
    },
    {
        "path": "t%c9%99hsil-kreditl%c9%99ri-hansi-formada-veril%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2021/07/CREDIT-STUDENT.png"
    },
    {
        "path": "i-v%c9%99-iv-qrup-uzr%c9%99-q%c9%99bul-imtahanlarinin-tarixl%c9%99ri-aciqlandi",
        "img": "https://celt.az/wp-content/uploads/2021/07/STUD-1.jpg"
    },
    {
        "path": "ixtisas-secimi-%c9%99riz%c9%99sinin-doldurulmasi-v%c9%99-t%c9%99sdiqi-qaydalari",
        "img": "https://celt.az/wp-content/uploads/2021/06/3d352bcc-4ebb-34a6-982a-fb65ee62e17d_850.jpg"
    },
    {
        "path": "dunyada-t%c9%99hsill%c9%99-bagli-bilm%c9%99dikl%c9%99rimiz",
        "img": "https://celt.az/wp-content/uploads/2021/07/dddd.jpg.jpeg"
    },
    {
        "path": "yuks%c9%99k-z%c9%99kali-insanlarin-%c9%99sas-xususiyy%c9%99tl%c9%99ri-n%c9%99l%c9%99rdir",
        "img": "https://celt.az/wp-content/uploads/2021/07/hgb.jpeg"
    },
    {
        "path": "az%c9%99rbaycanda-hansi-t%c9%99l%c9%99b%c9%99l%c9%99rin-kocurulm%c9%99sin%c9%99-yol-verilmir",
        "img": "https://celt.az/wp-content/uploads/2021/07/t23.jpeg"
    },
    {
        "path": "butun-t%c9%99l%c9%99b%c9%99l%c9%99r-peyv%c9%99nd-olunmalidir-r%c9%99smi",
        "img": "https://celt.az/wp-content/uploads/2021/06/imtahanyeni-768x403-1.png"
    },
    {
        "path": "operativ-q%c9%99rargah-hansi-yeni-q%c9%99rarlar-q%c9%99bul-etdi",
        "img": "https://celt.az/wp-content/uploads/2021/04/ElvKwvDV2O.jpg"
    },
    {
        "path": "bu-gun-keciril%c9%99n-test-imtahanin-duzgun-cavablari",
        "img": "https://celt.az/wp-content/uploads/2021/06/1583043213_1492451084-0637.jpeg"
    },
    {
        "path": "t%c9%99hsil-krediti-alan-t%c9%99l%c9%99b%c9%99l%c9%99r%c9%99-hansi-halda-guz%c9%99stl%c9%99r-t%c9%99tbiq-edil%c9%99c%c9%99k",
        "img": "https://celt.az/wp-content/uploads/2021/07/YTDS.jpeg"
    },
    {
        "path": "politecnico-di-torino-polytechnic-university-of-turin",
        "img": "https://celt.az/wp-content/uploads/2021/07/iu-2.jpeg"
    },
    {
        "path": "lund-university",
        "img": "https://celt.az/wp-content/uploads/2021/07/Lund-1024x683.jpg"
    },
    {
        "path": "myos-n%c9%99dir",
        "img": "https://celt.az/wp-content/uploads/2021/12/entrance-exam.jpeg"
    },
    {
        "path": "myos-n%c9%99dir-son-zamanlarda-k%c9%99skin-rezonans-doguran-bir-yenilik-myos-imtahanlari-haqqinda-narahat-olan-valideyn-v%c9%99-t%c9%99l%c9%99b%c9%99l%c9%99ri-m%c9%99lumatlandirmaq-ist%c9%99yirik",
        "img": "https://celt.az/wp-content/uploads/2021/06/Resim1-1.png"
    },
    {
        "path": "yos-v%c9%99-myos-imtahanlarin-f%c9%99rql%c9%99ri",
        "img": "https://celt.az/wp-content/uploads/2021/12/what-is-yos-2.jpg"
    },
    {
        "path": "toefl-imtahanindan-kecm%c9%99k-c%c9%99tindir",
        "img": "https://celt.az/wp-content/uploads/2023/10/393631791_630563965938614_5258488613989459182_n-822x1024.jpg"
    },
    {
        "path": "ielts-%c9%99-nec%c9%99-hazirlasmaq-olar",
        "img": "https://celt.az/wp-content/uploads/2023/10/ielts.jpg"
    },
    {
        "path": "oksford-universitetind%c9%99-q%c9%99bul-imtahanlari",
        "img": "https://celt.az/wp-content/uploads/2023/10/ox.jpg"
    },
    {
        "path": "ruslar-ucun-xaricd%c9%99-t%c9%99hsilin-c%c9%99tinlikl%c9%99ri-v%c9%99-h%c9%99ll-yollari",
        "img": "https://celt.az/wp-content/uploads/2023/11/russian.jpg"
    },
    {
        "path": "%d1%82%d1%80%d1%83%d0%b4%d0%bd%d0%be%d1%81%d1%82%d0%b8-%d0%bf%d0%be%d1%81%d1%82%d1%83%d0%bf%d0%bb%d0%b5%d0%bd%d0%b8%d1%8f-%d0%b7%d0%b0-%d0%b3%d1%80%d0%b0%d0%bd%d0%b8%d1%86%d1%83-%d0%b4%d0%bb%d1%8f",
        "img": "https://celt.az/wp-content/uploads/2023/11/russian.jpg"
    },
    {
        "path": "digital-sat-a-nec%c9%99-qeydiyyatdan-kecm%c9%99k-olar",
        "img": "https://celt.az/wp-content/uploads/2023/11/IMG_0620-1024x1019.jpg"
    },
    {
        "path": "sat-balinizi-nec%c9%99-qaldira-bil%c9%99rsiniz",
        "img": "https://celt.az/wp-content/uploads/2023/11/image_6487327-1022x1024.jpg"
    },
    {
        "path": "2024-abs-boyuk-britaniya-v%c9%99-kanadada-muh%c9%99ndislik-t%c9%99qaudl%c9%99ri",
        "img": "https://celt.az/wp-content/uploads/2023/11/image_6483441-724x1024.jpg"
    },
    {
        "path": "universitetl%c9%99rin-bakalavr-d%c9%99r%c9%99c%c9%99sin%c9%99-q%c9%99bul-ucun-t%c9%99l%c9%99b-olunan-umumi-kurslar",
        "img": "https://celt.az/wp-content/uploads/2023/12/WhatsApp-Image-2023-12-06-at-4.27.53-PM-1024x1024.jpeg"
    },
    {
        "path": "dunya-olk%c9%99l%c9%99rinin-%c9%99n-yaxsi-muh%c9%99ndislik-universitetl%c9%99ri",
        "img": "https://celt.az/wp-content/uploads/2023/12/Minimalist-Beige-Canvas-Print-Line-Art-Gears_20231213_174854_0000-819x1024.png"
    },
    {
        "path": "toefl-a-nec%c9%99-hazirlasmaq-lazimdir",
        "img": "https://celt.az/wp-content/uploads/2023/12/20231218_152733_0000-1024x576.png"
    },
    {
        "path": "acilan-imkanlar-kanadada-t%c9%99hsil-t%c9%99crub%c9%99sinin-z%c9%99nginl%c9%99sdirilm%c9%99si",
        "img": "https://celt.az/wp-content/uploads/2023/12/image_123650291-1024x1020.jpg"
    },
    {
        "path": "niderlandda-t%c9%99hsil",
        "img": "https://celt.az/wp-content/uploads/2024/01/image_123650291.jpg"
    },
    {
        "path": "celt-yay-dus%c9%99rg%c9%99si-avropada",
        "img": "https://celt.az/wp-content/uploads/2024/02/IMG_4482-1024x563.jpg"
    },
    {
        "path": "%d0%bb%d0%b5%d1%82%d0%bd%d0%b8%d0%b9-%d0%bb%d0%b0%d0%b3%d0%b5%d1%80%d1%8c-celt-%d0%b2-%d0%b5%d0%b2%d1%80%d0%be%d0%bf%d0%b5",
        "img": "https://celt.az/wp-content/uploads/2024/02/IMG_4482-1024x563.jpg"
    },
    {
        "path": "gunumuzd%c9%99-ingilis-dilinin-vacibliyi",
        "img": "https://celt.az/wp-content/uploads/2024/03/image00001-1-1024x1024.png"
    }
]