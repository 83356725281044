import React, { Component } from 'react'
import { connect } from 'react-redux';

export class ChangeLang extends Component {
  render() {
    const {systemLangs, changeLangBtn=()=>{}} = this.props;
    // console.log(systemLangs)
    return (
      <div className='flex w-full justify-between gap-[20px] mt-[10px]'>
        {
            systemLangs.map((data, i)=>{
                return(
                    <button key={i} onClick={()=>changeLangBtn(data?.id)} className=' p-[2px_8px] bg-[var(--blue)] text-white font-[500] text-[13px] rounded-[5px] border border-solid border-[var(--blue)]'>{data?.title}</button>
                )
            })
        }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    systemLangs: state.Data.systemLangs
});
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(ChangeLang)
