import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom'
import LoginPage from './pages/LoginPage'
import AdminGraduates from './pages/AdminGraduates'
import { useDispatch, useSelector } from 'react-redux'
import AdminNav from './components/navigation/AdminNav'
import { checkLoginUser, getLang } from './actions/MainAction'
import ExamsPage from './pages/ExamsPage'
import Popup from './components/PopUp'
import LangPreparationPage from './pages/LangPreparationPage'
import StudentReviews from './pages/StudentReviews'
import FaqPage from './pages/FaqPage'
import AdminBlog from './pages/AdminBlog'
import AdminKidsEnglishType from './pages/AdminKidsEnglishType'
import AdminWhyUs from './pages/AdminWhyUs'
import ExamFaq from './pages/ExamFaq'
import AdminCompanies from './pages/AdminCompanies'
import CompanyReview from './pages/CompanyReview'
import ContentPage from './pages/ContentPage'
import AdminMainPage from './pages/AdminMainPage'
import AdminPremiumStudent from './pages/AdminPremiumStudent'
import AdminSelectedPremiumSt from './pages/AdminSelectedPremiumSt'
import AdminJson from './pages/AdminJson'
import AdminOnlineAplly from './pages/AdminOnlineAplly'
import AdminEmailCollecPage from './pages/AdminEmailCollecPage'
import AdminStudentAppliesPage from './pages/AdminStudentAppliesPage'
import BlogSEO from './pages/BlogSEO'

function App() {
  const user = useSelector(state=> state.Data.user);
  const modalData = useSelector(state=> state.Data.modalData);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  useEffect(()=>{
    document.addEventListener("wheel", (event) => {  
      if (document.activeElement.type === "number") {  
          document.activeElement.blur(); 
      }  
    }); 
    dispatch(getLang())
    if(localStorage.getItem("userToken")){
      dispatch(checkLoginUser())
      .then(resp=>{
        if(resp == "error"){
          navigate('/login')
        }
      })
    }else{
      navigate('/login')
    }
  },[]);
  // console.log(user)
  return (
    <div className='flex'>
        {user !==""? <AdminNav />:null}
        <Routes>
         
          <Route path='/login' element={<LoginPage/>} />
          {
            user?.role == "admin"?
           <>
            <Route path='/' element={<AdminMainPage/>} />
            <Route path='/exams' element={<ExamsPage/>} />
            <Route path='/lang-preperation' element={<LangPreparationPage/>} />
            <Route path='/student-reviews' element={<StudentReviews/>} />
            <Route path='/faq' element={<FaqPage/>} />
            <Route path='/blog' element={<AdminBlog/>} />
            <Route path='/kids-course' element={<AdminKidsEnglishType/>} />
            <Route path='/why-us' element={<AdminWhyUs/>} />
            <Route path='/exam-faq/:id' element={<ExamFaq/>} />
            <Route path='/companies' element={<AdminCompanies/>} />
            <Route path='/company-review/:id' element={<CompanyReview/>} />
            <Route path='/content' element={<ContentPage/>} />
            <Route path='/premium-student' element={<AdminPremiumStudent/>} />
            <Route path='/premium-student-selected' element={<AdminSelectedPremiumSt/>} />
            <Route path='/json' element={<AdminJson/>} />
            <Route path='/online-apply' element={<AdminOnlineAplly/>} />
            <Route path='/email-collect' element={<AdminEmailCollecPage/>} />
            <Route path='/student-apply' element={<AdminStudentAppliesPage/>} />
            <Route path='/blog-seo' element={<BlogSEO/>} />
           </>:null
          }
          {
            user?.role == "content"?
            <Route path='/' element={<AdminBlog/>} />:null
          }
        </Routes>
        {modalData?.position? <Popup />:null}
        
      </div>
  )
}

export default App
