import React, { Component } from 'react'
import SectionHeader from '../components/SectionHeader'
import Card1 from '../components/cards/Card1'
import { connect } from 'react-redux'
import { changeStateValue } from '../redux/MainReducer';
import { getAdminContent } from '../actions/MainAction';
export class ContentPage extends Component {
  componentDidMount=()=>{
    this.props.getAdminContent(1)
  }
    openModal=(data, lang, lang_id=1)=>{
      this.props.changeStateValue({
        name:"modalData",
        value:{
          position:true,
          id:data?.id?data?.id:'',
          property:data?.property?data?.property:'',
         
          languages:
          {
            id:lang?.id?lang?.id:'',
            value:lang?.value?lang?.value:'',
          
            lang_id:lang_id
          },
          requiredsLang: ["value"],
          requireds:["property"],
          afterCallFunction:this.props.getAdminContent,
          afterParams:[1],
          url:'admin-content'
        }
      })
    }
  render() {
    const {adminContentData} = this.props;
    // console.log(adminContentData)
    return (
        <div className='flex w-full gap-[20px] justify-between '>
     
        <div className='w-full flex flex-col container1'>
          <div>
            <SectionHeader openModal={this.openModal.bind(this,"", "", 1)} btnTitle={"Add a content"} title='Contents'/>
          </div>
          <div className='grid-full-250'>
            {
              adminContentData?.map((data, i)=>{
                return(
                  <Card1
                    key={i}
                    title={data?.translate?.value}
                    image={data?.image_full_url}
                    openModal={(id)=>this.openModal(data, data.translates.find(x=>x.lang_id==id), id)}
                    deletedUrl={`admin-content/${data?.id}`}
                    afterCallFunc={()=>this.props.getAdminContent(1)}
                  />
                )
              })
            }
            
          </div>
        </div>

      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  adminContentData: state.Data.adminContentData
});
const mapDispatchToProps = {changeStateValue, getAdminContent}
export default connect(mapStateToProps, mapDispatchToProps)(ContentPage)


