import React, { Component } from 'react'
import { changeStateValue } from '../redux/MainReducer';
import { connect } from 'react-redux';

export class DropDown extends Component {

    onChangeSelect=(e)=>{
        this.props.changeStateValue({
            name: e.target.name,
            value: e.target.value
        })
    }
  render() {
    const {options = [], name="", value="", title=""} = this.props;
    return (
        <div className='flex flex-col items-start'>
        {title !== "" ? <label htmlFor={name} className='text-[14px] txt-light-black'>{title}</label>:null }
       <select name={name} onChange={this.onChangeSelect.bind(this)} value={value} className={` w-full h-[35px] rounded-[6px] p-[5px] pl-[12px] outline-none text-[15px] bg-gray-100`}>
            <option value={""}>Select a section</option>
            {
                options.map((data, i)=>{
                    return(
                        <option key={i} value={data?.value}>{data?.label}</option>
                    )
                })
            }
       </select>
       
      </div>
    )
  }
}

const mapStateToProps = (state) =>({

});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps) (DropDown)
