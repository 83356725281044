import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Logo from '../../images/celtLogo.png'
import { connect } from 'react-redux'

export class AdminNav extends Component {
  render() {
    const {user} = this.props
    return (
        <div className=' flex flex-col gap-[10px] w-[251px] min-w-[251px] h-screen bg-[var(--white)] p-[20px] fixed '>
          <div className='flex flex-col fixed h-screen overflow-auto pb-[20px]'>
          <img className='p-[26px] w-[200px]' src={Logo}/>
            {
              user?.role == 'admin'?
              <>
                <NavLink to="/" className='p-[13px] text-[14px] text-[var(--dark-gray)] rounded-[8px] hover:bg-[var(--light-blue)] hover:font-[700] hover:text-[var(--dark-blue)] transition duration-300'>
                  Main Page info
                </NavLink>
                <NavLink to="/exams" className='p-[13px] text-[14px] text-[var(--dark-gray)] rounded-[8px] hover:bg-[var(--light-blue)] hover:font-[700] hover:text-[var(--dark-blue)] transition duration-300'>
                  Imtahanlar
                </NavLink>
                <NavLink to="/lang-preperation" className='p-[13px] text-[14px] text-[var(--dark-gray)] rounded-[8px] hover:bg-[var(--light-blue)] hover:font-[700] hover:text-[var(--dark-blue)] transition duration-300'>
                  Dil hazirligi
                </NavLink>
                {/* <NavLink to="/student-reviews" className='p-[13px] text-[14px] text-[var(--dark-gray)] rounded-[8px] hover:bg-[var(--light-blue)] hover:font-[700] hover:text-[var(--dark-blue)] transition duration-300'>
                  Student reviews
                </NavLink> */}
                <NavLink to="/faq" className='p-[13px] text-[14px] text-[var(--dark-gray)] rounded-[8px] hover:bg-[var(--light-blue)] hover:font-[700] hover:text-[var(--dark-blue)] transition duration-300'>
                  Faq
                </NavLink>
                <NavLink to="/blog" className='p-[13px] text-[14px] text-[var(--dark-gray)] rounded-[8px] hover:bg-[var(--light-blue)] hover:font-[700] hover:text-[var(--dark-blue)] transition duration-300'>
                  Blog
                </NavLink>
                <NavLink to="/kids-course" className='p-[13px] text-[14px] text-[var(--dark-gray)] rounded-[8px] hover:bg-[var(--light-blue)] hover:font-[700] hover:text-[var(--dark-blue)] transition duration-300'>
                  Diploms
                </NavLink>
                <NavLink to="/why-us" className='p-[13px] text-[14px] text-[var(--dark-gray)] rounded-[8px] hover:bg-[var(--light-blue)] hover:font-[700] hover:text-[var(--dark-blue)] transition duration-300'>
                  Why Us
                </NavLink>
                <NavLink to="/companies" className='p-[13px] text-[14px] text-[var(--dark-gray)] rounded-[8px] hover:bg-[var(--light-blue)] hover:font-[700] hover:text-[var(--dark-blue)] transition duration-300'>
                Companies
                </NavLink>
                <NavLink to="/content" className='p-[13px] text-[14px] text-[var(--dark-gray)] rounded-[8px] hover:bg-[var(--light-blue)] hover:font-[700] hover:text-[var(--dark-blue)] transition duration-300'>
                Content
                </NavLink>
                <NavLink to="/premium-student-selected" className='p-[13px] text-[14px] text-[var(--dark-gray)] rounded-[8px] hover:bg-[var(--light-blue)] hover:font-[700] hover:text-[var(--dark-blue)] transition duration-300'>
                Premium Student
                </NavLink>
                {/* <NavLink to="/online-apply" className='p-[13px] text-[14px] text-[var(--dark-gray)] rounded-[8px] hover:bg-[var(--light-blue)] hover:font-[700] hover:text-[var(--dark-blue)] transition duration-300'>
                Online apply
                </NavLink> */}
                <NavLink to="/email-collect" className='p-[13px] text-[14px] text-[var(--dark-gray)] rounded-[8px] hover:bg-[var(--light-blue)] hover:font-[700] hover:text-[var(--dark-blue)] transition duration-300'>
                Email collect
                </NavLink>
                <NavLink to="/student-apply" className='p-[13px] text-[14px] text-[var(--dark-gray)] rounded-[8px] hover:bg-[var(--light-blue)] hover:font-[700] hover:text-[var(--dark-blue)] transition duration-300'>
                Student Apply
                </NavLink>
                {/* <NavLink to="/blog-seo" className='p-[13px] text-[14px] text-[var(--dark-gray)] rounded-[8px] hover:bg-[var(--light-blue)] hover:font-[700] hover:text-[var(--dark-blue)] transition duration-300'>
                 Blog SEO
                </NavLink> */}
              </>:null
            }
            {
              user?.role == "content"?
              <NavLink to="/" className='p-[13px] text-[14px] text-[var(--dark-gray)] rounded-[8px] hover:bg-[var(--light-blue)] hover:font-[700] hover:text-[var(--dark-blue)] transition duration-300'>
                Blog
              </NavLink>:null
            }
          </div>
          
        </div>
        
    )
  }
}
const mapStateToProps = (state) =>({
  user: state.Data.user
})
export default connect(mapStateToProps)(AdminNav)