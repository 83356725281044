import React, { Component } from 'react'
import Input from './Input';

export class Videoinp extends Component {
  render() {
    const {name, value, title, valueName} = this.props;
    console.log(name, value)
    return (
      <div>
        <Input title={title} type="text" name={name} value={value}  />
        <div className='max-w-full video_imp mt-[20px]' dangerouslySetInnerHTML={{ __html:value }}></div>
      </div>
    )
  }
}

export default Videoinp
