import React, { Component } from 'react'
import SectionHeader from '../components/SectionHeader'
import Card1 from '../components/cards/Card1'
import { connect } from 'react-redux'
import { changeStateValue } from '../redux/MainReducer';
import { getKidsEnglish } from '../actions/MainAction';
export class AdminKidsEnglishType extends Component {
  componentDidMount=()=>{
    this.props.getKidsEnglish(2)
  }
  getSection(){
    return [
      {
        label: "CELT Özəl Məktəbi",
        value: '1'
      },
      {
        label: "Primary School",
        value: '2'
      },
      {
        label: "Secondary School",
        value: '3'
      }
    ]
  }
    openModal=(data, lang, lang_id=1)=>{
      this.props.changeStateValue({
        name:"modalData",
        value:{
          position:true,
          id:data?.id?data?.id:'',
          image:data?.image?data?.image:'',
          image_full_url:data?.image_full_url?data?.image_full_url:'',
          school_type:data?.school_type?data?.school_type:'1',
          schoolTypeOption: this.getSection(),
          languages:
          {
            id:lang?.id?lang?.id:'',
            title:lang?.title?lang?.title:'',
            text:lang?.text?lang?.text:'',
            // type:lang?.type?lang?.type:'',
            lang_id:lang_id
          },
          requiredsLang: ["title", "text"],
          requireds:[],
          afterCallFunction:this.props.getKidsEnglish,
          afterParams:[2],
          url:'admin-kids'
        }
      })
    }
  render() {
    const {adminKidsEnglishData} = this.props;
    // console.log(adminKidsEnglishData)
    return (
        <div className='flex w-full gap-[20px] justify-between '>
     
        <div className='w-full flex flex-col container1'>
          <div>
            <SectionHeader openModal={this.openModal.bind(this,"", "", 1)} btnTitle={"Add a diplom"} title='Diploms'/>
          </div>
          <div className='grid-full-250'>
            {
              adminKidsEnglishData?.map((data, i)=>{
                return(
                  <Card1
                    key={i}
                    title={data?.translate?.title}
                    image={data?.image_full_url}
                    openModal={(id)=>this.openModal(data, data.translates.find(x=>x.lang_id==id), id)}
                    deletedUrl={`admin-kids/${data?.id}`}
                    afterCallFunc={()=>this.props.getKidsEnglish(2)}
                  />
                )
              })
            }
            
          </div>
        </div>

      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  adminKidsEnglishData: state.Data.adminKidsEnglishData
});
const mapDispatchToProps = {changeStateValue, getKidsEnglish}
export default connect(mapStateToProps, mapDispatchToProps)(AdminKidsEnglishType)




