import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../redux/MainReducer';
// import EyeSvg from '../../svg/EyeSvg';
// import EyeCloseSvg from '../../svg/EyeCloseSvg';
export class Input extends Component {
  state={
    inpType:'password'
  }
    changeInpValue(onChangeParent, minValue, maxValue, step, e){
      this.props.changeStateValue({
        name: e.target.name, 
        value: e.target.value
      })
      onChangeParent(e.target.name, e.target.value);
    }
  render() {
    const {type = "text", name="", value="", title="", required=false, onChangeParent=()=>{}, minValue="", maxValue="", step=1, bg="bg-gray-100 "} = this.props;
    const {inpType} = this.state;
    return (
      <div className='flex flex-col items-start'>
        {title !== "" ? <label htmlFor={name} className='text-[14px] txt-light-black'>{title}</label>:null }
        {
          type !=="password"?
          <input  onChange={this.changeInpValue.bind(this, onChangeParent, minValue, maxValue, step)} placeholder={title} id={name} name={name} value={value} type={type} className={`${bg} w-full h-[35px] rounded-[6px] p-[5px] pl-[12px] outline-none text-[15px]`} />
          :<div className={`${bg} w-full h-[35px] rounded-[6px] flex items-center gap-[10px] pr-[7px]`}>
            <input  onChange={this.changeInpValue.bind(this, onChangeParent, minValue, maxValue, step)} placeholder={title} id={name} name={name} value={value} type={inpType} className={`${bg}  w-full h-[35px] rounded-[6px] p-[5px] pl-[12px] outline-none text-[15px]`} />
            <button onClick={()=>this.setState({inpType: inpType==="password"? "text":"password"})}>
             {/* { inpType==="password"?<EyeSvg />:<EyeCloseSvg /> }  */}
            </button>
          </div>
        }
        {
            required && value===""?
            <span className='text-[11px] text-red-700'>{"This field is required"}</span>:null
        }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(Input)
