import React, { Component } from 'react'

export class SectionHeader extends Component {
  render() {
    const {title, btnTitle="", openModal=()=>{}}  = this.props
    return (
      <div  className=' pt-[52px] mb-[32px] flex items-center justify-between'>
        <h2 className='text-[40px] font-[700] text-[var(--dark-blue)] max-[350px]:text-[30px]'>
        {title}
        </h2>
        <button onClick={()=>openModal("")} className=' p-[3px_10px] bg-[var(--blue)] text-[var(--white)] font-[700] text-[14px] rounded-[8px]'>
          {btnTitle}
        </button>
      </div>
    )
  }
}

export default SectionHeader