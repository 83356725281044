import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getAcceptedStudentsSelected } from '../actions/MainAction';
import SectionHeader from '../components/SectionHeader';
import Card1 from '../components/cards/Card1';
import { changeStateValue } from '../redux/MainReducer';
import { Link } from 'react-router-dom';

export class AdminSelectedPremiumSt extends Component {

    componentDidMount(){
        this.props.getAcceptedStudentsSelected()
    }

    openModal=(data, lang, lang_id=1)=>{
        this.props.changeStateValue({
            name:"modalData",
            value:{
              position:true,
              id:data?.id?data?.id:'',
              video:data?.video?data?.video:'',
              video_full_url:data?.video_full_url?data?.video_full_url:'',
              percentage:data?.percentage?data?.percentage:'',
              student_id:data?.user_id,
              languages:
              {
                id:lang?.id?lang?.id:'',
                title:lang?.title?lang?.title:'',
                content:lang?.content?lang?.content:'',
                description:lang?.description?lang?.description:'',
                lang_id:lang_id
              },
              requiredsLang: ["title"],
              requireds:[],
              afterCallFunction:this.props.getAcceptedStudentsSelected,
              afterParams:[],
              url:'admin-prem-st',
              go_api:true
            }
          })
      }
  render() {
    const {acceptedStudentsSelected} = this.props;
    console.log(acceptedStudentsSelected)
    return (
        <div className='flex w-full gap-[20px] justify-between '>
     
        <div className='w-full flex flex-col container1'>
          <div>
            <SectionHeader openModal={this.openModal.bind(this,"", "", 1)} btnTitle={""} title='Premium Students'/>
          </div>
          <div className='mb-[20px] flex items-center gap-[30px]'>
          <button className='text-[12px] bg-blue-700 text-white rounded-full p-[7px_8px]'>Premium Students</button>
            <Link to="/premium-student" className='text-[12px] border border-blue-700 text-blue-700 rounded-full p-[7px_8px]'>Accepted Students</Link>
         
          </div>
          <div className='grid-full-250'>
            {
              acceptedStudentsSelected?.map((data, i)=>{
                return(
                  <Card1
                    key={i}
                    title={data?.student?.full_name}
                    image={data?.image_full_url}
                    openModal={(id)=>this.openModal(data, data.translates.find(x=>x.lang_id==id), id)}
                    deletedUrlCO={`admin-prem-st/${data?.id}`}
                    afterCallFunc={()=>this.props.getAcceptedStudentsSelected(2)}
                  />
                )
              })
            }
            
          </div>
        </div>

      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    acceptedStudentsSelected: state.Data.acceptedStudentsSelected
});
const mapDispatchToProps = {getAcceptedStudentsSelected, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(AdminSelectedPremiumSt)
