import React, { Component } from 'react'
import { getOnlineApply } from '../actions/MainAction'
import { connect } from 'react-redux'
import SectionHeader from '../components/SectionHeader';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';

export class AdminOnlineAplly extends Component {
    state={
        page:'0'
    }
    componentDidMount(){
        this.props.getOnlineApply('0')
    }
    getData=(day)=>{
        this.setState({page:day});
        this.props.getOnlineApply(day)
    }
  render() {
    const {onlineApply} = this.props;
    const {page} = this.state;
    // console.log(onlineApply)
    return (
        <div className='flex w-full gap-[20px] justify-between '>
     
        <div className='w-full flex flex-col container1'>
            <div>
                <SectionHeader  btnTitle={""} title='Online Apply'/>
            </div>
            <div  className='flex items-center gap-[20px] mb-[20px]'>
                <Link to={'/student-apply'} className={`text-[12px] font-regular rounded-[6px] p-[5px_10px] ${1!==1? "bg-blue-600 text-white":"border border-blue-600 text-blue-600"}`}>
                    Student Apply
                </Link>
                <Link to={'/online-apply'} className={`text-[12px] font-regular rounded-[6px] p-[5px_10px] ${1===1? "bg-blue-600 text-white":"border border-blue-600 text-blue-600"}`}>
                    Online Apply
                </Link>
            </div>
            <div className='flex items-center gap-[20px] mb-[20px]'>
                <button onClick={this.getData.bind(this, '0')} className={`text-[12px] font-regular rounded-[6px] p-[5px_10px] ${page=='0'? "bg-blue-600 text-white":"border border-blue-600 text-blue-600"}`}>Today</button>
                <button onClick={this.getData.bind(this, '1')} className={`text-[12px] font-regular rounded-[6px] p-[5px_10px] ${page=='1'? "bg-blue-600 text-white":"border border-blue-600 text-blue-600"}`}>Yesterday</button>
                <button onClick={this.getData.bind(this, '2')} className={`text-[12px] font-regular rounded-[6px] p-[5px_10px] ${page=='2'? "bg-blue-600 text-white":"border border-blue-600 text-blue-600"}`}>2 days ago</button>
                <button onClick={this.getData.bind(this, '3')} className={`text-[12px] font-regular rounded-[6px] p-[5px_10px] ${page=='3'? "bg-blue-600 text-white":"border border-blue-600 text-blue-600"}`}>3 days ago</button>
                <button onClick={this.getData.bind(this, '4')} className={`text-[12px] font-regular rounded-[6px] p-[5px_10px] ${page=='4'? "bg-blue-600 text-white":"border border-blue-600 text-blue-600"}`}>4 days ago</button>
                <button onClick={this.getData.bind(this, '5')} className={`text-[12px] font-regular rounded-[6px] p-[5px_10px] ${page=='5'? "bg-blue-600 text-white":"border border-blue-600 text-blue-600"}`}>5 days ago</button>
            </div>
            <div className='grid-full-250'>
                {
                    onlineApply?.map((data, i) => {
                        return(
                            <div key={i} className='bg-white p-[10px] rounded-[10px]'>
                                <ul className='flex flex-col gap-[6px]'>
                                    <li className='flex items-center gap-[5px] font-[300] text-[14px]'><span className='font-[600]'>Name:</span> {data?.name}</li>
                                    <li className='flex items-center gap-[5px] font-[300] text-[14px]'><span className='font-[600]'>Phone:</span> {data?.phone_number}</li>
                                    {
                                        data?.education_type?
                                        <li className='flex items-center gap-[5px] font-[300] text-[14px]'><span className='font-[600]'>Education type:</span> {data?.education_type ==1? "Online": "Offline"}</li>:null
                                    }
                                    {
                                        data?.school_type?
                                        <li className='flex items-center gap-[5px] font-[300] text-[14px]'><span className='font-[600]'>School type:</span> {data?.school_type === "private_school"? "High School":data?.school_type === "secondary_school"? "Secondary School":data?.school_type === "primary_school"? "Primary School":""  }</li>:null
                                    }
                                    {
                                        data?.subject?
                                        <li className='flex items-center gap-[5px] font-[300] text-[14px]'><span className='font-[600]'>Subject:</span> {data?.subject}</li>:null
                                    }
                                    
                                    {
                                        data?.company?
                                         <li className='flex items-center gap-[5px] font-[300] text-[14px]'><span className='font-[600]'>Company:</span> {data?.company?.title}</li>:null
                                    }
                                    <li className='flex items-center gap-[5px] font-[300] text-[14px]'><span className='font-[600]'>Apply date:</span> {moment(data?.created_at)?.format("DD/MM/YYYY")}</li>
                                </ul>
                            </div>
                        )
                    })
                }
            </div>
        </div>
        
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    onlineApply: state.Data.onlineApply
})
const mapDispatchToProps = {getOnlineApply}
export default connect(mapStateToProps, mapDispatchToProps)(AdminOnlineAplly)
