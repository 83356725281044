import React, { Component } from 'react'
import SectionHeader from '../components/SectionHeader'
import { connect } from 'react-redux';
import { getBlog } from '../actions/MainAction';
import Card1 from '../components/cards/Card1';
import { changeStateValue } from '../redux/MainReducer';
import LoadMoreBtn from '../components/LoadMoreBtn';

export class AdminBlog extends Component {
  state={
    search:'',
    page:1
  }
    openModal=(data)=>{
        this.props.changeStateValue({
            name:"modalData",
            value:{
              position:true,
              id:data?.id?data?.id:'',
              image:data?.image?data?.image:'',
              title:data?.title?data?.title:'',
              description:data?.description?data?.description:'',
              date:data?.date?data?.date:'',
              path:data?.path?data?.path:'',
              image_full_url:data?.image_full_url?data?.image_full_url:'',
            
              languages:
              { },
              requiredsLang: [],
              requireds:["title", "description"],
              afterCallFunction:this.props.getBlog,
              afterParams:[],
              url:'admin-blog'
            }
          })
    }
    componentDidMount(){
        this.props.getBlog()
    }
    loadMore=(page)=>{
      this.setState({page})
      this.props.getBlog(page, this.state.search)
    }

    searchBlog=(search, e)=>{
      e.preventDefault();
      this.props.getBlog(this.state.page, search)
    }
    onChangeSearch=(e)=>{
      this.setState({search: e.target.value})
      if(e.target.value == ""){
        this.props.getBlog(this.state.page, '')
      }
    }
  render() {
    const {adminBlogData, user} = this.props;
    const {search} = this.state;
    // console.log(adminBlogData)
    return (
        <div className='flex w-full gap-[20px] justify-between '>
     
        <div className='w-full flex flex-col container1 pb-[50px]'>
          <div>
            <SectionHeader openModal={this.openModal.bind(this,"", "", 1)} btnTitle={"Add a Blog"} title='Blog'/>
          </div>
          <div className='flex justify-center mb-[20px] '>
           <form  onSubmit={this.searchBlog.bind(this, search)}>
            <input type='text' value={search} onChange={this.onChangeSearch.bind(this)} className='w-[300px] h-[40px] rounded-full outline-none pl-[5px] text-[17px]' placeholder='Search' />
           </form>
          </div>
          <div className='grid-full-250'>
            {
              adminBlogData?.map((data, i)=>{
                return(
                  <Card1
                    key={i}
                    title={data?.title}
                    image={data?.image_full_url}
                    openModal={this.openModal.bind(this, data)}
                    deletedUrl={user?.role == "admin"? `admin-blog/${data?.id}`:""}
                    afterCallFunc={()=>this.props.getBlog()}
                    langTr={false}
                  />
                )
              })
            }
            
          </div>
          {
            adminBlogData?.length >=20?
            <LoadMoreBtn count={adminBlogData?.length} onLoadData={this.loadMore} />:null
          }
        </div>

      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    adminBlogData: state.Data.adminBlogData,
    user: state.Data.user,
});
const mapDispatchToProps = {getBlog, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(AdminBlog)


