import axios from "axios"
import { MAIN_API, MAIN_API_GO } from "../MAIN_API"
import { changeStateValue } from "../redux/MainReducer"
const apiHeaders = () => {
    return {
        "Authorization": `Bearer ${localStorage.getItem("userToken")}`,
        "Accept": "application/json",
        // "Content-Type": "application/json"
    }
}
export const loginAction = (params) => async dispatch => {
    return await axios.post(`${MAIN_API}/login`, params, {
        headers:{
            "Accept":"application/json"
        }
    }).then(resp=>{
        localStorage.setItem("userToken", resp?.data?.token);
        dispatch(changeStateValue({name:"user", value: resp.data?.user}))
        return "success";
    }).catch(err=>{
        console.log(err.response)
        return "error";
    })
}

export const checkLoginUser = () => async dispatch =>  {
    return await axios.get(`${MAIN_API}/user`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"user", value:resp.data}));
        return "success";
    }).catch(err=>{
        return "error";
    })
}

export const getAdminExams = (type="1") => async dispatch =>  {
    return await axios.get(`${MAIN_API}/admin-exams?lang_id=1&page_type=${type}`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"adminExams", value:resp.data}));
        return "success";
    }).catch(err=>{
        return "error";
    })
}

export const getAdminContent = () => async dispatch =>  {
    return await axios.get(`${MAIN_API}/admin-content?lang_id=1`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"adminContentData", value:resp.data}));
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getStudentReviews = (company_id) => async dispatch =>  {
    return await axios.get(`${MAIN_API}/admin-student-reviews?company_id=${company_id}`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"adminStudentReviews", value:resp.data}));
        return "success";
    }).catch(err=>{
        console.log(err.response)
        return "error";
    })
}
export const getFaq = (exam_id="", type="") => async dispatch =>  {
    return await axios.get(`${MAIN_API}/admin-faq?lang_id=1&exam_id=${exam_id}&type=${type}`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"adminFaqData", value:resp.data}));
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getBlog = (page=1, search="") => async dispatch =>  {
    return await axios.get(`${MAIN_API}/admin-blog?page=${page}&keyword=${search}`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"adminBlogData", value:resp.data}));
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getKidsEnglish = () => async dispatch =>  {
    return await axios.get(`${MAIN_API}/admin-kids?lang_id=1`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"adminKidsEnglishData", value:resp.data}));
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getWhyUs = () => async dispatch =>  {
    return await axios.get(`${MAIN_API}/admin-why-us?lang_id=1`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"adminWhyUsData", value:resp.data}));
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getCompanies = () => async dispatch =>  {
    return await axios.get(`${MAIN_API}/admin-companies`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"adminCompaniesData", value:resp.data}));
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getLang = () => async dispatch =>  {
    return await axios.get(`${MAIN_API}/system-lang`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"systemLangs", value:resp.data}));
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getMainPageInfo = () => async dispatch =>  {
    return await axios.get(`${MAIN_API}/admin-main-info?lang_id=1`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"mainPageInfoData", value:resp.data}));
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getOnlineApply = (day) => async dispatch =>  {
    return await axios.get(`${MAIN_API}/admin-online-apply?page=${day}`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"onlineApply", value:resp.data}));
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getEmailCollects = () => async dispatch =>  {
    return await axios.get(`${MAIN_API}/admin-email-collect`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"emailCollects", value:resp.data}));
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getStudentApply = (day) => async dispatch =>  {
    return await axios.get(`${MAIN_API}/admin-student-apply?page=${day}`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"studentApllies", value:resp.data}));
        return "success";
    }).catch(err=>{
        return "error";
    })
}

export const getAcceptedStudents = (page=1) => async dispatch =>  {
    return await axios.get(`${MAIN_API_GO}/accepted-students?lang_id=1&page=${page}`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"acceptedStudents", value:resp.data}));
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getAcceptedStudentsSelected = (page=1) => async dispatch =>  {
    return await axios.get(`${MAIN_API_GO}/admin-prem-st`,{
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"acceptedStudentsSelected", value:resp.data}));
        return "success";
    }).catch(err=>{
        return "error";
    })
}


export const insertNewData = (url, params) => async dispatch => {
    return await axios.post(`${MAIN_API}/${url}`, params, {
        headers: apiHeaders()
    }).then(resp=>{
        console.log(resp.data)
        return "success";
    }).catch(err=>{
        console.log(err.response)
        return "error";
    })
}
export const insertNewDataGO = (url, params) => async dispatch => {
    return await axios.post(`${MAIN_API_GO}/${url}`, params, {
        headers: apiHeaders()
    }).then(resp=>{
        return "success";
    }).catch(err=>{
        console.log(err)
        return "error";
    })
}


export const deleteData = (url) => async dispatch => {
    return await axios.delete(`${MAIN_API}/${url}`, {
        headers: apiHeaders()
    }).then(resp=>{
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const deleteDataCO = (url) => async dispatch => {
    return await axios.delete(`${MAIN_API_GO}/${url}`, {
        headers: apiHeaders()
    }).then(resp=>{
        return "success";
    }).catch(err=>{
        return "error";
    })
}