import React, { Component } from 'react'
import { getEmailCollects } from '../actions/MainAction';
import { connect } from 'react-redux';
import SectionHeader from '../components/SectionHeader';
import moment from 'moment';

export class AdminEmailCollecPage extends Component {

    componentDidMount(){
        this.props.getEmailCollects()
    }
  render() {
    const {emailCollects} = this.props;
    // console.log(emailCollects)
    return (
        <div className='flex w-full gap-[20px] justify-between '>
     
        <div className='w-full flex flex-col container1'>
            <div>
                <SectionHeader  btnTitle={""} title='Emails'/>
            </div>
            <div className='grid-full-250'>
                {
                    emailCollects?.map((data, i) => {
                        return (
                            <div className='bg-white p-[10px] rounded-[10px]' key={i}>
                                <ul className='flex flex-col gap-[6px]'>
                                    <li className='flex items-center gap-[5px] font-[300] text-[14px]'><span className='font-[600]'>Email:</span> {data?.email}</li>
                                    <li className='flex items-center gap-[5px] font-[300] text-[14px]'><span className='font-[600]'>Apply date:</span> {moment(data?.created_at)?.format("DD/MM/YYYY")}</li>
                                </ul>
                            </div>
                        )
                    })
                }
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    emailCollects: state.Data.emailCollects
});
const mapDispatchToProps = {getEmailCollects}
export default connect(mapStateToProps, mapDispatchToProps)(AdminEmailCollecPage)
