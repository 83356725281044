import React, { Component } from 'react'
import {img_data} from "../img"
import { insertNewData } from '../actions/MainAction';
import { connect } from 'react-redux';
export class AdminJson extends Component {
  addProgram=(rank)=>{
    // console.log(img_data)
    let new_arr = [];
    
    for(let i = 0; i <img_data.length; i++){
      if(i >= 700 && i <1100){
        if(img_data[i]["img"]){
          new_arr.push(img_data[i])
       }
      }
    }
    // console.log(new_arr)
    console.log("getdi")
    this.props.insertNewData("img-add", {img: JSON.stringify(new_arr)})
    .then(resp=>{
        console.log(resp)
    })
  }
  render() {
    return (
      <div className='flex w-full gap-[20px] justify-between '>
     
      <div className='w-full flex flex-col container1'>
       {/* <Header title="Add ranking" /> */}
    
         
          <button onClick={this.addProgram.bind(this)}>Add</button>
      </div>
    </div>
    )
  }
}
const mapStateToProps = (state) =>({})
const mapDispatchToProps = {insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(AdminJson)
