import React, { Component } from 'react'
import SectionHeader from '../components/SectionHeader'
import { connect } from 'react-redux';
import { getCompanies } from '../actions/MainAction';
import Card1 from '../components/cards/Card1';
import { changeStateValue } from '../redux/MainReducer';

export class AdminCompanies extends Component {
    openModal=(data)=>{
        this.props.changeStateValue({
            name:"modalData",
            value:{
              position:true,
              id:data?.id?data?.id:'',
              image:data?.image?data?.image:'',
              title:data?.title?data?.title:'',
              phone_number:data?.phone_number?data?.phone_number:'',
              office_number:data?.office_number?data?.office_number:'',
              location:data?.location?data?.location:'',
              map:data?.map?data?.map:'',
              facebook:data?.facebook?data?.facebook:'',
              instagram:data?.instagram?data?.instagram:'',
              image_full_url:data?.image_full_url?data?.image_full_url:'',
              type:data?.type?data?.type:'1',
              path:data?.path?data?.path:'',
              seo_description:data?.seo_description?data?.seo_description:'',
              seo_keywords:data?.seo_keywords?data?.seo_keywords:'',
              languages:
              { },
              requiredsLang: [],
              requireds:["title"],
              afterCallFunction:this.props.getCompanies,
              afterParams:[],
              url:'admin-companies'
            }
          })
    }
    componentDidMount(){
        this.props.getCompanies()
    }
  render() {
    const {adminCompaniesData} = this.props;
    // console.log(adminCompaniesData)
    return (
        <div className='flex w-full gap-[20px] justify-between '>
     
        <div className='w-full flex flex-col container1'>
          <div>
            <SectionHeader openModal={this.openModal.bind(this,"", "", 1)} btnTitle={"Add a Company"} title='Companies'/>
          </div>
          <div className='grid-full-250'>
            {
              adminCompaniesData?.map((data, i)=>{
                return(
                  <Card1
                    key={i}
                    title={data?.title}
                    image={data?.image_full_url}
                    openModal={this.openModal.bind(this, data)}
                    deletedUrl={`admin-companies/${data?.id}`}
                    afterCallFunc={()=>this.props.getCompanies()}
                    reviewUrl={`/company-review/${data?.id}`}
                    langTr={false}
                  />
                )
              })
            }
            
          </div>
        </div>

      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    adminCompaniesData: state.Data.adminCompaniesData
});
const mapDispatchToProps = {getCompanies, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(AdminCompanies)




