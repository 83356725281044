import React, { Component } from 'react'
import { connect } from 'react-redux';

export class LoadMoreBtn extends Component {
  state={
    page:1,
    prevData: 0,
    loadMore:true,
    showLess:false
  }

  loadMoreBtn( page, count, prevData){
    let inc = page;
    if(count !== prevData){
      inc++;
      this.setState({prevData:count, page:inc});
    }else{
      if(count === this.props.defCount){
        this.setState({loadMore:false})
      }else{
        this.setState({showLess:true})
      }
    }

    this.props.onLoadData(inc);
  }

  showLessBtn(){
    this.setState({
      page:1,
      prevData: 0,
      loadMore:true,
      showLess:false
    });
    this.props.onLoadData(1);
  }
  render() {
    const {count, lang, defCount=20} = this.props;
    const {page, prevData, showLess, loadMore} = this.state;
    return (
      <div className='flex justify-center items-center gap-[20px] mt-[20px]'>
        {
         count % defCount ===0 && loadMore && !showLess?
          <button onClick={this.loadMoreBtn.bind(this, page, count, prevData )} className='bg-blue-700 pl-[10px] pr-[10px] h-[35px] text-white font-semibold rounded-[6px]'>{"Load More"}</button>:null
        }
        {
          (count >defCount && count % defCount !== 0) || showLess?
          <button onClick={this.showLessBtn.bind(this)} className='bg-white pl-[10px] pr-[10px] h-[35px] txt-blue border border-[2px] border-blue-800 font-semibold rounded-[6px]'>{"Show Less"}</button>:null
        }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  lang: state.Data.lang
})
export default connect(mapStateToProps)(LoadMoreBtn)
