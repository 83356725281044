import React, { Component } from 'react';

class ImageUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: null
    };
    this.inputRef = React.createRef(); 
  }

  handleImageChange = event => {
    const file = event.target.files[0]; 
    const imageUrl = URL.createObjectURL(file); 
    this.setState({ imageUrl });
  };

  handleRemoveImage = () => {
    this.setState({ imageUrl: null });
    this.inputRef.current.value = ''; 
  };

  render() {
    const { imageUrl } = this.state;

    return (
      <div className=''>
        <label htmlFor='imgUp' className='block w-[99px] h-[99px] rounded-[50px] bg-[var(--light-gray-2)]'>
            {imageUrl && (
             <img src={imageUrl} alt="uploaded"  className='w-[99px] h-[99px] rounded-[50px]'/>
            )}
            
        </label>
        <p className='font-[600] text-[var(--blue)] mt-[5px]'>Upload an image</p>
        <input
        id="imgUp"
          className='hidden'
          type="file"
          accept="image/*"
          onChange={this.handleImageChange}
          ref={this.inputRef} 
        />
      </div>
    );
  }
}

export default ImageUploader;
