import React, { Component } from 'react'
import { insertNewData } from '../actions/MainAction';
import { connect } from 'react-redux';

export class BlogSEO extends Component {
    state={
        count:0,
        btnDisable:false,
        processText: ''
    }
    onClickBtn=()=>{
        this.setState({btnDisable:true, processText:"processing"})
        this.props.insertNewData("blog-seo", {})
        .then(resp=>{
            this.setState({btnDisable:false, count: this.state.count+1, processText:"waiting"});
            setTimeout(()=>{
                this.onClickBtn()
            }, 30000)
        })
    }
  render() {
    const {count, btnDisable, processText} = this.state;
    return (
        <div className='flex w-full gap-[20px] justify-between '>
            <div className='container1'>
                <button onClick={this.onClickBtn.bind(this)} disabled={btnDisable}>Start</button>
                <h2>Count: {count}</h2>
                <h2>Status: {processText}</h2>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({});
const mapDispatchToProps = {insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(BlogSEO)
