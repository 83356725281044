import React, { Component } from 'react'
import { getAcceptedStudents } from '../actions/MainAction'
import { connect } from 'react-redux'
import PublicCard1 from '../components/cards/PublicCard1';
import SectionHeader from '../components/SectionHeader';
import LoadMoreBtn from '../components/LoadMoreBtn';
import { changeStateValue } from '../redux/MainReducer';
import { Link } from 'react-router-dom';

export class AdminPremiumStudent extends Component {
  state={
    page:1
  }
    componentDidMount(){
        this.props.getAcceptedStudents(1)
    }
    openModal=()=>{

    }
    loadMore=(page)=>{
      this.setState({page})
      this.props.getAcceptedStudents(page)
    }

    addToPremium=(data, lang, lang_id)=>{
      this.props.changeStateValue({
        name:"modalData",
        value:{
          position:true,
          id:data?.id?data?.id:'',
          video:data?.video?data?.video:'',
          video_full_url:data?.video_full_url?data?.video_full_url:'',
          percentage:data?.percentage?data?.percentage:'',
          student_id:data?.user_id,
          languages:
          {
            id:lang?.id?lang?.id:'',
            title:lang?.title?lang?.title:'',
            content:lang?.content?lang?.content:'',
            description:lang?.description?lang?.description:'',
            lang_id:lang_id
          },
          requiredsLang: ["title"],
          requireds:[],
          afterCallFunction:this.props.getAcceptedStudents,
          afterParams:[this.state.page],
          url:'admin-prem-st',
          go_api:true
        }
      })
    }
  render() {
    const {acceptedStudents} = this.props;
    console.log(acceptedStudents)
    return (
      <div className='flex w-full gap-[20px] justify-between '>
        <div className='w-full flex flex-col container1'>
          <div>
            <SectionHeader openModal={this.openModal.bind(this,"", "", 1)} title='Accepted Students'/>
          </div>
          <div className='mb-[20px] flex items-center gap-[30px]'>
           
            <Link to="/premium-student-selected" className='text-[12px] border border-blue-700 text-blue-700 rounded-full p-[7px_8px]'>Premium Students</Link>
            <button className='text-[12px] bg-blue-700 text-white rounded-full p-[7px_8px]'>Accepted Students</button>
          </div>
          <div className='grid-full-250'>
            {
              acceptedStudents?.map((data, i)=>{
                return(
                  <PublicCard1 
                    key={i}
                    data={data}
                    addToPremium={this.addToPremium.bind(this, data, "", 1)}
                  />
                )
              })
            }
          </div>
          {
            acceptedStudents?.length >=20?
            <LoadMoreBtn count={acceptedStudents?.length} onLoadData={this.loadMore} />:null
          }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    acceptedStudents: state.Data.acceptedStudents
})
const mapDispatchToProps = {getAcceptedStudents, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(AdminPremiumStudent)
