export const initialState = {
    user:'',
    modalData:{
        position:false
    },
    adminExams:[],
    adminStudentReviews:[],
    adminFaqData:[],
    adminBlogData:[],
    adminKidsEnglishData:[],
    adminWhyUsData:[],
    adminCompaniesData:[],
    adminContentData:[],
    systemLangs:[],
    mainPageInfoData:[],

    acceptedStudents:[],
    acceptedStudentsSelected:[],
    onlineApply:[],
    emailCollects:[],
    studentApllies:[]
}
