import React, { Component } from 'react'
import SectionHeader from '../components/SectionHeader'
import Card1 from '../components/cards/Card1'
import { connect } from 'react-redux'
import { changeStateValue } from '../redux/MainReducer';
import { getAdminExams } from '../actions/MainAction';
export class ExamsPage extends Component {
  componentDidMount=()=>{
    this.props.getAdminExams(1)
  }
  getSection(){
    return [
      {
        label: "Dil Imtahanlari",
        value: '1'
      },
      {
        label: "Qəbul imtahanlari",
        value: '2'
      }
    ]
  }
  
    openModal=(data, lang, lang_id=1)=>{
      this.props.changeStateValue({
        name:"modalData",
        value:{
          position:true,
          id:data?.id?data?.id:'',
          image:data?.image?data?.image:'',
          image_full_url:data?.image_full_url?data?.image_full_url:'',
          video:data?.video?data?.video:'',
          video_full_url:data?.video_full_url?data?.video_full_url:'',
          price:data?.price?data?.price:'',
          duration:data?.duration?data?.duration:'',
          lessons:data?.lessons?data?.lessons:'',
          student_count:data?.student_count?data?.student_count:'',
          section_id:data?.section_id?data?.section_id:'1',
          sectionsOption: this.getSection(),
          page_type:1,
          languages:
          {
            id:lang?.id?lang?.id:'',
            title:lang?.title?lang?.title:'',
            text:lang?.text?lang?.text:'',
            description:lang?.description?lang?.description:'',
            seo_description:lang?.seo_description?lang?.seo_description:'',
            seo_keywords:lang?.seo_keywords?lang?.seo_keywords:'',
            lang_id:lang_id
          },
          requiredsLang: ["title", "text"],
          requireds:[],
          afterCallFunction:this.props.getAdminExams,
          afterParams:[1],
          url:'admin-exams'
        }
      })
    }
  render() {
    const {adminExams} = this.props;
    // console.log(adminExams)
    return (
        <div className='flex w-full gap-[20px] justify-between '>
     
        <div className='w-full flex flex-col container1'>
          <div>
            <SectionHeader openModal={this.openModal.bind(this,"", "", 1)} btnTitle={"Add an exam"} title='Exams'/>
          </div>
          <div className='grid-full-250'>
            {
              adminExams?.map((data, i)=>{
                return(
                  <Card1
                    key={i}
                    title={data?.translate?.title}
                    image={data?.image_full_url}
                    openModal={(id)=>this.openModal(data, data.translates.find(x=>x.lang_id==id), id)}
                    deletedUrl={`admin-exams/${data?.id}`}
                    afterCallFunc={()=>this.props.getAdminExams(1)}
                    faqUrl={`/exam-faq/${data?.id}`}
                  />
                )
              })
            }
            
          </div>
        </div>

      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  adminExams: state.Data.adminExams
});
const mapDispatchToProps = {changeStateValue, getAdminExams}
export default connect(mapStateToProps, mapDispatchToProps)(ExamsPage)
