import React, { Component } from 'react'
import ProfileImg from '../../images/1692285193passport.jpeg'
import { bigAlert, confirmAlert } from '../../actions/SweetAlerts'
import { deleteData, deleteDataCO } from '../../actions/MainAction'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ChangeLang from './ChangeLang'


export class Card1 extends Component {
  deleteItem=(url)=>{
    confirmAlert("warning", "Are you sure?", "You won't be able to revert this!")
    .then(alertResp=>{
        if(alertResp === "success"){
            this.props.deleteData(url)
            .then(resp=>{
                if(resp==="success"){
                    bigAlert("Success!", "Data deleted successfully!", "success");
                    this.props.afterCallFunc()
                }else{
                    bigAlert("Error!", "Something went wrong!", "error")
                }
            })
        }
    })
  }
  deleteItemCo=(url)=>{
    confirmAlert("warning", "Are you sure?", "You won't be able to revert this!")
    .then(alertResp=>{
        if(alertResp === "success"){
            this.props.deleteDataCO(url)
            .then(resp=>{
                if(resp==="success"){
                    bigAlert("Success!", "Data deleted successfully!", "success");
                    this.props.afterCallFunc()
                }else{
                    bigAlert("Error!", "Something went wrong!", "error")
                }
            })
        }
    })
  }
  render() {
    const {title, image, openModal=()=>{}, deletedUrl="", deletedUrlCO="", faqUrl="", reviewUrl="", langTr=true} = this.props;
    return (
      <div className='bg-[var(--white)] rounded-[16px] p-[20px]'>
        <img className=' w-[100%]  rounded-[10px] ml-auto mr-auto' src={image} />
        <div className='mt-[10px]'>
          <p className='text-[14px] text-[var(--dark-gray)] font-[600] p-[5px_0px_5px_0px] border-b-[2px] border-dashed '>{title}</p>
         
        </div>
        <div className='flex gap-[10px] justify-between mt-[20px]'>
          {
            deletedUrl !=""?
            <button onClick={this.deleteItem.bind(this,deletedUrl)} className=' p-[2px_8px] bg-[none] text-[var(--red)] font-[500] text-[13px] rounded-[5px] border border-solid border-[var(--red)]'>Delete</button>:null
          }
          {
            deletedUrlCO !=""?
            <button onClick={this.deleteItemCo.bind(this,deletedUrlCO)} className=' p-[2px_8px] bg-[none] text-[var(--red)] font-[500] text-[13px] rounded-[5px] border border-solid border-[var(--red)]'>Delete</button>:null
          }
            
            {
              faqUrl !=""?
              <Link to={faqUrl}className=' p-[2px_8px] bg-[none] text-[var(--blue)] font-[500] text-[13px] rounded-[5px] border border-solid border-[var(--blue)]'>Add FAQ</Link>:null
            }
            {
              reviewUrl !=""?
              <Link to={reviewUrl}className=' p-[2px_8px] bg-[none] text-[var(--blue)] font-[500] text-[13px] rounded-[5px] border border-solid border-[var(--blue)]'>Add Review</Link>:null
            }
            <button onClick={()=>openModal(1)} className=' p-[2px_8px] bg-[none] text-[var(--blue)] font-[500] text-[13px] rounded-[5px] border border-solid border-[var(--blue)]'>Edit</button>
          </div>
          {
            langTr?
            <ChangeLang changeLangBtn={(id)=>openModal(id)} />:null
          }
          
      </div>
    )
  }
}
const mapStateToProps = (state) =>({

});
const mapDispatchToProps = {deleteData, deleteDataCO}
export default connect(mapStateToProps, mapDispatchToProps)(Card1)
