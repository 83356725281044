import React, { Component } from 'react'
import SectionHeader from '../components/SectionHeader'
import { connect } from 'react-redux';
import { getStudentReviews } from '../actions/MainAction';
import Card1 from '../components/cards/Card1';
import { changeStateValue } from '../redux/MainReducer';

export class StudentReviews extends Component {
    openModal=(data)=>{
        this.props.changeStateValue({
            name:"modalData",
            value:{
              position:true,
              id:data?.id?data?.id:'',
              image:data?.image?data?.image:'',
              name:data?.name?data?.name:'',
              text:data?.text?data?.text:'',
              image_full_url:data?.image_full_url?data?.image_full_url:'',
              raiting:data?.raiting?data?.raiting:'',
            
              languages:
              { },
              requiredsLang: [],
              requireds:["name", "text"],
              afterCallFunction:this.props.getStudentReviews,
              afterParams:[],
              url:'admin-student-reviews'
            }
          })
    }
    componentDidMount(){
        this.props.getStudentReviews()
    }
  render() {
    const {adminStudentReviews} = this.props;
    // console.log(adminStudentReviews)
    return (
        <div className='flex w-full gap-[20px] justify-between '>
     
        <div className='w-full flex flex-col container1'>
          <div>
            <SectionHeader openModal={this.openModal.bind(this,"", "", 1)} btnTitle={"Add a student review"} title='Student reviews'/>
          </div>
          <div className='grid-full-250'>
            {
              adminStudentReviews?.map((data, i)=>{
                return(
                  <Card1
                    key={i}
                    title={data?.name}
                    image={data?.image_full_url}
                    openModal={(id)=>this.openModal(data, data.translates.find(x=>x.lang_id==id), id)}
                    deletedUrl={`admin-student-reviews/${data?.id}`}
                    afterCallFunc={()=>this.props.getStudentReviews()}
                  />
                )
              })
            }
            
          </div>
        </div>

      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    adminStudentReviews: state.Data.adminStudentReviews
});
const mapDispatchToProps = {getStudentReviews, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(StudentReviews)
