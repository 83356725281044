import React, { Component } from 'react'
import { mixinAlert } from '../actions/SweetAlerts';
import { connect } from 'react-redux';
import { loginAction } from '../actions/MainAction';
import { withHooks } from '../actions/withHooks';
export class LoginPage extends Component {
    state={
        email:'',
        password:''
    }
    loginBtn=(data, e)=>{
        e.preventDefault();

        if(data?.email !=="" && data?.password?.trim() !==""){
            this.props.loginAction(data)
            .then(resp=>{
                if(resp == "error"){
                    mixinAlert("error", "Email or password is incorrect")
                }else{
                    this.props.navigate("/")
                }
            })
        }else{
            mixinAlert("warning", "Please fill the all fields")
        }
    }
  render() {
    const {email, password} = this.state;
    return (
        <div className='flex justify-center items-center h-screen w-full'>
            <div className='w-[350px] p-[20px] bg-white shadow rounded-[10px]'>
                <h3 className='text-center text-[24px] text-blue-600 font-600'>Login</h3>
                <form className='mt-[20px] flex flex-col gap-[20px]'>
                    <div className='flex flex-col'>
                    <label className='text-[14px]'>Email</label>
                    <input value={email} onChange={(e)=>this.setState({email: e.target.value})} className='bg-gray-100 h-[32px] rounded-[5px] outline-none pl-[4px] text-[14px]' placeholder='Email' type='email' />
                    </div>
                    <div className='flex flex-col'>
                    <label className='text-[14px]'>Password</label>
                    <input value={password} onChange={(e)=>this.setState({password: e.target.value})} className='bg-gray-100 h-[32px] rounded-[5px] outline-none pl-[4px] text-[14px]' placeholder='Password' type='password' />
                    </div>
                    <div className='flex justify-center'>
                    <button onClick={this.loginBtn.bind(this, this.state)} className='text-[14px] bg-blue-800 p-[3px_6px] rounded-[5px] text-white font-semibold'>Log In</button>
                    </div>
                </form>
            </div>
        </div>
    )
  }
}

const mapStateToProps = (state) =>({});
const mapDispatchToProps = {loginAction}

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(LoginPage))
